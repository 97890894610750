import { Camera, CameraType } from "expo-camera";
import { make, stylize } from "../lib/style";
import SyPage from "../widgets/SyPage";
import SyText from "../widgets/SyText";
import SyIcon from "../widgets/SyIcon";
import { View } from "react-native";
import { cameraImageVar } from "../queries/cache";
import { useRef } from "react";
import { SaveFormat, manipulateAsync } from "expo-image-manipulator";

export default function CameraPage({ navigation }) {
  const [permission, requestPermission] = Camera.useCameraPermissions();
  const camera = useRef(null);

  const actions = [
    {
      label: "Fotoğraf çek",
      onPress: async () => {
        try {
          const pic = await camera.current.takePictureAsync({
            base64: true,
            allowsEditing: true,
            aspect: [1, 1],
            quality: 1,
          });

          const image = await manipulateAsync(
            pic.uri,
            [
              {
                resize: {
                  width: 480,
                },
              },
            ],
            { compress: 1, format: SaveFormat.JPEG, base64: true }
          );
          cameraImageVar("data:image/jpg;base64," + image.base64);
          navigation.goBack();
        } catch (err) {
          console.log(err);
        }
      },
    },
  ];

  if (!permission) {
    return <View />;
  }

  if (!permission.granted) {
    const actions = [
      {
        label: "Kameraya izin ver",
        onPress: requestPermission,
      },
    ];

    return (
      <SyPage style={styles.page} actions={actions}>
        <View style={styles.content}>
          <SyIcon name="camera-off" style={styles.icon}></SyIcon>
          <SyText content="Kamera izni yok" style={styles.text}></SyText>
        </View>
      </SyPage>
    );
  }

  return (
    <SyPage style={styles.page} actions={actions}>
      <View>
        <Camera
          ref={camera}
          style={styles.camera}
          type={CameraType.back}
        ></Camera>
      </View>
    </SyPage>
  );
}

const styles = stylize({
  page: make().row().flex(1).itemsCenter().center(),

  content: make().selfCenter().itemsCenter(),
  icon: make().font("modalIcon"),
  text: make().font("l"),

  camera: make()
    .width("cameraView")
    .ratio(1)
    .selfCenter()
    .background("light")
    .radius("l"),
});
