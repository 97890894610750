import { Dimensions } from "react-native";

export default {
  ratio: 1,

  radius: {
    n: 0,
    t: 4,
    s: 8,
    m: 8,
    l: 20,
    x: 30,
  },

  size: {
    n: 0,
    u: 1,
    t: 3,
    s: 6,
    sm: 12,
    m: 14,
    l: 16,
    lx: 20,
    x: 24,
    xl: 32,
    xxl: 48,
    dateSection:96,
    carouselButton: 20,
    starSize: 20,
    smallText: 10,
    icon: 32,
    modalIcon: 64,
    cancelIcon: 32,
    thumbnail: 64,
    requestThumbnail: 72,
    profileThumbnail: 128,
    profilePadding: 36,
    purchaseImage: 36,
    cardCvc: 64,
    visaMastercard: 32,
    iyzipay: 24,
    xyz: 200,
    carousel: 300,
    modal: "80%",
    smallModal: 320,
    half: "50%",
    authIcon: 128,
    sloganImage: 72,
    windowWidth: Dimensions.get("window").width,
    windowHeight: Dimensions.get("window").height,
    screenWidth: Dimensions.get("screen").width,
    screenHeight: Dimensions.get("screen").height,
    contentWidth: 500,
    contentWidthHalf: 250,
    tabbarHeight: 48,
    tabbarIconSize: 28,
    tabbarButtonSize: 40,
    tabbarBottomGap: 30,
    cameraView: 240,
    leftPane: 200,
    rightPane: 400,
    shippingInput:128
  },

  font: {
    main: {
      m: "Poppins-Regular",
      l: "Poppins-Bold",
    },
    secondary: {
      m: "Poppins-Regular",
      l: "Poppins-Bold",
    },
  },
};
