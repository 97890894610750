import { useState } from "react";
import { uuid } from "../lib/util";
import SyModalInput from "../widgets/SyModalInput";
import SyProductForm from "../widgets/SyProductForm";
import SyTitle from "../widgets/SyTitle";
import SyText from "../widgets/SyText";
import { View } from "react-native";
import { make, stylize } from "../lib/style";

export default function NewProductPage({}) {
  const id = uuid();
  const shortId = `${Math.floor(Math.random() * 1000000000 + 1000000000)}`;
  const [warningVisible, changeWarningVisile] = useState(true);
  return (
    <>
      <SyProductForm
        id={id}
        shortId={shortId}
        newProduct={true}
      ></SyProductForm>
      <SyModalInput
        visible={warningVisible}
        changeVisible={changeWarningVisile}
        showExit={true}
        style={styles.warningModalInput}
      >
        <View style={styles.warningModal}>
          <SyTitle content="Satış yapılmaz" noCaps={true}  style={styles.modalTitle}></SyTitle>
          <SyText content="saykl'da satış ya da eski usul takas yapılmaz, her ürün 1 saykl puan karşılığında el değiştirir." style={styles.modalText}></SyText>
        </View>
      </SyModalInput>
    </>
  );
}

const styles = stylize({
  warningModalInput: make().background('primary'),
  warningModal: make().itemsCenter(),
  modalTitle:make().color('black'),
  modalText:make().textCenter().font('m','m','black')
});
