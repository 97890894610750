import { ScrollView, View } from "react-native";
import { make, stylize } from "../lib/style";
import SyPage from "../widgets/SyPage";
import SyText from "../widgets/SyText";
import SyTitle from "../widgets/SyTitle";
import { makeColumn } from "../themes/components";

const FAQ = require("../assets/text/faq.json");

export default function FaqPage() {
  return (
    <SyPage authRequired={false}>
      <ScrollView>
        <View style={styles.sections}>
          <SyTitle content="Sıkça Sorulan Sorular"></SyTitle>

          {FAQ.map((faq) => {
            return (
              <View key={faq.id} style={styles.section}>
                <SyText style={styles.title} content={faq.soru}></SyText>
                <SyText content={faq.cevap}></SyText>
              </View>
            );
          })}
        </View>
      </ScrollView>
    </SyPage>
  );
}

const styles = stylize({
  sections: makeColumn("s").padding('m'),
  section: make(),
  title: make().font("m", "l"),
});
