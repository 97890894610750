import SyIconHome from "../icons/SyIconHome";
import SyIconMessage from "../icons/SyIconMessage";
import SyIconSearch from "../icons/SyIconSearch";
import SyIconSwap from "../icons/SyIconSwap";
import SyIconUser from "../icons/SyIconUser";

export const items = [
  {
    label: "Ana sayfa",
    screen: "Dashboard",
    route:"Main",
    icon: (props) => <SyIconHome {...props}></SyIconHome>,
    //icon: (props) => <SyIcon name="home" {...props}></SyIcon>,
  },
  {
    label: "Mesajlar",
    screen: "Chats",
    route:"Main",
    icon: (props) => <SyIconMessage {...props}></SyIconMessage>,
  },
  {
    label: "Arama",
    screen: "Search",
    route:"Main",
    icon: (props) => <SyIconSearch {...props}></SyIconSearch>,
  },
  {
    label: "Talepler",
    screen: "Requests",
    route:"Main",
    icon: (props) => <SyIconSwap {...props}></SyIconSwap>,
  },
  {
    label: "Profil",
    screen: "Profile",
    route:"Main",
    icon: (props) => <SyIconUser {...props}></SyIconUser>,
  },
];
