import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { make } from "../lib/style";

import MemberMessagesPage from "../pages/MemberMessagesPage";
import SystemMessagesPage from "../pages/SystemMessagesPage";
import { useReactiveVar } from "@apollo/client";
import { notificationsVar } from "../queries/cache";
import SyBadge from "../widgets/SyBadge";

const Tab = createMaterialTopTabNavigator();

export default function ChatsLayout() {
  const notifications = useReactiveVar(notificationsVar);

  const options = {
    system: {
      title: "Sistem Mesajları",
      tabBarBadge: notifications.SystemMessage ? SyBadge : null,
    },
    member: {
      title: "Üye Mesajları",
      tabBarBadge: notifications.UserMessage ? SyBadge : null,
    },
  };

  return (
    <Tab.Navigator screenOptions={screenOptions} id="Chats">
      <Tab.Screen
        name="MemberMessages"
        component={MemberMessagesPage}
        options={options.member}
      ></Tab.Screen>
      <Tab.Screen
        name="SystemMessages"
        component={SystemMessagesPage}
        options={options.system}
      ></Tab.Screen>
    </Tab.Navigator>
  );
}

const colors = make().theme.colors;

const screenOptions = {
  tabBarShowIcon: false,
  tabBarIcon: ({ focused, color }) => {},
  tabBarIndicatorStyle: make().background("primary").style,
  tabBarLabelStyle: make().capitalize().color("main").style,
  lazy: true,
  unmountOnBlur: true,
  tabBarStyle: {
    backgroundColor: colors.headerColor,
    borderBottomColor: colors.dark,
  },
};
