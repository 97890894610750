import { Image, Linking, Platform, Pressable, View } from "react-native";
import { Link } from "@react-navigation/native";
import { make, stylize } from "../lib/style";
import { makeColumn, makeRow } from "../themes/components";

const GOOGLE_BADGE = require("../assets/images/google-play-badge.png");
const APPLE_BADGE = require("../assets/images/app-store-badge.svg");

export default function SyStoreBadges({ vertical = false }) {
  if (Platform.OS !== "web") {
    return null;
  }

  let style;

  if (vertical) {
    style = styles.badgesVertical;
  } else {
    style = styles.badges;
  }

  return (
    <View style={style}>
      <SyBadge
        image={GOOGLE_BADGE}
        imageStyle={styles.google}
        to="https://play.google.com/store/apps/details?id=com.saykl.mobile"
      ></SyBadge>
      <SyBadge
        image={APPLE_BADGE}
        imageStyle={styles.apple}
        to="https://apps.apple.com/us/app/saykl/id6447631282"
      ></SyBadge>
    </View>
  );
}

export function SyBadge({ image, to, imageStyle }) {
  const goto = () => {
    Linking.openURL(to);
  };

  return (
    <Pressable onPress={goto}>
      <Image source={image} style={imageStyle}></Image>
    </Pressable>
  );
}

const styles = stylize({
  badges: makeRow("s").itemsCenter(),
  badgesVertical: makeColumn("s").itemsCenter(),
  google: make()
    .ratio(646 / 192)
    .set({
      height: 42,
    }),
  apple: make()
    .ratio(151 / 40)
    .set({
      height: 42,
    }),
});
