import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import ProfilePage from "../pages/ProfilePage";
import SearchPage from "../pages/SearchPage";
import RequestsLayout from "./RequestsLayout";
import SyBrandIcon from "../widgets/SyBrandIcon";
import DashboardLayout from "./DashboardLayout";
import ChatsLayout from "./ChatsLayout";
import { make, stylize } from "../lib/style";
import SyTabBar from "../widgets/SyTabBar";
import { useScreenState } from "../lib/screen";
import { Platform } from "react-native";
import SyButton from "../widgets/SyButton";
import { useNavigation } from "@react-navigation/native";

const Tab = createBottomTabNavigator();

export default function TabLayout() {
  const { state } = useScreenState();

  const getTabbar = (props) => {
    if (state !== "small" && state !== "tablet") {
      return null;
    }

    return <SyTabBar {...props}></SyTabBar>;
  };

  return (
    <Tab.Navigator
      screenOptions={navigatorScreenOptions}
      tabBar={getTabbar}
      initialRouteName="Search"
      id="Main"
    >
      <Tab.Screen name="Dashboard" component={DashboardLayout}></Tab.Screen>
      <Tab.Screen name="Chats" component={ChatsLayout}></Tab.Screen>
      <Tab.Screen
        name="Search"
        component={SearchPage}
        options={{ title: "Arama" }}
      ></Tab.Screen>
      <Tab.Screen name="Requests" component={RequestsLayout}></Tab.Screen>
      <Tab.Screen
        name="Profile"
        component={ProfilePage}
        options={{ title: "Profil" }}
      ></Tab.Screen>
    </Tab.Navigator>
  );
}
const colors = make().theme.colors;

const navigatorScreenOptions = ({ route }) => {
  return {
    headerTitle: SyBrandIcon,
    headerTitleAlign: "center",
    headerStyle: {
      backgroundColor: colors.headerColor,
      borderBottomColor: colors.headerBorderColor,
    },
    headerRight: () => <SyDownloadLink></SyDownloadLink>,
    headerBackTitle: "",
    unmountOnBlur: true,
    lazy: true,
  };
};

function SyDownloadLink() {
  const navigation = useNavigation();

  if (Platform.OS === "web") {
    return (
      <SyButton
        label="İndir"
        style={styles.download}
        labelStyle={styles.downloadLabel}
        onPress={() => navigation.navigate("Download")}
      ></SyButton>
    );
  }

  return (
    <SyButton
      icon="chat-question"
      iconStyle={styles.faqIcon}
      style={styles.faq}
      onPress={() => navigation.navigate("Faq")}
    ></SyButton>
  );
}

const styles = stylize({
  download: make()
    .padding("m", false, true)
    .padding("s", true, false)
    .margin("s", false, true)
    .radius("l")
    .background("secondary"),
  downloadLabel: make().font("m", "l", "white"),
  faq: make()
    .margin("s", false, true)
    .radius("l")
    .background("transparent"),
  faqIcon: make().font("xl", "l", "main"),
  
});
