import { Image, Pressable, View } from "react-native";
import { useScreenState } from "../lib/screen";
import { make, stylize } from "../lib/style";
import { makeColumn, makeRow } from "../themes/components";
import { Link, useNavigation } from "@react-navigation/native";
import { useState } from "react";

import { items } from "../lib/menu.js";

import SySupportCard from "./SySupportCard";
import SyStoreCard from "./SyStoreCard";
import SyIcon from "./SyIcon";
import SyText from "./SyText";
import { loggedInVar } from "../queries/cache";
import { useReactiveVar } from "@apollo/client";
import SyProductCard from "./SyProductCard.js";

const SAYKL_LOGO_LIGHT = require("../assets/icon-w-text-light.png");
const SAYKL_LOGO_DARK = require("../assets/icon-w-text-dark.png");
const SAYKL_ICON_LIGHT = require("../assets/icon.png");
const SAYKL_ICON_DARK = require("../assets/icon.png");

function SyWebPane({ children, style }) {
  return <View style={[styles.webPane, style]}>{children}</View>;
}

function SyMenuLink({
  label,
  icon,
  route,
  screen,
  iconType = "svg",
  iconStyle,
  index,
  activeIndex,
  changeActiveIndex,
  iconOnly = false,
}) {
  const navigation = useNavigation();
  const theme = make().theme;
  let style;
  if (activeIndex === index) {
    style = styles.menuLinkTextActive;
  } else {
    style = styles.menuLinkText;
  }

  const navigate = () => {
    navigation.navigate(route, { screen });
    changeActiveIndex(index);
  };

  return (
    <Pressable style={styles.menuLink} onPress={navigate}>
      <View>
        {iconType === "svg" ? (
          icon({ style: styles.menuLinkSvg, color: theme.colors.main })
        ) : (
          <SyIcon name={icon} style={[styles.menuLinkIcon, iconStyle]}></SyIcon>
        )}
      </View>
      {!iconOnly && <SyText style={style} content={label}></SyText>}
    </Pressable>
  );
}

export default function SyWebLayout({ children }) {
  const { state, screenWidth,iconsOnly,ads } = useScreenState();
  const theme = make().name;
  const isLoggedIn = useReactiveVar(loggedInVar);

  const logo = theme === "light" ? SAYKL_LOGO_LIGHT : SAYKL_LOGO_DARK;
  const icon = theme === "light" ? SAYKL_ICON_LIGHT : SAYKL_ICON_DARK;

  const [activeIndex, changeActiveIndex] = useState(0);

  return (
    <View style={styles.webPage}>
      <SyWebPane style={styles.webPaneLeft}>
        {!iconsOnly && (
          <Link to="/arama">
            <Image source={logo} style={styles.logo}></Image>
          </Link>
        )}
        {iconsOnly && (
          <Link to="/arama">
            <Image source={icon} style={styles.sayklIcon}></Image>
          </Link>
        )}
        <View style={styles.menuTop}>
          {items.map((item, index) => (
            <SyMenuLink
              {...item}
              key={item.label}
              index={index}
              activeIndex={activeIndex}
              changeActiveIndex={changeActiveIndex}
              iconOnly={iconsOnly}
            ></SyMenuLink>
          ))}
        </View>
        {isLoggedIn && (
          <View style={styles.menuBottom}>
            <SyMenuLink
              icon="plus-circle"
              iconType="icon"
              label="İlan ver"
              route="NewProduct"
              iconStyle={styles.menuLinkIconPrimary}
              iconOnly={iconsOnly}
            ></SyMenuLink>
            <SyMenuLink
              icon="cog"
              iconType="icon"
              label="Ayarlar"
              route="Settings"
              iconOnly={iconsOnly}
            ></SyMenuLink>
          </View>
        )}
      </SyWebPane>
      <View style={styles.webMainContent}>{children}</View>
      {ads && (
        <SyWebPane style={styles.webPaneRight}>
          <View style={styles.webBanner}>
            <SyStoreCard></SyStoreCard>
          </View>
          <View style={styles.webBanner}>
            <SySupportCard></SySupportCard>
          </View>
          <View style={styles.webBanner}>
            <SyProductCard></SyProductCard>
          </View>
          <View style={styles.webBanner}></View>
          <View style={styles.webBanner}></View>
        </SyWebPane>
      )}
    </View>
  );
}

const styles = stylize({
  webPage: makeRow("n")
    .fullSize()
    .itemsCenter()
    .spaceBetween()
    .overflowHidden(),
  webMainContent: make()
    .set((theme) => ({
      maxWidth: theme.size.contentWidth,
      height: "100%",
      borderLeftWidth: theme.size.u,
      borderRightWidth: theme.size.u,
    }))
    .flex(1)
    .borderColor("webBorderColor")
    .selfCenter()
    .overflowHidden(),
  webPane: makeColumn("m").column().background("light").start().fullHeight(),
  webPaneLeft: make()
    .padding("l"),
  webPaneRight: makeColumn("m")
    .set((theme) => ({
      width: theme.size.rightPane,
    }))
    .itemsCenter()
    .padding("m"),
  menuTop: makeColumn("l").flex(1).padding("x", true, false),
  menuBottom: makeColumn("l"),
  menuLink: makeRow("m").itemsCenter(),
  menuLinkText: make().font("l", "m", "main"),
  menuLinkTextActive: make().font("l", "l", "main"),
  menuLinkIcon: make().font("xl", "m", "main"),
  menuLinkIconPrimary: make().font("xl", "m", "primary"),
  menuLinkSvg: make()
    .set((theme) => ({
      width: 28,
    }))
    .ratio(1),
  webBanner: make().background("transparent").fullWidth().flex(1).row(),
  logo: make()
    .set({
      width: 128,
    })
    .ratio(226 / 100),
  sayklIcon: make().ratio(1).width("icon"),
});
