import { useEffect, useState } from "react";
import { make, stylize } from "../lib/style";
import { errorVar, profileVar } from "../queries/cache";
import { useChat } from "../queries/chat";
import { useProduct, useProductRemove } from "../queries/product";
import SyButton from "../widgets/SyButton";
import SyModalInput from "../widgets/SyModalInput";

import SyPage from "../widgets/SyPage";
import SyProductDetail from "../widgets/SyProductDetail";
import SyTitle from "../widgets/SyTitle";
import { useReactiveVar } from "@apollo/client";
import { productReserved } from "../lib/error";
import { Platform } from "react-native";

export default function ProductDetailPage({ navigation, route }) {
  const profile = useReactiveVar(profileVar);
  const productRet = useProduct(route.params.id);
  const [fetchChat] = useChat(null, route.params.id, null, null, true);
  const product = productRet.data?.product;

  const [removeProduct] = useProductRemove();
  const [removeModalVisible, changeRemoveModalVisible] = useState(false);
  let actions;

  useEffect(() => {
    if (product?.availability === "Reserved") {
      productReserved();
    }
  }, [product]);

  if (product?.isOwner) {
    actions = [
      {
        label: "Güncelle",
        authRequired: true,
        redirect: "UpdateProduct",
        params: route.params,
        replace: true,
        disabled: product?.availability === "Reserved",
      },
      {
        label: "Kaldır",
        authRequired: true,
        disabled: product?.availability === "Reserved",
        onPress: () => {
          if (product?.availability !== "Reserved") {
            changeRemoveModalVisible(true);
          }
        },
      },
    ];
  } else if (product?.availability === "Reserved") {
    actions = [
      {
        label: "Talep Gönder",
        disabled: true,
      },
      /*{
        label: "Mesaj Gönder",
        disabled: true,
      },*/
    ];
  } else {
    actions = [
      {
        label: "Talep Gönder",
        disabled: product?.owner.isBlocked,
        authRequired: true,
        redirect: "NewRequest",
      },
      /*{
        label: "Mesaj Gönder",
        authRequired: true,
        disabled: product?.owner.isBlocked,
        onPress: async () => {
          const chatRet = await fetchChat({
            variables: {
              productId: route.params.id,
              senderId: product.owner.id,
              receiverId: profile.id,
            },
          });
          const chat = chatRet.data?.chat;

          if (chat) {
            navigation.navigate("ChatDetail", { id: chat.id });
          } else {
            navigation.navigate("NewChat", { productId: route.params.id });
          }
        },
      },*/
    ];
  }

  return (
    <SyPage
      style={styles.page}
      error={productRet.error}
      loading={productRet.loading}
      actions={actions}
      authRequired={false}
    >
      {product && <SyProductDetail {...product}></SyProductDetail>}
      <SyModalInput
        visible={removeModalVisible}
        changeVisible={changeRemoveModalVisible}
      >
        <SyTitle
          content="Emin miyiz?"
          style={styles.removeTitle}
          noCaps={true}
        ></SyTitle>
        <SyButton
          label="İlanı Kaldır"
          onPress={() => removeProduct({ variables: { id: route.params.id } })}
          style={styles.requestButton}
          labelStyle={styles.requestButtonLabel}
        ></SyButton>
      </SyModalInput>
    </SyPage>
  );
}

const styles = stylize({
  page: make().column().flex(1),
  requestButton: make().background("primary"),
  requestButtonLabel: make().font("l", "l", "light"),
  removeTitle: make()
    .set((theme) => ({
      marginBottom: theme.size.l,
    }))
    .selfCenter(),
});
