const PICK = {
  id: "",
  name: "Seç",
};

export const CATEGORIES_DEFAULT = {
  id: "",
  name: "Kategori",
};

export const CATEGORIES_DEFAULT_FORM = { ...PICK };

export const DISTANCES = [
  {
    name: "Tüm Mesafeler",
    value: 0,
  },
  {
    name: "10km",
    value: 10,
  },
  {
    name: "25km",
    value: 25,
  },
  {
    name: "50km",
    value: 50,
  },
  {
    name: "100km",
    value: 100,
  },
];

export const DISTANCES_DEFAULT = {
  name: "Mesafe",
  value: 0,
};

export const CONDITIONS_STATES = [
  {
    id: "New",
    name: "Yeni Gibi",
  },
  {
    id: "Fine",
    name: "İyi",
  },
  {
    id: "Weared",
    name: "Yıpranmış",
  },
  {
    id: "Antique",
    name: "Antika",
  },
  {
    id: "Faulty",
    name: "Arızalı",
  },
];

export const CONDITIONS = [
  {
    id: "",
    name: "Tüm Durumlar",
  },
  ...CONDITIONS_STATES,
];

export const CONDITIONS_DEFAULT = {
  id: "",
  name: "Durum",
};

export const CONDITIONS_DEFAULT_FORM = { ...PICK };

export const LOCATION_TYPES = [
  {
    id: "Profile",
    name: "Kayıtlı Konum",
  },
  {
    id: "Current",
    name: "Mevcut Konum",
  },
];
export const LOCATION_TYPES_DEFAULT = LOCATION_TYPES[0];
export const LOCATION_TYPES_DEFAULT_FORM = { ...PICK };

export const SHIPMENT_TYPES_REQUIRED = [
  {
    id: "Manual",
    name: "Elden Teslim",
  },
  {
    id: "Shipping",
    name: "Anlaşmalı Kargo",
  },
];

export const SHIPMENT_TYPES_DEFAULT = {
  id: "",
  name: "Teslimat",
};

export const SHIPMENT_TYPES_DEFAULT_FORM = { ...PICK };
export const SHIPMENT_TYPES_SEARCH = [
  {
    id: "",
    name: "Farketmez",
  },
  ...SHIPMENT_TYPES_REQUIRED
];

export const SHIPMENT_TYPES = [
  {
    id: "Any",
    name: "Farketmez",
  },
  ...SHIPMENT_TYPES_REQUIRED,
];

export const REQUEST_STATES = [
  {
    id: "Waiting",
    name: "Cevap bekleniyor...",
  },
  {
    id: "Accepted",
    name: "Onaylandı.",
  },
  {
    id: "Declined",
    name: "Reddedildi.",
  },
  {
    id: "Delivered",
    name: "Teslim edildi.",
  },
  {
    id: "Received",
    name: "Teslim alındı.",
  },
  {
    id: "Complete",
    name: "Başarılı!",
  },
  {
    id: "Cancelled",
    name: "Vazgeçildi.",
  },
];

export const GOOGLE_API_KEY = "AIzaSyCrybKSalhnfrtylyVPu3mecngqtdCl8K8";

export function findShipmentType(id,newProduct) {

  return SHIPMENT_TYPES.find((item) => {
    if (item.id === id) {
      return true;
    }
    if (!id && item.id === "Any" && !newProduct) {
      return true;
    }

    return false;
  });
}

export function findLocationType(id) {
  return LOCATION_TYPES.find((item) => item.id === id);
}

export function findCondition(id) {
  return CONDITIONS.find((item) => item.id === id);
}

export function findRequestState(id) {
  return REQUEST_STATES.find((item) => item.id === id);
}
