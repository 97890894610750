import { ScrollView } from "react-native";
import { useProfileIncomingRequests } from "../queries/profile";
import SyNA from "../widgets/SyNA";
import SyPage from "../widgets/SyPage";
import SyRequestListItem from "../widgets/SyRequestListItem";
import { clearNotifications } from "../lib/notification";
import SySpace from "../widgets/SySpace";
import SyInformatique from "../widgets/SyInformatique";

const ACTIONS = [
  {
    label: "Geçmiş Talepler",
    navigate: "IncomingRequestsArchive",
  },
];

const INFO_IMAGE = require("../assets/incoming.png");
const WARNING_IMAGE = require("../assets/info.png");

export default function IncomingRequestsPage() {
  const { loading, data, refetch } = useProfileIncomingRequests();

  const incomingRequests = data?.profile?.incomingRequests;

  clearNotifications("SenderRequest");

  return (
    <SyPage
      loading={loading}
      authRequired={true}
      actions={ACTIONS}
      refetch={refetch}
      data={data}
    >
      <SyInformatique
        margin={true}
        image={WARNING_IMAGE}
        text="İlanına gelen talebin içeriğine ulaşmak için detaylara tıklayıp talep gönderen üyeye mesaj yazarak iletişime geçebilirsin."
      ></SyInformatique>
      <SyInformatique
        margin={true}
        image={INFO_IMAGE}
        text="Talep edilen ürünü kargo şubesine ya da talep eden kişiye elden teslim ettiğinde “Teslim Ettim” butonunu işaretlemeyi unutma."
      ></SyInformatique>
      <ScrollView>
        {incomingRequests &&
          incomingRequests.map((request) => {
            if (!request.sender || !request.product || !request.receiver) {
              return null;
            }

            return (
              <SyRequestListItem
                {...request}
                incoming={true}
                key={request.id}
              ></SyRequestListItem>
            );
          })}
        <SySpace mode="page"></SySpace>
      </ScrollView>
    </SyPage>
  );
}
