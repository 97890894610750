import { Platform, ScrollView, View } from "react-native";
import SyPage from "../widgets/SyPage";
import { make, stylize } from "../lib/style";
import { makeColumn, makeRow } from "../themes/components";
import WebView from "react-native-webview";

export default function UsersAgreementPage() {
  return (
    <SyPage authRequired={false}>
      <ScrollView contentContainerStyle={styles.container}>
        {Platform.OS !== "web" && (
          <WebView
            source={{ uri: "https://saykl-text.surge.sh/user-agreement.html" }}
          ></WebView>
        )}
        {Platform.OS === "web" && (
          <iframe
            style={styles.iframe}
            src="https://saykl-text.surge.sh/user-agreement.html"
          ></iframe>
        )}
      </ScrollView>
    </SyPage>
  );
}

const styles = stylize({
  container: makeColumn("m").itemsStretch().flex(1),
  iframe:make().set({
    flex:1,
    border:'none',
    height:'100%'

  })
});
