import { make, stylize } from "../lib/style";
import { capitalize } from "../lib/util";
import SyText from "./SyText";

export default function SyTitle(props) {
  const content = props.noCaps ? props.content : capitalize(props.content);
  return (
    <SyText
      {...props}
      content={content}
      style={[styles.text,props.style]}
    ></SyText>
  );
}

const styles = stylize({
  text: make().font("l", "l"),
});
