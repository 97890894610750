import { useState } from "react";
import { uuid } from "../lib/util";
import { useChat, useCreateChat } from "../queries/chat";
import { useProductBasic } from "../queries/product";
import SyInputText from "../widgets/SyInputText";
import { make, stylize } from "../lib/style";
import { makeBox, makeColumn, makeRow } from "../themes/components";
import { Pressable, ScrollView, View } from "react-native";
import SyText from "../widgets/SyText";
import SyTitle from "../widgets/SyTitle";
import { processState } from "../lib/graphql";
import { useRequest } from "../queries/request";
import { profileVar } from "../queries/cache";
import { findShipmentType } from "../lib/constants";
import SyPage from "../widgets/SyPage";
import SyUserCard from "../widgets/SyUserCard";
import SySpace from "../widgets/SySpace";
import SyButton from "../widgets/SyButton";

export default function RequestDetailPage({ navigation, route }) {
  const profile = profileVar();
  const chatId = route.params.chatId;
  const [newMessage, changeNewMessage] = useState("");
  const productRet = useProductBasic(route.params.productId);
  const [fetchSupportChat] = useChat(
    null,
    route.params.productId,
    null,
    null,
    true
  );

  let requestRet;

  if (route.params.requestId) {
    requestRet = useRequest(route.params.requestId);
  } else {
    requestRet = {};
  }

  const [createChat] = useCreateChat();

  const product = productRet.data?.product;
  const request = requestRet.data?.request;

  let other;
  let shipmentType;

  if (route.params.requestId && request) {
    if (request.sender.id === profile.id) {
      other = request.receiver;
    } else if (request.receiver.id === profile.id) {
      other = request.sender;
    }
    shipmentType = request.shipmentType;
  } else if (product) {
    other = null;
  }

  const sendMessage = async () => {
    if (!newMessage) {
      return;
    }

    if (!chatId) {
      const chatId = uuid();
      await createChat({
        variables: {
          id: chatId,
          productId: route.params.productId,
          message: newMessage,
          otherId: other.id,
        },
      });
      changeNewMessage("");
      navigation.replace("ChatDetail", { id: chatId });
    }
  };
  const gotoChatDetail = () => {
    navigation.replace("ChatDetail", { id: chatId });
  };
  const requestState = processState(productRet, requestRet);
  const gotoProfile = () => {
    navigation.replace("UserDetail", { id: other.id });
  };

  const report = async () => {
    const ret = await fetchSupportChat({
      variables: {
        productId: route.params.productId,
        senderId: "5f75488b-bab4-4ae2-947b-349b23cef8fe",
        receiverId: route.params.receiverId,
      },
    });

    const chat = ret.data?.chat;

    if (chat) {
      navigation.navigate("ChatDetail", { id: chat.id });
    } else {
      navigation.navigate("NewChat", {
        productId: route.params.productId,
        otherId: "5f75488b-bab4-4ae2-947b-349b23cef8fe",
      });
    }
  };

  const ACTIONS = [];

  if (chatId) {
    ACTIONS.push({
      label: "Sohbete git",
      authRequired: true,
      onPress: gotoChatDetail,
    });
  } else {
    ACTIONS.push({
      label: "Gönder",
      authRequired: true,
      onPress: sendMessage,
    });
  }

  return (
    <SyPage actions={ACTIONS}>
      <ScrollView>
        {other && (
          <Pressable style={styles.header} onPress={gotoProfile}>
            <SyUserCard {...other} title="Üye profili"></SyUserCard>
          </Pressable>
        )}
        <View style={styles.page}>
          <SyTitle content="Talep Detayları"></SyTitle>
          <View style={styles.detail}>
            <View>
              {shipmentType && (
                <SyText
                  content={`${
                    findShipmentType(shipmentType, false).name
                  } talep ediliyor.`}
                ></SyText>
              )}
              {request && (
                <SyText
                  content={`Talep tarihi: ${request.datetime}`}
                  style={styles.light}
                ></SyText>
              )}
            </View>
            <SyButton
              label="Bildir"
              style={styles.reportButton}
              labelStyle={styles.reportButtonLabel}
              onPress={report}
            ></SyButton>
          </View>
          {!chatId && (
            <>
              <SyTitle content="Üyeyle sohbet"></SyTitle>
              <SyInputText
                style={styles.input}
                value={newMessage}
                onChangeText={changeNewMessage}
                multiline={true}
                placeholder="Teslimat detaylarını netleştirmek için konuşma başlat"
              ></SyInputText>
            </>
          )}
        </View>
      </ScrollView>
    </SyPage>
  );
}

const styles = stylize({
  content: makeColumn("s"),
  input: make().set({
    height: 120,
  }),
  light: make().color("lightGrey3"),
  profileButton: make()
    .background("primary")
    .set({
      width: 42,
      height: 42,
      borderRadius: 21,
    })
    .center()
    .itemsCenter()
    .shadow(1),
  detail: makeBox().row("m").spaceBetween().itemsCenter(),
  page: makeColumn("m")
    .padding("m", false, true)
    .set((theme) => ({
      marginBottom: theme.size.l,
    })),
  reportButton: make().background("secondary").padding("xl", false, true),
  reportButtonLabel: make().font("m", "m", "white"),
});
