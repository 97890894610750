import { useState } from "react";
import SyButton from "../widgets/SyButton";
import SyFieldInput from "../widgets/SyFieldInput";
import SyInputText from "../widgets/SyInputText";
import SyPage from "../widgets/SyPage";
import { useSaveProfile } from "../queries/profile";
import { make, stylize } from "../lib/style";
import { makeColumn } from "../themes/components";
import SyPageModal from "../widgets/SyPageModal";
import { passwordsEmpty, passwordsNotMatching } from "../lib/error";

export default function ResetPasswordDetailPage({ route, navigation }) {
  const [saveProfile] = useSaveProfile();
  const code = route.params.id;
  const [newPassword, changeNewPassword] = useState("");
  const [newPasswordRepeat, changeNewPasswordRepeat] = useState("");

  const saveProfileData = async () => {
    if (!newPassword || !newPasswordRepeat) {
      passwordsEmpty();
      return;
    }

    if (newPassword !== newPasswordRepeat) {
      passwordsNotMatching();
      return;
    }

    const res = await saveProfile({
      variables: {
        resetPasswordCode: code,
        password: newPassword,
        passwordRepeat: newPasswordRepeat,
      },
    });

    if (!res.error) {
      navigation.navigate("Login");
    }
  };

  return (
    <SyPageModal authRequired={false}>
      <SyFieldInput title="Şifre Sıfırlama" style={styles.password}>
        <SyInputText
          placeholder="Yeni Şifre"
          value={newPassword}
          onChangeText={changeNewPassword}
          secureTextEntry={true}
        ></SyInputText>
        <SyInputText
          placeholder="Yeni Şifre (Tekrar)"
          value={newPasswordRepeat}
          onChangeText={changeNewPasswordRepeat}
          secureTextEntry={true}
        ></SyInputText>

        <SyButton
          label="Kaydet"
          onPress={saveProfileData}
          style={styles.requestButton}
          labelStyle={styles.requestButtonLabel}
          disabled={!newPassword || !newPasswordRepeat}
        ></SyButton>
      </SyFieldInput>
    </SyPageModal>
  );
}

const styles = stylize({
  password: makeColumn("m"),
  button: make()
    .background("light")
    .set((theme) => ({
      marginTop: theme.size.m,
    })),
  requestButton: make().background("primary"),
  requestButtonLabel: make().font("l", "l", "light"),
});
