import { gQuery, gMutation } from "../lib/graphql";
import { loggedInVar, profileVar } from "../queries/cache";
import { navigate, replace } from "../lib/navigation";
import { removeToken, setToken } from "../storage/token";
import {
  fetchProfileFavoritesQuery,
  fetchProfileProductsQuery,
  fetchProfileQuery,
  loginMutation,
  signupMutation,
  fetchProfileChatsQuery,
  fetchProfileIncomingRequestsQuery,
  fetchProfileOutgoingRequestsQuery,
  saveProfileMutation,
  socialLoginMutation,
  savePushTokenMutation,
  blockUserMutation,
  unblockUserMutation,
  fetchProfileCompleteOutgoingRequestsQuery,
  fetchProfileCompleteIncomingRequestsQuery,
  saveProfileSettingsMutation,
  confirmEmailMutation,
  resetPasswordMutation,
  deleteProfileMutation,
  reportUserMutation,
} from "./gql";
import * as Linking from "expo-linking";

export function useProfile(manual) {
  return gQuery(
    fetchProfileQuery,
    {
      onCompleted(data) {
        profileVar(data.profile);
      },
      async onError(error) {
        if (loggedInVar() === true) {
          loggedInVar(false);
          await removeToken();
        }
      },
    },
    manual
  );
}

export function useProfileFavorites() {
  return gQuery(fetchProfileFavoritesQuery);
}

export function useProfileProducts() {
  return gQuery(fetchProfileProductsQuery);
}

export function useProfileChats() {
  return gQuery(fetchProfileChatsQuery, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });
}

export function useProfileIncomingRequests() {
  return gQuery(fetchProfileIncomingRequestsQuery, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-and-network",
  });
}

export function useProfileOutgoingRequests() {
  return gQuery(fetchProfileOutgoingRequestsQuery, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-and-network",
  });
}

export function useProfileCompleteIncomingRequests() {
  return gQuery(fetchProfileCompleteIncomingRequestsQuery, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-and-network",
  });
}

export function useProfileCompleteOutgoingRequests() {
  return gQuery(fetchProfileCompleteOutgoingRequestsQuery, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-and-network",
  });
}

export function useLogin() {
  return gMutation(loginMutation, {
    onCompleted(data) {
      const token = data.login;
      processToken(token);
    },
    onError() {},
  });
}

export function useSocialLogin(isApple = false) {
  return gMutation(socialLoginMutation, {
    onCompleted(data) {
      const token = data.socialLogin;

      if (token.isNewUser) {
        processToken(token, false);
        replace("SocialLogin", { isApple: isApple });
      } else {
        processToken(token);
      }
    },
    onError() {},
  });
}

export function useSaveProfile() {
  return gMutation(saveProfileMutation);
}

export function useSaveProfileSettings() {
  return gMutation(saveProfileSettingsMutation);
}

export function useSignup() {
  return gMutation(signupMutation, {
    onCompleted(data) {
      const token = data.signup;
      processToken(token, false);
    },
    onError() {},
  });
}

export function useSavePushToken() {
  return gMutation(savePushTokenMutation);
}

export async function processToken(token, navigateToSearch = true) {
  if (!token) {
    return;
  }

  loggedInVar(true);
  await setToken(token);

  if (navigateToSearch) {
    navigate("Main", { screen: "Search" });
    return;
  }

  const page = pageVar();

  if (page.link) {
    Linking.openURL(page.link);
    return;
  }

  if (page.name) {
    if (page.parent && page.parent !== "App") {
      const parent = page.parent;
      navigate(parent, { screen: page.name, params: page.params });
    } else {
      replace(page.name, page.params);
    }

    pageVar({ name: "", parent: "", params: null });
  }
}

export function isProfileOwner(userId) {
  const profile = profileVar();

  if (!profile) {
    return false;
  }

  return profile.id === userId;
}

export function useBlockUser() {
  return gMutation(blockUserMutation, {
    refetchQueries: ["GetUser"],
  });
}

export function useUnblockUser() {
  return gMutation(unblockUserMutation, {
    refetchQueries: ["GetUser"],
  });
}

export function useReportUser() {
  return gMutation(reportUserMutation, {
    refetchQueries: ["GetUser"],
  });
}

export function useConfirmEmail() {
  return gMutation(confirmEmailMutation);
}

export function useResetPassword() {
  return gMutation(resetPasswordMutation);
}

export function useDeleteProfile() {
  return gMutation(deleteProfileMutation);
}
