import { Svg, Path } from "react-native-svg";

export default function SyIconSwap(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 121.39 121.39"
      {...props}
    >
      <Path
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={11.0352}
        stroke={props.color}
        d="M60.693 38.623h13.794V5.517L115.87 46.9 74.487 88.281V60.693H44.14V30.346L5.517 71.728 44.14 115.87V82.764h13.794"
      />
    </Svg>
  );
}
