import * as React from "react";
import Svg, { Path } from "react-native-svg";
export function SyIconHAppyHat(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 64 64"
      {...props}
    >
      <Path d="m45.465 46.051-.283-.283a9.477 9.477 0 0 0-1.844-1.427C49.927 43.552 56 41.952 56 39c0-2.092-3.004-3.703-8.933-4.794A171.798 171.798 0 0 1 49.93 7.46L50.206 6H13.794l.275 1.459a171.824 171.824 0 0 1 2.863 26.747C11.004 35.297 8 36.908 8 39c0 2.952 6.073 4.552 12.662 5.341a9.468 9.468 0 0 0-1.844 1.427l-.283.283A13.395 13.395 0 0 1 9 50H6.586l5.172 5.172C14.227 57.641 17.509 59 21 59s6.773-1.359 9.242-3.828L32 53.414l1.758 1.758C36.227 57.641 39.509 59 43 59s6.773-1.359 9.242-3.828L57.414 50H55a13.395 13.395 0 0 1-9.535-3.949zM16.202 8h31.595a173.856 173.856 0 0 0-2.448 20.056C41.456 28.672 36.859 29 32 29s-9.456-.328-13.349-.944A173.666 173.666 0 0 0 16.202 8zm28.874 26.098C41.242 34.686 36.747 35 32 35s-9.242-.314-13.076-.902a172.784 172.784 0 0 0-.162-4.001c3.903.589 8.45.903 13.238.903s9.335-.314 13.238-.904a173.096 173.096 0 0 0-.162 4.002zM10 39c0-.34 1.378-1.84 8.12-2.972l.095-.016C22.236 36.657 26.987 37 32 37s9.764-.343 13.785-.988l.095.016C52.622 37.16 54 38.66 54 39c0 .814-5.742 4-22 4s-22-3.186-22-4zm22 6 .689-.003a9.867 9.867 0 0 0-.689.594 9.427 9.427 0 0 0-.689-.594L32 45zm18.828 8.758C48.737 55.849 45.957 57 43 57s-5.737-1.151-7.828-3.242L32 50.586l-3.172 3.172C26.737 55.849 23.957 57 21 57s-5.737-1.151-7.828-3.242l-1.919-1.919a15.368 15.368 0 0 0 8.696-4.374l.283-.283A7.4 7.4 0 0 1 25.5 45a7.4 7.4 0 0 1 5.268 2.182L32 48.414l1.232-1.232A7.4 7.4 0 0 1 38.5 45a7.4 7.4 0 0 1 5.268 2.182l.283.283a15.372 15.372 0 0 0 8.696 4.374l-1.919 1.919z" />
      <Path d="m27.432 20.271-1.076 6.6L32 23.751l5.645 3.12-1.076-6.6 4.507-4.618-6.234-.953L32 8.646 29.158 14.7l-6.233.953 4.507 4.618zm3.084-3.754L32 13.354l1.484 3.163 3.44.525-2.493 2.556.576 3.531L32 21.466l-3.008 1.663.576-3.531-2.493-2.556 3.441-.525zM6 15h2v3H6zM2.05 17.465l1.415-1.414 2.121 2.122-1.414 1.414zM1 20h3v2H1zM2.051 24.535l2.121-2.121 1.415 1.414-2.122 2.121zM6 24h2v3H6zM8.414 23.828l1.414-1.414 2.122 2.121-1.415 1.414zM10 20h3v2h-3zM8.414 18.171l2.121-2.12 1.414 1.413-2.121 2.122zM56 6h2v3h-2zM52.05 8.466l1.415-1.414 2.12 2.121-1.413 1.414zM51 11h3v2h-3zM52.05 15.535l2.122-2.121 1.414 1.414-2.12 2.121zM56 15h2v3h-2zM58.414 14.828l1.414-1.414 2.121 2.121-1.414 1.415zM60 11h3v2h-3zM58.413 9.172l2.122-2.122 1.414 1.415-2.121 2.121zM9 1h2v2H9zM9 5h2v2H9zM11 3h2v2h-2zM7 3h2v2H7zM59 41h2v2h-2zM59 45h2v2h-2zM61 43h2v2h-2zM57 43h2v2h-2zM51 26h2v2h-2zM51 30h2v2h-2zM53 28h2v2h-2zM49 28h2v2h-2zM3 42h2v2H3zM3 46h2v2H3zM5 44h2v2H5zM1 44h2v2H1z" />
    </Svg>
  );
}
