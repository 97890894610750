import { gMutation, gQuery } from "../lib/graphql";
import { convertSayklPointMutation, fetchUserQuery } from "./gql";

export function useUser(id) {
  return gQuery(fetchUserQuery, {
    variables: { id },
    onCompleted(data) {},
    async onError() {},
  });
}

export function useConvertSayklPoint() {
  return gMutation(convertSayklPointMutation);
}
