import Svg, { Path } from "react-native-svg";

export function SyIconSad(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 57.482 57.481"
      xmlSpace="preserve"
      {...props}
    >
      <Path d="M6.48 15.501c0 4.126 3.357 7.481 7.481 7.481 4.125 0 7.481-3.355 7.481-7.481 0-4.124-3.356-7.48-7.481-7.48-4.123 0-7.481 3.357-7.481 7.48zm6.972-2.667a2.64 2.64 0 1 1-.002 5.282 2.64 2.64 0 0 1 .002-5.282zM34.76 15.501c0 4.126 3.357 7.481 7.481 7.481 4.123 0 7.481-3.355 7.481-7.481 0-4.124-3.358-7.48-7.481-7.48-4.123 0-7.481 3.357-7.481 7.48zm9.526-.026a2.638 2.638 0 1 1-5.279 0 2.64 2.64 0 1 1 5.279 0z" />
      <Path d="M43.44 24.658a8.608 8.608 0 0 1-1.198.084c-4.987 0-9.044-4.057-9.044-9.044 0-1.599.422-3.098 1.153-4.401.283-.501.3-1.264-.023-1.738l-1.215-1.788c-3.298-4.847-8.454-4.726-11.52.272l-.482.786c-.266.433-.162 1.126.175 1.592a8.952 8.952 0 0 1 1.716 5.277c0 4.987-4.055 9.044-9.042 9.044-.471 0-.933-.04-1.384-.111-.568-.091-1.26.175-1.562.666L5.487 34.31l-4.123 6.723c-3.063 4.999-.795 9.051 5.067 9.051h.525c.289 0 .525.039.525.086v.084c.027 1.899 1.8 3.211 3.688 2.99 4.19-.491 11.777-1.232 20.109-1.359 7.943-.12 14.396.622 18.088 1.189 1.879.288 3.559-.932 3.526-2.83l-.004-.142c-.003-.077.458-.199 1.014-.352 3.774-1.029 4.758-4.415 2.017-8.444L45.045 25.317c-.323-.476-1.037-.738-1.605-.659zm.312 24.827c-2.611-.401 1.083-13.122-12.71-13.693-17.252-.718-11.347 13.466-14.31 13.813-1.335.155-2.59-.772-2.608-2.115-.069-4.895.773-15.215 16.063-15.215 11.285 0 15.973 10.31 16.061 15.208.022 1.343-1.166 2.206-2.496 2.002z" />
    </Svg>
  );
}
