import * as Location from "expo-location";
import { useCallback, useEffect, useState } from "react";
import { GOOGLE_API_KEY } from "./constants";

export async function getLocation(reverse = true, lastKnown = false) {
  Location.installWebGeolocationPolyfill();

  let { status, canAskAgain } =
    await Location.requestForegroundPermissionsAsync();

  if (status !== "granted") {
    throw new Error("Konum bilgisine erişim sağlanamadı");
  }

  let location;

  if (lastKnown) {
    location = await Location.getLastKnownPositionAsync({});
  } else {
    location = await Location.getCurrentPositionAsync({
      accuracy: Location.Accuracy.Lowest,
      timeout: 2000,
    });
  }

  const ret = {
    latitude: location.coords.latitude,
    longitude: location.coords.longitude,
  };

  if (reverse) {
    Location.setGoogleApiKey(GOOGLE_API_KEY);
    const reverse = await Location.reverseGeocodeAsync(location.coords);
    const firstResult = reverse[0];
    ret.region = firstResult.region || "";
    ret.subregion = firstResult.subregion || "";
  }

  return { canAskAgain, location: ret };
}

export function formatLocation(location,notFound = "") {
  if (!location || !location.subregion || !location.region) {
    return notFound;
  }
  return `${location.subregion},${location.region}`;
}

export function useLocation(reverse = true) {
  const [location, changeLocation] = useState(null);
  const [error, changeError] = useState(null);
  const [canAskAgain, changeCanAskAgain] = useState(false);

  const effectBody = async () => {
    try {
      const { canAskAgain, location } = await getLocation(reverse);
      changeLocation(location);
      changeCanAskAgain(canAskAgain);
    } catch (err) {
      console.log(err);
      changeLocation(null);
      changeError(err);
    }
  };

  if (error) {
    return {
      loading: false,
      error,
      data: location,
      canAskAgain,
      refetch: effectBody,
    };
  } else if (location) {
    return {
      loading: false,
      error: false,
      data: location,
      canAskAgain,
      refetch: effectBody,
    };
  }

  return { loading: false, error: false, data: null, refetch: effectBody };
}
