import { FlatList } from "react-native";
import SyFavoriteListItem from "./SyFavoriteListItem";

export default function SyFavoriteList({ products }) {
  return (
    <FlatList
      data={products}
      keyExtractor={(item) => item.id}
      renderItem={({ item }) => (
        <SyFavoriteListItem {...item}></SyFavoriteListItem>
      )}
    ></FlatList>
  );
}
