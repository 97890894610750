import { ScrollView, View } from "react-native";
import { make, stylize } from "../lib/style";
import { makeBox, makeColumn, makeRow } from "../themes/components";
import SyIcon from "../widgets/SyIcon";
import SyPage from "../widgets/SyPage";
import SyText from "../widgets/SyText";
import SyTitle from "../widgets/SyTitle";
import SySpace from "../widgets/SySpace";
import SySupportCard from "../widgets/SySupportCard";

const RULES = require("../assets/text/rules.json");

export default function FaqPage() {
  return (
    <SyPage authRequired={false}>
      <ScrollView contentContainerStyle={styles.scrollView}>
        <SySupportCard></SySupportCard>

        <View style={styles.sections}>
          <SyTitle content="Topluluk Kuralları"></SyTitle>

          {RULES.map((rule) => {
            return (
              <View key={rule.id} style={styles.section}>
                <SyText style={styles.title} content={rule.title}></SyText>
                <SyText content={rule.content}></SyText>
                {rule.items && (
                  <View style={styles.items}>
                    {rule.items.map((item, index) => {
                      return (
                        <View key={index} style={styles.item}>
                          <SyIcon name="minus"></SyIcon>
                          <SyText content={item}></SyText>
                        </View>
                      );
                    })}
                  </View>
                )}
              </View>
            );
          })}
        </View>
        <SySpace mode="page"></SySpace>
      </ScrollView>
    </SyPage>
  );
}

const styles = stylize({
  scrollView: make().padding("m").gap("lx"),
  sections: makeColumn("s"),
  section: make(),
  title: make().font("m", "l"),
  items: make().padding("s"),
  item: makeRow("s").itemsBaseline(),
});
