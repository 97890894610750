import { ScrollView, View } from "react-native";
import { useEffect, useState } from "react";

import { make, stylize } from "../lib/style";
import { useProfile, useSaveProfile } from "../queries/profile";
import { makeColumn, makeRow } from "../themes/components";
import { cameraImageVar, errorVar, profileVar } from "../queries/cache";
import { formatPhoneNumber, pickImage } from "../lib/util";

import SyButton from "../widgets/SyButton";
import SyFieldInput from "../widgets/SyFieldInput";
import SyInputText from "../widgets/SyInputText";
import SyPage from "../widgets/SyPage";
import SyUserImage from "../widgets/SyUserImage";

// ---------------------------------
import SyFieldLocation from "../widgets/SyFieldLocation";
// ---------------------------------

import { REGEX_EMAIL, REGEX_OPERATOR, REGEX_PHONE } from "../lib/regex";
import SySpace from "../widgets/SySpace";
import {
  emailFormatError,
  passwordsEmpty,
  passwordsNotMatching,
  phoneFormatError,
} from "../lib/error";
import SyModalInput from "../widgets/SyModalInput";
import { useReactiveVar } from "@apollo/client";

export default function UpdateProfilePage({ route, navigation }) {
  const profile = profileVar();

  const [saveProfile] = useSaveProfile();
  const [newImage, changeNewImage] = useState(profile?.image);
  const [newName, changeNewName] = useState(profile?.name);
  const [newEmail, changeNewEmail] = useState(profile?.email || "");
  const [newPhone, changeNewPhone] = useState(profile?.phone || "");
  const [currentPassword, changeCurrentPassword] = useState("");
  const [newPassword, changeNewPassword] = useState("");
  const [newPasswordRepeat, changeNewPasswordRepeat] = useState("");

  const cameraImage = useReactiveVar(cameraImageVar);

// ---------------------------------
const [newLocation, changeNewLocation] = useState({});
// ---------------------------------

  useEffect(() => {
    if (cameraImage && changeNewImage) {
      changeNewImage(cameraImage);
      cameraImageVar(null);
    }
  }, [cameraImage]);

  useEffect(() => {
    changeNewLocation(profile.location);
  },[]);

  const [passwordModalVisible, changePasswordModalVisible] = useState(false);

  const removeImage = () => {
    changeNewImage(null);
  };

  const takePhoto = () => {
    navigation.navigate("Camera");
  };

  const selectImage = async () => {
    const image = await pickImage();
    if (image) {
      changeNewImage(image);
    }
  };

  const onNewPhoneChanged = (value) => {
    if (value.length === 15) {
      return;
    }
    try {
      const ret = formatPhoneNumber(value);
      changeNewPhone(ret);
    } catch (err) {}
  };

  const saveProfileData = async () => {
    if (currentPassword && (!newPassword || !newPasswordRepeat)) {
      passwordsEmpty();
      return;
    }

    if (newPassword !== newPasswordRepeat) {
      passwordsNotMatching();
      return;
    }

    if (newEmail && !newEmail.match(REGEX_EMAIL)) {
      emailFormatError();
      return;
    }

    if (newPhone.length !== 0 && newPhone.length < 10) {
      phoneFormatError();
      return;
    }

    await saveProfile({
      variables: {
        name: newName.trim(),
        image: newImage,
        phone: newPhone.trim(),
        currentPassword: currentPassword.trim(),
        password: newPassword.trim(),
        passwordRepeat: newPasswordRepeat.trim(),
        email: newEmail.trim(),
        // ---------------------------------
        location: {
          latitude: newLocation?.latitude,
          longitude: newLocation?.longitude,
          region: newLocation?.region,
          subregion: newLocation?.subregion,
        },
        // ---------------------------------
      },
    });

    navigation.navigate("Profile");
  };

  const ACTIONS = [
    {
      label: "Kaydet",
      authRequired: true,
      onPress: saveProfileData,
    },
  ];

  return (
    <SyPage
      authRequired={true}
      data={profile}
      usingData={true}
      actions={ACTIONS}
    >
      <ScrollView>
        <View style={styles.imageContainer}>
          <SyUserImage image={newImage}></SyUserImage>
          <View style={styles.imageButtons}>
            <SyButton
              icon="image"
              onPress={selectImage}
              style={styles.buttonStyle}
              labelStyle={styles.labelStyle}
            ></SyButton>
            <SyButton
              icon="camera"
              onPress={takePhoto}
              style={styles.buttonStyle}
              labelStyle={styles.labelStyle}
            ></SyButton>
            {profile?.image && (
              <SyButton
                icon="delete"
                onPress={removeImage}
                style={styles.buttonStyle}
                labelStyle={styles.labelStyle}
              ></SyButton>
            )}
          </View>
        </View>
        <View style={styles.inputs}>
          <SyFieldInput title="İsim">
            <SyInputText
              placeholder="Ad Soyad"
              value={newName}
              onChangeText={changeNewName}
            ></SyInputText>
          </SyFieldInput>
          <SyFieldInput title="E-posta">
            <SyInputText
              placeholder="E-posta"
              value={newEmail}
              onChangeText={changeNewEmail}
            ></SyInputText>
          </SyFieldInput>
          <SyFieldInput title="Telefon">
            <SyInputText
              placeholder="Telefon"
              type="phone"
              value={newPhone}
              onChangeText={onNewPhoneChanged}
            ></SyInputText>
          </SyFieldInput>
          <SyFieldLocation
            location={newLocation}
            changeLocation={changeNewLocation}
          ></SyFieldLocation>
          {!profile.account && (
            <>
              <SyButton
                label="Şifremi güncelle"
                onPress={() => changePasswordModalVisible(true)}
                style={styles.button}
                labelStyle={styles.buttonLabel}
              ></SyButton>
              <SyModalInput
                visible={passwordModalVisible}
                changeVisible={changePasswordModalVisible}
              >
                <SyFieldInput title="Şifre Güncelleme" style={styles.password}>
                  <SyInputText
                    placeholder="Mevcut Şifre"
                    value={currentPassword}
                    onChangeText={changeCurrentPassword}
                    secureTextEntry={true}
                  ></SyInputText>
                  <SyInputText
                    placeholder="Yeni Şifre"
                    value={newPassword}
                    onChangeText={changeNewPassword}
                    secureTextEntry={true}
                  ></SyInputText>
                  <SyInputText
                    placeholder="Yeni Şifre (Tekrar)"
                    value={newPasswordRepeat}
                    onChangeText={changeNewPasswordRepeat}
                    secureTextEntry={true}
                  ></SyInputText>

                  <SyButton
                    label="Kaydet"
                    onPress={saveProfileData}
                    style={styles.requestButton}
                    labelStyle={styles.requestButtonLabel}
                    disabled={
                      !currentPassword || !newPassword || !newPasswordRepeat
                    }
                  ></SyButton>
                </SyFieldInput>
              </SyModalInput>
            </>
          )}
        </View>
        <SySpace mode="page"></SySpace>
      </ScrollView>
    </SyPage>
  );
}

const styles = stylize({
  imageContainer: makeColumn("m").itemsCenter().padding("m"),
  imageButtons: makeRow("m"),
  inputs: makeColumn("m").padding("m"),
  password: makeColumn("m"),
  button: make()
    .background("light")
    .set((theme) => ({
      marginTop: theme.size.m,
    })),
  buttonLabel: make().font("l", "l", "main"),
  requestButton: make().background("primary"),
  requestButtonLabel: make().font("l", "l", "light"),
  addressInput: makeColumn("s"),
  secondRow: makeRow("s").flex(1).itemsStretch(),
  secondRowButton: make().flex(1),
});

/*<SyButton
              icon="camera"
              onPress={takePhoto}
              style={styles.buttonStyle}
              labelStyle={styles.labelStyle}
            ></SyButton>*/
