import { Platform, View } from "react-native";
import { make, stylize } from "../lib/style";
import { useNavigation } from "@react-navigation/native";

export default function SySpace({ color, extra = false }) {
  const navigation = useNavigation();
  const navState = navigation?.getState();

  if (Platform.OS !== "ios" || navState?.type === "tab") {
    return null;
  }

  const containerStyle = [styles.container];

  if (color === "light") {
    containerStyle.push(make().background("light").style);
  }

  if(extra){
    containerStyle.push(make().margin('m',true,false).style);
  }

  return <View style={containerStyle}></View>;
}

const styles = stylize({
  container: make()
    .background("transparent")
    .set((theme) => ({
      height: theme.size.tabbarBottomGap,
    })),
});
