import * as React from "react";
import { Svg, Path } from "react-native-svg";

export default function SyIconPlane(props) {
  return (
    <Svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M20 12 4 4l2 8m14 0L4 20l2-8m14 0H6"
        stroke={props.stroke}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
