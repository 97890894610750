import {
  createNavigationContainerRef,
  StackActions,
} from "@react-navigation/native";

export const navigationRef = createNavigationContainerRef();

export function navigate(name, params) {
  if (navigationRef.isReady()) {
    navigationRef.navigate(name, params);
  }
}

export function goBack() {
  if (navigationRef.isReady()) {
    navigationRef.navigate("Main", { screen: "Search" });
  }
}

export function replace(name, params) {
  if (navigationRef.isReady()) {
    navigationRef.current.dispatch(StackActions.replace(name, params));
  }
}

export function navigateWithParams(name) {
  const route = navigationRef.getCurrentRoute();
  navigate(name, route.params);
}
