import { Platform, useWindowDimensions } from "react-native";
import { make } from "./style";
import { useNavigation } from "@react-navigation/native";

const CONTENT = make().theme.size.contentWidth;
const LEFT_PANE = make().theme.size.leftPane;
const RIGHT_PANE = make().theme.size.rightPane;

export function useScreenState() {
  const navigation = useNavigation();
  const { width, height } = useWindowDimensions();
  const ratio = width / height;
  const screenState = {
    screenWidth: width,
    screenHeight: height,
    iconsOnly: false,
    ads: true,
    portrait: ratio > 1 ? false : true,
    landscape: ratio > 1 ? true : false,
  };

  if (navigation.getState()?.type === "tab") {
    screenState.isTab = true;
  } else {
    screenState.isTab = false;
  }

  if (Platform.isPad) {
    screenState.state = "tablet";
    screenState.contentWidth = width;
    screenState.style = { width: width };

  } else if (Platform.OS === "web") {

    screenState.contentWidth = CONTENT;
    screenState.style = { width: CONTENT };

    const maxRequiredWidth = CONTENT + LEFT_PANE + RIGHT_PANE;

    if (width >= maxRequiredWidth) {
      screenState.state = "large";
    } else {
      screenState.state = "medium";
      screenState.contentWidth = CONTENT;
      screenState.style = { width: CONTENT };

      if (width > CONTENT + RIGHT_PANE) {
        screenState.iconsOnly = true;

        if (screenState.portrait) {
          screenState.state = "tablet";
          screenState.contentWidth = width;
          screenState.style = { width: width };
        }
      } else {
        screenState.contentWidth = width;
        screenState.style = { width: width };

        if (screenState.portrait && width > 500) {
          screenState.state = "tablet";
        }else{
          screenState.state = "small";
        }
      }
    }
  } else {
    screenState.state = "small";
    screenState.contentWidth = width;
    screenState.style = { width: width };
  }

  return screenState;
}
