import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { make } from "../lib/style";

import IncomingRequestsPage from "../pages/IncomingRequestsPage";
import OutgoingRequestsPage from "../pages/OutgoingRequestsPage";
import { useReactiveVar } from "@apollo/client";
import { notificationsVar } from "../queries/cache";
import SyBadge from "../widgets/SyBadge";

const Tab = createMaterialTopTabNavigator();

export default function RequestsLayout() {
  const notifications = useReactiveVar(notificationsVar);

  const options = {
    incoming: {
      title: "Gelen Talepler",
      tabBarBadge: notifications.SenderRequest ? SyBadge : null,
    },
    outgoing: {
      title: "Giden Talepler",
      tabBarBadge: notifications.ReceiverRequest ? SyBadge : null,
    },
  };

  return (
    <Tab.Navigator screenOptions={screenOptions} id="Requests">
      <Tab.Screen
        name="IncomingRequests"
        component={IncomingRequestsPage}
        options={options.incoming}
      ></Tab.Screen>
      <Tab.Screen
        name="OutgoingRequests"
        component={OutgoingRequestsPage}
        options={options.outgoing}
      ></Tab.Screen>
    </Tab.Navigator>
  );
}
const colors = make().theme.colors;
const screenOptions = {
  tabBarShowIcon: false,
  tabBarIndicatorStyle: make().background("primary").style,
  tabBarLabelStyle: make().capitalize().color('main').style,
  lazy: true,
  unmountOnBlur: true,
  tabBarStyle: {
    backgroundColor: colors.headerColor,
    borderBottomColor: colors.dark,
  },
};
