import { Pressable, View } from "react-native";
import { make, stylize } from "../lib/style";
import SyIcon from "./SyIcon";

export default function SyUserRating({ size = "l", rating = 0, changeRating }) {
  const sizeStyle = make().font(size, "m", "primary").style;

  const stars = [];
  let counter = rating;

  for (let i = 0; i < 5; i++) {
    if (counter > 0 && counter < 1) {
      stars.push(
        <SyIcon name="star-half-alt" style={[styles.star, sizeStyle]} key={i} />
      );
    } else if (counter >= 1) {
      stars.push(
        <Pressable
          disabled={!changeRating}
          onPress={() => changeRating(i + 1)}
          key={i}
          style={styles.pressable}
        >
          <SyIcon name="star" solid style={[styles.star, sizeStyle]} />
        </Pressable>
      );
    } else {
      stars.push(
        <Pressable
          disabled={!changeRating}
          onPress={() => changeRating(i + 1)}
          key={i}
          style={styles.pressable}
        >
          <SyIcon name="star" style={[styles.star, sizeStyle]} />
        </Pressable>
      );
    }
    counter--;
  }

  return <View style={styles.rating}>{stars.map((s) => s)}</View>;
}

const styles = stylize({
  pressable: make().set({
    outlineStyle: "none",
  }),
  rating: make().row().itemsCenter(),
  star: make().color("primary"),
  value: make()
    .font("x")
    .color("primary")
    .padding("s")
    .margin("m", false, true)
    .border("u")
    .radius("l"),
});
