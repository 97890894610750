import { gMutation, gQuery } from "../lib/graphql";
import { navigate, replace } from "../lib/navigation";
import {
  fetchProductBasicQuery,
  fetchProductQuery,
  fetchProfileFavoritesQuery,
  fetchProfileProductsQuery,
  removeProductMutation,
  saveProductMutation,
  toggleFavoriteMutation,
} from "./gql";
import { searchProducts } from "./operations/product";

export function useProduct(id) {
  return gQuery(fetchProductQuery, {
    variables: { id },
    //fetchPolicy: "network-only",
    //nextFetchPolicy: "network-only",
  });
}

export function useProductBasic(id) {
  return gQuery(fetchProductBasicQuery, { variables: { id } });
}

export function useProducts(
  search,
  category,
  condition,
  distance,
  shipment,
  page
) {
  return gQuery(
    searchProducts,
    {
      variables: {
        search,
        category,
        condition,
        distance,
        shipment,
        page,
      },
    },
    false
  );
}

export function useProductSave() {
  return gMutation(saveProductMutation, {
    refetchQueries: () => [{ query: fetchProfileProductsQuery }],
    onCompleted: function ({ saveProduct }) {
      replace("Main", { screen: "Products" });
    },
    onError: function () {},
  });
}

export function useProductRemove() {
  return gMutation(removeProductMutation, {
    refetchQueries: () => [{ query: fetchProfileProductsQuery }],
    onCompleted: () => {
      navigate("Dashboard");
    },
  });
}

export function useToggleFavorite() {
  return gMutation(toggleFavoriteMutation, {
    refetchQueries: () => [{ query: fetchProfileFavoritesQuery }],
    onCompleted() {},
    onError(err) {},
  });
}
