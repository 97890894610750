import { errorVar } from "../queries/cache";

function showError(message) {
  errorVar({
    message,
  });
}

function showSuccess(message) {
  errorVar({
    message,
    type: "success",
  });
}

export function loginEmpty() {
  showError("E-posta ve şifre gerekli");
}

export function fillEmpty() {
  showError("Bütün alanları doldurun");
}

export function acceptTerms() {
  showError("Gizlilik sözleşmesi onayı gerekli");
}

export function locationRequired() {
  showError("Konum bilgisi gerekli");
}

export function passwordsNotMatching() {
  showError("Şifreler aynı değil");
}

export function passwordsEmpty() {
  showError("Yeni şifre girilmedi");
}

export function emailFormatError() {
  showError("E-posta formatı hatalı");
}

export function phoneFormatError() {
  showError("Telefon numarası formatı hatalı");
}

export function linkCopySucess() {
  showSuccess("Kopyalandı");
}

export function emailSent() {
  showSuccess("Eposta gönderildi");
}

export function invalidEmail() {
  showError("Geçersiz Eposta adresi");
}

export function ratingIsRequired() {
  showError("Değerlendirme gereklidir");
}

export function productReserved() {
  showError("Aktif talep mevcut.");
}