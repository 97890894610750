import { useNavigation, useRoute } from "@react-navigation/native";
import * as ImagePicker from "expo-image-picker";
import rnuuid from "react-native-uuid";
import { manipulateAsync, SaveFormat } from "expo-image-manipulator";

import { loggedInVar, pageVar } from "../queries/cache";
import { replace as REPLACE } from "./navigation";
import { sendAnalyticsEvent } from "./analytics";
import * as Linking from "expo-linking";

export function getAuthPress(
  onPress,
  authRequired,
  redirect,
  redirectParent,
  redirectParams,
  navigate,
  replace
) {
  const navigation = useNavigation();
  const route = useRoute();
  const loggedIn = loggedInVar();

  let onpress = onPress;

  if (authRequired) {
    if (loggedIn) {
      if (onpress) {
        return () => {
          onpress();
          sendAnalyticsEvent(redirect);
        }
      } else if (redirect) {
        if (redirectParent) {
          onpress = () => {
            navigation.navigate(redirectParent, {
              screen: redirect,
              params: redirectParams || route.params,
            });
            sendAnalyticsEvent(redirect);
          };
        } else {
          onpress = () => {
            navigation.navigate(redirect, redirectParams || route.params);
            sendAnalyticsEvent(redirect);
          };
        }
      }
    } else {
      onpress = () => {
        const r = redirect || route.name;
        const rp = redirectParent || navigation.getId();

        pageVar({
          name: r,
          parent: rp,
          params: redirectParams || route.params,
        });
        navigation.push("Login");
        sendAnalyticsEvent("Login");
      };
    }
  } else if (navigate) {
    if (navigate === "Login" || navigate === "Signup") {
      const page = pageVar();

      if (!page.name) {
        const r = route.name;
        const rp = navigation.getId();

        pageVar({
          name: r,
          parent: rp,
          params: route.params,
        });
      }
    }

    if (replace) {
      onpress = () => {
        REPLACE(navigate);
        sendAnalyticsEvent(navigate);
      };
    } else {
      onpress = () => {
        navigation.push(navigate);
        sendAnalyticsEvent(navigate);
      };
    }
  }

  return onpress;
}

export async function pickImage() {
  try {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      base64: true,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
    });

    try {
      if (!result.canceled) {
        const image = await manipulateAsync(
          result.assets[0].uri,
          [
            {
              resize: {
                width: 480,
              },
            },
          ],
          { compress: 1, format: SaveFormat.JPEG, base64: true }
        );

        return "data:image/jpg;base64," + image.base64;
      }
    } catch (err) {
      console.log("maipualte error");
    }
  } catch (err) {
    console.log("picker error");
  }
}

export function uuid() {
  return rnuuid.v4();
}

export function capitalize(str, lower = false) {
  if (!str) {
    return "";
  }

  return (lower ? str.toLowerCase() : str).replace(
    /(?:^|\s|["'([{])+\S/g,
    (match) => match.toLocaleUpperCase()
  );
}

export function formatPhoneNumber(value) {
  let formattedNumber;
  const { length } = value;

  const regex = () => value.replace(/[^0-9\.]+/g, "");
  const ac = regex().slice(0, 3);

  if (ac.charAt(0) !== "5") {
    throw new Error("Geçersiz numara");
  }

  const areaCode = () => `(${ac})`;
  const firstSix = () => `${areaCode()} ${regex().slice(3, 6)}`;
  const trailer = (start) => `${regex().slice(start, regex().length)}`;

  if (length < 3) {
    formattedNumber = regex();
  } else if (length === 4) {
    formattedNumber = `${areaCode()} ${trailer(3)}`;
  } else if (length === 5) {
    formattedNumber = `${areaCode().replace(")", "")}`;
  } else if (length > 5 && length < 9) {
    formattedNumber = `${areaCode()} ${trailer(3)}`;
  } else if (length >= 10) {
    formattedNumber = `${firstSix()} ${trailer(6)}`;
  }
  return formattedNumber || value;
}
