import { make, stylize } from "../lib/style";
import SyButton from "./SyButton";

export default function SyButtonSelection({
  item,
  id = "id",
  defaultState,
  changeVisible,
  noSelectionError = false,
  style,
}) {

  const isActive = item && (item[id] !== defaultState[id]);
  const isError = item && noSelectionError && item[id] === defaultState[id];

  const labelStyle =
    isError || isActive ? styles.activeButtonLabel : styles.inactiveButtonLabel;

  let buttonStyle;

  if (isActive) {
    buttonStyle = styles.activeButton;
  } else if (isError) {
    buttonStyle = styles.errorButton;
  } else {
    buttonStyle = styles.inactiveButton;
  }

  return (
    <SyButton
      label={isActive ? item.name : defaultState.name}
      style={[styles.button, style, buttonStyle]}
      labelStyle={labelStyle}
      onPress={() => {
        changeVisible(true);
      }}
    ></SyButton>
  );
}

const styles = stylize({
  button: make().flex(1).background("light").center().itemsCenter(),

  inactiveButton: make().background("primary"),
  inactiveButtonLabel: make().font('sm','l','black'),

  activeButton: make().background("light"),
  activeButtonLabel: make(),

  errorButton: make().background("secondary"),
});
