import React, { useCallback } from "react";
import { KeyboardAvoidingView, Platform, View } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { ApolloProvider } from "@apollo/client";
import { useFonts } from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import { enableScreens } from "react-native-screens";
import "expo-dev-client";
import { StatusBar } from 'expo-status-bar';
import * as NavigationBar from 'expo-navigation-bar';

import { make, stylize } from "./lib/style";
import { graphqlClient } from "./lib/graphql";
import { navigationRef } from "./lib/navigation";

import AppLayout from "./layouts/AppLayout";
import { initializeNotifications } from "./lib/notification";
import { linking } from "./lib/routes";

const theme = make().theme;
const background = make().background('tabBarColor');

enableScreens();
SplashScreen.preventAutoHideAsync();
initializeNotifications();
NavigationBar.setBackgroundColorAsync(background.style.backgroundColor)
NavigationBar.setButtonStyleAsync(theme)

export default function App() {
  
  const fontsLoaded = useSyFonts();
  const onLayoutRootView = useCallback(async () => {
    if (fontsLoaded) {
      await SplashScreen.hideAsync();
    }
  }, [fontsLoaded]);

  if (!fontsLoaded) {
    return null;
  }

  const NAV_THEME = {
    colors: {
      background: theme.colors.primaryBackground,
    },
  };


  return (
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <View style={styles.view} onLayout={onLayoutRootView}>
        <StatusBar style="auto"/>
        <ApolloProvider client={graphqlClient}>
          <NavigationContainer
            ref={navigationRef}
            linking={linking}
            theme={NAV_THEME}
          >
            <AppLayout></AppLayout>
          </NavigationContainer>
        </ApolloProvider>
      </View>
    </KeyboardAvoidingView>
  );
}

function useSyFonts() {
  const [fontsLoaded] = useFonts({
    "Poppins-Regular": require("./assets/fonts/poppins/Poppins-Regular.ttf"),
    "Poppins-Bold": require("./assets/fonts/poppins/Poppins-Bold.ttf"),
  });
  return fontsLoaded;
}

const styles = stylize({
  view: make().background("primaryBackground").flex(1),
});
