import { useEffect } from "react";
import { useRequestStateChanged } from "../queries/request";

export default function SyRequestListener({ id, changeRequest }) {
  const { loading, error, data } = useRequestStateChanged(id);
  
  useEffect(() => {
    changeRequest(data);
  }, [data]);

  return;
}
