import { View } from "react-native";
import SyIconSpark from "../icons/SyIconSpark";
import { make, stylize } from "../lib/style";
import { makeColumn } from "../themes/components";
import SyButton from "../widgets/SyButton";
import SyModal from "../widgets/SyModal";
import SyText from "../widgets/SyText";
import SyTitle from "../widgets/SyTitle";
import { useConfirmEmail } from "../queries/profile";
import { useEffect } from "react";
import SyPageModal from "../widgets/SyPageModal";

export default function ConfirmationDetailPage({ route, navigation }) {
  const invitation = route.params.id;
  const [confirmEmail, { data, loading }] = useConfirmEmail();

  useEffect(() => {
    confirmEmail({ variables: { code: invitation } });
  }, []);

  return (
    <SyPageModal>
      <View style={styles.modalContent}>
        <SyIconSpark width={128} height={128}></SyIconSpark>
        <SyTitle
          style={styles.successMessage}
          content="E-posta doğrulandı!"
        ></SyTitle>
        {invitation && (
          <View style={styles.successGroup}>
            <SyText content="Arkadaşının hesabına 1 saykl puan aktarıldı."></SyText>
          </View>
        )}
      </View>
      <SyButton
        style={styles.successButton}
        labelStyle={styles.successButtonLabel}
        onPress={() => navigation.navigate("Main", { screen: "Search" })}
        label="İlanlara git"
      ></SyButton>
    </SyPageModal>
  );
}

const styles = stylize({
  modalContent: makeColumn().padding("m").itemsCenter().gap("s"),
  successImage: make().fullWidth().ratio(1),
  successMessage: make().textCenter(),
  successButton: make().background("primary"),
  successButtonLabel: make().font("m", "l", "light"),
  successGroup: make().itemsCenter().gap("n"),
});
