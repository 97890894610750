import { View } from "react-native";
import SyPage from "./SyPage";
import { makeColumn } from "../themes/components";
import { make, stylize } from "../lib/style";
import { goBack } from "../lib/navigation";

const ACTIONS = [
  {
    label: "İptal",
    negative: true,
    onPress() {
      goBack();
    },
  },
];

export default function SyPageModal({
  children,
  loading,
  authRequired,
  showExit = true,
  actions,
}) {
  if (actions && showExit) {
    actions.push(...ACTIONS);
  }

  return (
    <SyPage
      style={styles.page}
      authRequired={authRequired}
      loading={loading}
      contentStyle={styles.content}
      actions={actions}
    >
      <View style={styles.container}>{children}</View>
    </SyPage>
  );
}

const styles = stylize({
  page: make().fullHeight(),
  content: make().center().itemsCenter(),
  container: makeColumn("m").width("smallModal"),
});
