import { gql } from "@apollo/client";

export const productBasicInfo = gql`
  fragment basicInfo on Product {
    id
    name
    sayklPoints
    location {
      region
      subregion
    }
    owner {
      id
    }
  }
`;

export const productDetailedInfo = gql`
  ${productBasicInfo}

  fragment detailedInfo on Product {
    ...basicInfo

    category {
      id
      name
    }

    owner {
      id
      name
      thumbnail
    }

    shipmentType
    locationType
    condition

    createdAt
    updatedAt

    createdDate @client
    createdDateTime @client
    createdTime @client

    updatedDate @client
    updatedDateTime @client
    updatedTime @client
  }
`;

export const productItem = gql`
  fragment item on Product {
    thumbnail
    favoritedByUsers
    isFavorite
  }
`;

export const searchProducts = gql`
  ${productBasicInfo}
  ${productItem}

  query SearchProducts(
    $search: String
    $category: String
    $condition: String
    $distance: Float
    $shipment: String
    $page: Int
  ) {
    products(
      search: $search
      category: $category
      condition: $condition
      distance: $distance
      shipment: $shipment
      page: $page
    ) {
      ...basicInfo
      ...item
    }
  }
`;
