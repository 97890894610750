import * as Google from "expo-auth-session/providers/google";
import { useEffect } from "react";
import { make, stylize } from "../lib/style";
import SyButton from "./SyButton";
import { useSocialLogin } from "../queries/profile";

export default function SyButtonGoogle() {
  
  const [socialLogin, socialLoginResponse] = useSocialLogin();
  const [request, googleResponse, promptGoogleLogin] = Google.useAuthRequest({
    webClientId:
      "867512090818-738itjou0bcden6jt240itrqv4av04os.apps.googleusercontent.com",
    androidClientId:
      "867512090818-2d6ekhjegsqdrmedbne8u32mdkt23rt2.apps.googleusercontent.com",
    iosClientId:
      "867512090818-loa86p72atso5jf4em2lgf65dl965uie.apps.googleusercontent.com",
  });

  useEffect(() => {
    if (googleResponse?.type === "success") {
      const token = googleResponse.authentication.accessToken;
      fetch("https://www.googleapis.com/userinfo/v2/me", {
        headers: { Authorization: `Bearer ${token}` },
      }).then(async (response) => {
        const user = await response.json();

        await socialLogin({
          variables: {
            account: "Google",
            token,
            email: user.email,
            accountId: user.id,
            name: user.given_name + " " + user.family_name,
            image: user.picture,
          },
        });
      });
    }
  }, [googleResponse]);

  const googleLogin = async () => {
    await promptGoogleLogin();
  };

  return (
    <SyButton
      icon="google"
      label="Google ile Giriş Yap"
      onPress={googleLogin}
      style={styles.googleButton}
      labelStyle={styles.googleButtonLabel}
      iconStyle={styles.googleButtonIcon}
    ></SyButton>
  );
}

const styles = stylize({
  googleButton: make()
    .background("light")
    .shadow(1)
    .border('u')
    .borderColor('lightGrey3')
    .set({ width: "100%", height: 40 }),
  googleButtonLabel: make().font("m", "l", "main"),
  googleButtonIcon: make().font("m", "m", "main"),
});
