import { Pressable, View } from "react-native";
import { make, stylize } from "../lib/style";
import SyText from "./SyText";

export default function SySelection({ items, id = "id", changeItem }) {
  return (
    items &&
    items.map((item, index) => {
      const labelStyle = item.disabled
        ? styles.disabledLabelStyle
        : styles.itemLabel;

      const onpress = item.disabled ? null : () => changeItem(item);

      return (
        <View key={item[id]}>
          <Pressable
            style={styles.item}
            onPress={onpress}
          >
            <SyText style={labelStyle} content={item.name}></SyText>
          </Pressable>
          {index < items.length - 1 && <View style={styles.separator}></View>}
        </View>
      );
    })
  );
}

const styles = stylize({
  item: make()
    .shadow(0)
    .background("transparent")
    .itemsCenter()
    .padding("s", true, false),
  itemLabel: make().color("main"),
  disabledLabelStyle: make().color("lightGrey2"),
  separator: make()
    .height("u")
    .background("primary")
    .set({
      width: "65%",
    })
    .selfCenter(),
});
