import { make, stylize } from "../lib/style";
import { useProfileFavorites } from "../queries/profile";
import SyFavoriteList from "../widgets/SyFavoriteList";
import SyNA from "../widgets/SyNA";
import SyPage from "../widgets/SyPage";

export default function FavoritesPage() {
  const { loading, data, refetch } = useProfileFavorites();

  const favorites = data?.profile?.favorites;

  if (!favorites || favorites.length === 0) {
    return <SyNA loading={loading}></SyNA>;
  }

  return (
    <SyPage loading={loading} refetch={refetch} data={data}>
      {favorites && (
        <SyFavoriteList
          style={styles.list}
          products={data?.profile?.favorites}
        ></SyFavoriteList>
      )}
    </SyPage>
  );
}

const styles = stylize({
  list: make().flex(1),
  imageContainer: make().flex(1).itemsCenter().center(),
  modal: make().width("modal").itemsCenter(), //.background('light').radius('l').shadow(1).padding('l'),
  image: make().width("modal").ratio(1),
  text: make().font("l", "m", "main"),
  separator: make()
    .border("u")
    .fullWidth()
    .borderColor("lightGrey2")
    .margin("s", true),
});
