import SyIcon from "../widgets/SyIcon";

import { make, stylize } from "../lib/style";

export default function SyBadge() {
  return <SyIcon name="circle" style={styles.icon}></SyIcon>;
}

const styles = stylize({
  icon: make().font("m", "m", "primary"),
});
