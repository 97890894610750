import { Linking, Platform, View } from "react-native";
import { make, stylize } from "../lib/style";
import { uuid } from "../lib/util";
import { useProductBasic } from "../queries/product";
import { useCreateRequest } from "../queries/request";
import { makeBox } from "../themes/components";
import SyButton from "../widgets/SyButton";

import SyPage from "../widgets/SyPage";
import SyText from "../widgets/SyText";
import SyTitle from "../widgets/SyTitle";
import SyInputText from "../widgets/SyInputText";
import { useState } from "react";
import { SyIconSad } from "../icons/SyIconSad";

export default function NewShippingPage({ navigation, route }) {
  const { loading, error, data } = useProductBasic(route.params.id);
  const [createRequest] = useCreateRequest();
  const [firstName, changeFirstName] = useState("");
  const [lastName, changeLastName] = useState("");
  const [phoneNumber, changePhoneNumber] = useState("");
  const [identificationNumber, changeIdentificationNumber] = useState("");
  const [address, changeAddress] = useState("");

  const product = data?.product;
  const shipmentType = product?.shipmentType;
  const availability = product?.availability;

  const makeRequest = (shipmentType) => {
    createRequest({
      variables: {
        id: uuid(),
        productId: route.params.id,
        shipmentType: shipmentType.id,
      },
    });
  };

  const gotoPurchase = () => {
    /*
    Linking.openURL("https://saykl.com/puan-al");
    return;
    */

    if (Platform.OS === "web") {
      navigation.replace("Purchase");
    } else {
      Linking.openURL("https://saykl.com/puan-al");
    }
  };

  let modalContent = null;

  switch (availability) {
    case "NoShippingPoints": // done
      modalContent = (
        <View style={styles.modal}>
          <View style={styles.modalContent}>
            <SyIconSad width={128} height={128} fill={colors.blank}></SyIconSad>
            <SyTitle content="Üzgünüz"></SyTitle>
            <View style={styles.centered}>
              <SyText content="Kullanılabilir kargo hakkın yok."></SyText>
              <SyText content="89.90 TL'ye kargo hakkı almak ister misin?"></SyText>
            </View>
            <View style={styles.reminderText}>
              <SyText content="(Satın alma işlemi için"></SyText>
              <SyText content="web sitemize yönlendirileceksin.)"></SyText>
            </View>
          </View>
          <SyButton
            style={styles.successButton}
            labelStyle={styles.successButtonLabel}
            label="İsterim"
            redirect="Purchase"
            redirectParent="Main"
            onPress={gotoPurchase}
          ></SyButton>
        </View>
      );
      break;
    case "Available":
    default:
      modalContent = (
        <View style={styles.modal}>
          <View style={styles.reminderTop}>
            <SyText content="Anlaşmalı kargo için kargo hakkı satın almalısın."></SyText>
            <SyText content="(Taleple birlikte 1 kargo hakkı rezerve edilecektir."></SyText>
            <SyText content="İptal/red durumunda hesabına geri aktarılır.)"></SyText>
          </View>
          <View style={styles.content}>
            <SyText content="Anlaşmalı kargo seçeneğinde"></SyText>
            <SyText content="MNG veya Aras Kargo ile"></SyText>
            <SyText content="10 desiye kadar (38 x 29 x 29 cm)"></SyText>
            <SyText content="gönderim yapabilirsin. "></SyText>
          </View>
          <SyInputText
            placeholder="Ad"
            value={firstName}
            onChangeText={changeFirstName}
            style={styles.input}
          ></SyInputText>
          <SyInputText
            placeholder="Soyad"
            value={lastName}
            onChangeText={changeLastName}
            style={styles.input}
          ></SyInputText>
          <SyInputText
            placeholder="Telefon"
            value={phoneNumber}
            onChangeText={changePhoneNumber}
            style={styles.input}
          ></SyInputText>
          <SyInputText
            placeholder="Adres"
            value={address}
            onChangeText={changeAddress}
            style={styles.address}
            multiline={true}
            lines={3}
          ></SyInputText>
        </View>
      );
  }

  return (
    <SyPage loading={loading} error={error} showExit={true}>
      <View style={styles.page}>{modalContent}</View>
    </SyPage>
  );
}

const colors = make().theme.colors;

const styles = stylize({
  page: make().flex(1).row().center().itemsCenter(),
  modal: makeBox(),
  modalContent: make().itemsCenter().padding("m").gap("s"),
  content: make().itemsCenter(),
  centered: make().itemsCenter(),
  successButton: make().background("primary"),
  successButtonLabel: make().font("m", "l", "light"),
  reminder: make()
    .itemsCenter()
    .set((theme) => ({ marginTop: theme.size.xl })),
  reminderTop: make()
    .itemsCenter()
    .set((theme) => ({ marginBottom: theme.size.xl })),
  header: make()
    .itemsCenter()
    .set((theme) => ({ marginBottom: theme.size.xl })),
  reminderText: make().font("smallText", "m", "lightGrey3").textCenter(),
});
