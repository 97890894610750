import { useState } from "react";
import { View } from "react-native";
import * as WebBrowser from "expo-web-browser";

import { fillEmpty } from "../lib/error";
import { make, stylize } from "../lib/style";
import { useSignup } from "../queries/profile";
import { makeColumn, makeRow } from "../themes/components";

import SyButton from "../widgets/SyButton";
import SyInputText from "../widgets/SyInputText";
import SyText from "../widgets/SyText";
import SyButtonGoogle from "../widgets/SyButtonGoogle";
import SyButtonApple from "../widgets/SyButtonApple";
import SyTitle from "../widgets/SyTitle";
import SyPageModal from "../widgets/SyPageModal";
import SyBrandSlogan from "../widgets/SyBrandSlogan";

WebBrowser.maybeCompleteAuthSession();

export default function InvitationDetailPage({ route, navigation }) {
  const invitedById = route.params.id;
  const [name, changeName] = useState("");
  const [email, changeEmail] = useState("");
  const [password, changePassword] = useState("");
  const [signup, response] = useSignup();
  const [signupSuccess, changeSignupSuccess] = useState(false);

  const onPress = async () => {
    if (!email || !password || !name) {
      fillEmpty();
      return;
    }

    await signup({
      variables: {
        name: name,
        email: email,
        password: password,
        invitedById: invitedById,
      },
    });

    changeSignupSuccess(true);
  };

  return (
    <>
      {!signupSuccess && (
        <SyPageModal
          loading={response.loading}
          authRequired={false}
          showExit={false}
        >
          <View style={styles.container}>
            <SyBrandSlogan></SyBrandSlogan>
            <SyInputText
              placeholder="Ad Soyad"
              value={name}
              onChangeText={changeName}
              autoComplete="off"
            ></SyInputText>
            <SyInputText
              placeholder="E-posta"
              value={email}
              keyboardType="email"
              onChangeText={changeEmail}
              autoComplete="off"
            ></SyInputText>
            <SyInputText
              placeholder="Şifre"
              value={password}
              secureTextEntry={true}
              onChangeText={changePassword}
            ></SyInputText>

            <View>
              <View style={styles.terms}>
                <SyButton
                  style={styles.linkButton}
                  labelStyle={styles.linkButtonLabel}
                  label="Gizlilik Politikası"
                  lines={1}
                  navigate="PrivacyStatement"
                ></SyButton>
                <SyText style={styles.text} content="ve" lines={1}></SyText>

                <SyButton
                  style={styles.linkButton}
                  labelStyle={styles.linkButtonLabel}
                  label="Kullanıcı Sözleşmesi"
                  navigate="UsersAgreement"
                  lines={1}
                ></SyButton>
              </View>
              <SyText
                style={styles.text}
                content="kaydolduğunda okunup onaylanmış sayılır."
              ></SyText>
            </View>
            <View style={styles.actions}>
              <SyButton
                label="Hesap Oluştur"
                onPress={onPress}
                style={styles.loginButton}
                labelStyle={styles.loginButtonLabel}
              ></SyButton>
            </View>
            <View style={styles.actions}>
              <SyButtonGoogle></SyButtonGoogle>
              <SyButtonApple></SyButtonApple>
            </View>
          </View>
        </SyPageModal>
      )}
      {signupSuccess && (
        <SyPageModal authRequired={false} showExit={false}>
          <View style={styles.modalContent}>
            <SyBrandSlogan></SyBrandSlogan>
            <SyTitle
              style={styles.successMessage}
              content="Aramıza hoşgeldin!"
            ></SyTitle>
            <View style={styles.successGroup}>
              <SyText content="Arkadaşının hesabına 1 saykl puan aktarılması için gönderdiğimiz"></SyText>
              <SyText content="e-postayı onayla."></SyText>
            </View>
          </View>
          <SyButton
            style={styles.successButton}
            labelStyle={styles.successButtonLabel}
            onPress={() => navigation.navigate("Main", { screen: "Search" })}
            label="Dönüştürmeye Başla"
          ></SyButton>
        </SyPageModal>
      )}
    </>
  );
}

const styles = stylize({
  container: make().column().gap("s"),
  logo: make()
    .selfCenter()
    .ratio(2.2)
    .width("authIcon")
    .set({
      resizeMode: "contain",
    })
    .margin("l", true, false),
  loginButton: make().flex(1).background("primary"),
  loginButtonLabel: make().font("m", "l", "light"),
  signupButton: make().flex(1).background("light"),
  signupButtonLabel: make().font("m", "l", "main"),
  actions: makeRow("m").spaceBetween(),
  terms: makeRow("s").itemsCenter().center(),
  text: make().color("lightGrey3").textCenter(),
  link: make().color("primary"),
  termsSwitch: makeRow("s"),
  circle: make().color("lightGrey2"),
  modalContent: makeColumn().padding("m").itemsCenter().gap("s"),
  successImage: make().fullWidth().ratio(1),
  successMessage: make().textCenter(),
  successButton: make().background("primary"),
  successButtonLabel: make().font("m", "l", "light"),
  successGroup: make().itemsCenter().gap("n").textCenter(),
});
