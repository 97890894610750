import { make, stylize } from "../lib/style";

import { useProfileProducts } from "../queries/profile";

import SyProductList from "../widgets/SyProductList";
import SyPage from "../widgets/SyPage";

const ACTIONS = [
  {
    label: "Yeni ilan ver",
    authRequired: true,
    redirect: "NewProduct",
  },
];

export default function ProductsPage() {
  const { loading, error, data } = useProfileProducts();

  const products = data?.profile?.products;

  return (
    <SyPage
      error={error}
      loading={loading}
      actions={ACTIONS}
      authRequired={true}
      data={products}
      usingData={true}
      pullToRefresh={false}
    >
      {products && (
        <SyProductList style={styles.list} products={products}></SyProductList>
      )}
    </SyPage>
  );
}

const styles = stylize({
  list: make().flex(1),
});
