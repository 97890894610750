import * as AppleAuthentication from "expo-apple-authentication";
import { make, stylize } from "../lib/style";
import { useEffect, useState } from "react";
import { useSocialLogin } from "../queries/profile";

export default function SyButtonApple() {
  const [isAvailable, changeIsAvaiable] = useState(false);
  const [socialLogin, socialLoginResponse] = useSocialLogin(true);

  useEffect(() => {
    AppleAuthentication.isAvailableAsync().then((available) => {
      changeIsAvaiable(available);
    });
  }, []);

  if (!isAvailable) {
    return null;
  }

  const appleLogin = async () => {

    try {
      const credential = await AppleAuthentication.signInAsync({
        requestedScopes: [
          AppleAuthentication.AppleAuthenticationScope.EMAIL,
          AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
        ],
      });

      await socialLogin({
        variables: {
          account: "Apple",
          token: credential.authorizationCode,
          accountId: credential.user,
        },
      });
    } catch (e) {
      if (e.code === "ERR_REQUEST_CANCELED") {
      } else {
      }
    }
  };

  return (
    <AppleAuthentication.AppleAuthenticationButton
      buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
      buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
      cornerRadius={8}
      onPress={appleLogin}
      style={{ width: "100%", height: 40 }}
    />
  );
}

const styles = stylize({
  appleButton: make().fullWidth().flex(1),
});

/*
  970x250 - masthead
  970x250 - altbanner
  970x90  - 
          - pageskin

*/
