import { Image, Pressable, View } from "react-native";
import SyText from "./SyText";
import { Link } from "@react-navigation/native";
import { make, stylize } from "../lib/style";
import { makeBox, makeRow } from "../themes/components";
import { SyBadge } from "./SyStoreBadges";

const GOOGLE_BADGE = require("../assets/images/google-play-badge.png");
const APPLE_BADGE = require("../assets/images/app-store-badge.svg");

export default function SyStoreCard() {
  return (
    <View style={styles.help}>
      <SyText
        style={styles.contactTitle}
        content="Üye olmak da ilan vermek de ücretsiz!"
      ></SyText>
      <View style={styles.badges}>
        <SyBadge
          image={GOOGLE_BADGE}
          imageStyle={styles.google}
          to="https://play.google.com/store/apps/details?id=com.saykl.mobile"
        ></SyBadge>
        <SyBadge image={APPLE_BADGE} imageStyle={styles.apple} to="https://apps.apple.com/us/app/saykl/id6447631282"></SyBadge>
      </View>
    </View>
  );
}

const styles = stylize({
  help: makeBox().gap("s").margin("n").flex(1).center(),
  contactTitle: make().font("l", "l", "main"),
  badges: makeRow("s").itemsCenter(),
  google: make()
    .ratio(646 / 192)
    .set({
      height: 42,
    }),
  apple: make()
    .ratio(151 / 40)
    .set({
      height: 42,
    }),
});
