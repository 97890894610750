import { useNavigation } from "@react-navigation/native";
import { Image, Pressable, View } from "react-native";

import { make, stylize } from "../lib/style";
import { useToggleFavorite } from "../queries/product";
import { makeBox, makeListItem, makeRow } from "../themes/components";
import SyButton from "./SyButton";
import SyIcon from "./SyIcon";

import SyText from "./SyText";

export default function SyProductListItem({
  id,
  name,
  thumbnail,
  isOwner,
  favoritedByUsers,
  style,
}) {
  const navigation = useNavigation();
  const [toggleFavorite] = useToggleFavorite();
  const gotoProduct = () => navigation.push("ProductDetail", { id });

  const remove = () => {
    toggleFavorite({ variables: { productId: id } });
  };

  return (
    <Pressable
      style={[styles.container, style]}
      onPress={() => gotoProduct(id)}
    >
      <View style={styles.imageContainer}>
        <Image source={{ uri: thumbnail }} style={styles.image}></Image>
      </View>
      <View style={styles.content}>
        <View style={styles.header}>
          <SyText
            content={name}
            style={styles.title}
            lines={1}
            ellipsis="head"
          ></SyText>
        </View>
        <View style={styles.favorites}>
          <View style={styles.favoritesCount}>
            <SyIcon name="heart" style={styles.star} solid={true}></SyIcon>
            <SyText
              content={favoritedByUsers}
              style={styles.starText}
              lines={1}
              ellipsis="head"
            ></SyText>
          </View>
          <SyButton
            style={styles.removeButton}
            labelStyle={styles.removeButtonLabelStyle}
            label="Favorilerden Çıkart"
            onPress={remove}
          ></SyButton>
        </View>
      </View>
    </Pressable>
  );
}

const listItem = makeListItem();

listItem.container.margin("s");
listItem.header.itemsCenter().flex(1);
listItem.title.font("m", "m");

const styles = stylize({
  ...listItem,
  star: make().color("primary").font("l", "m", "primary"),
  starText: make().color("primary").font("l"),
  favoritesCount: makeRow("s").itemsCenter(),
  favorites: make().row().itemsCenter().spaceBetween(),
  removeButton: make().background("primary"),
  removeButtonLabelStyle: make().font('m','l',"light"),
});
