import { Platform } from "react-native";
import { URL } from "react-native-url-polyfill";

export function convertToGrid(imageUrl) {
  return convertImageUrl(imageUrl, "grid");
}

export function convertToAvatar(imageUrl) {
  return convertImageUrl(imageUrl, "avatar");
}

function convertImageUrl(imageUrl, type) {
  let url;

  if (Platform.OS === "web") {
    url = window.URL;
  } else {
    url = URL;
  }

  const thumbnailUrl = new url(imageUrl);
  const pathname = thumbnailUrl.pathname;
  thumbnailUrl.pathname = type + "-" + pathname.substring(1);
  return thumbnailUrl.toString();
}
