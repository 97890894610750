import { View } from "react-native";
import SyPageModal from "../widgets/SyPageModal";
import SyBrandSlogan from "../widgets/SyBrandSlogan";
import SyButton from "../widgets/SyButton";
import { make, stylize } from "../lib/style";
import { makeColumn } from "../themes/components";
import SyText from "../widgets/SyText";
import SyIcon from "../widgets/SyIcon";
import { useReportUser } from "../queries/profile";

export default function ReportPage({ navigation, route }) {
  const reportedId = route.params.userId;
  const [reportUser] = useReportUser();
  const report = async (reportType) => {
    const ret = await reportUser({
      variables: {
        id: reportedId,
        reason: reportType,
      },
    });

    navigation.navigate("ReportSuccess", { userId: route.params.userId });
  };

  return (
    <SyPageModal authRequired={false} showExit={false}>
      <View style={styles.modalContent}>
        <SyIcon
          name="alert-decagram-outline"
          style={styles.alertStyle}
        ></SyIcon>
        <SyText
          style={styles.successMessage}
          content="Üyeyi neden bildirmek istiyorsun?"
        ></SyText>
      </View>
      <SyButton
        style={styles.successButton}
        labelStyle={styles.successButtonLabel}
        onPress={() => report("sales")}
        label="Satış yapıyor"
      ></SyButton>
      <SyButton
        style={styles.successButton}
        labelStyle={styles.successButtonLabel}
        onPress={() => report("disturbance")}
        label="Rahatsız ediyor"
      ></SyButton>
      <SyText
        style={styles.successMessage}
        content="Bildiriminle ilgili en kısa sürede aksiyon alınacaktır."
      ></SyText>
    </SyPageModal>
  );
}

const styles = stylize({
  modalContent: makeColumn().padding("m").itemsCenter().gap("s"),
  successMessage: make().textCenter(),
  successButton: make().background("secondary"),
  successButtonLabel: make().font("m", "l", "white"),
  alertStyle: make().font("xxl", "m", "secondary"),
});
