import { ScrollView } from "react-native";
import { useProfileCompleteOutgoingRequests } from "../queries/profile";
import SyNA from "../widgets/SyNA";
import SyPage from "../widgets/SyPage";
import SyRequestListItem from "../widgets/SyRequestListItem";

export default function OutgoingRequestsArchivePage() {

  const { loading, data,refetch } = useProfileCompleteOutgoingRequests();

  const outgoingRequests = data?.profile?.completeOutgoingRequests;

  if (!outgoingRequests || !outgoingRequests.length) {
    return <SyNA loading={loading}></SyNA>;
  }

  return (
    <SyPage loading={loading} authRequired={true}  refetch={refetch} data={data}>
      <ScrollView>
        {outgoingRequests &&
          outgoingRequests.map((request) => {
            if (!request.sender || !request.product || !request.receiver) {
              return null;
            }

            return (
              <SyRequestListItem
                {...request}
                incoming={false}
                key={request.id}
              ></SyRequestListItem>
            );
          })}
      </ScrollView>
    </SyPage>
  );
}
