import { useState } from "react";
import { Pressable, View } from "react-native";
import * as WebBrowser from "expo-web-browser";

import { make, stylize } from "../lib/style";
import { useLogin, useResetPassword } from "../queries/profile";
import { makeColumn, makeRow } from "../themes/components";

import SyButton from "../widgets/SyButton";
import SyInputText from "../widgets/SyInputText";
import SyText from "../widgets/SyText";
import { emailSent, loginEmpty } from "../lib/error";
import SyButtonGoogle from "../widgets/SyButtonGoogle";
import SyButtonApple from "../widgets/SyButtonApple";
import SyPageModal from "../widgets/SyPageModal";
import SyBrandSlogan from "../widgets/SyBrandSlogan";
import SyModalInput from "../widgets/SyModalInput";
import SyTitle from "../widgets/SyTitle";

WebBrowser.maybeCompleteAuthSession();

export default function DeliveryNotificationPage({
  navigation,
  shipmentType = "Manual",
}) {
  const onPress = () => {};

  if (shipmentType === "Manual") {
    return (
      <SyPageModal authRequired={false}>
        <View style={styles.header}>
          <SyText
            content="Elden teslimde karşı tarafın iletişim bilgilerini edinmek üyelerin sorumluluğundadır."
            style={styles.reminderText}
          ></SyText>
          <SyText
            content="saykl, hiç bir üyenin verilerini bir başkasıyla paylaşmaz."
            style={styles.reminderText}
          ></SyText>
        </View>
        <View style={styles.actions}>
          <SyButton
            label="Talep Gönder"
            onPress={onPress}
            style={styles.loginButton}
            labelStyle={styles.loginButtonLabel}
          ></SyButton>
        </View>
      </SyPageModal>
    );
  }

  return (
    <SyPageModal authRequired={false}>
      <View style={styles.header}>
        <SyTitle content="Anlaşmalı Kargoyla Talep Gönderme"></SyTitle>
        <SyText
          content="İlan sahibinden talebine yönelik onay gelirse kargo ödemeni gerçekleştirebilirsin."
          style={styles.reminderText}
        ></SyText>
      </View>
      <View style={styles.actions}>
        <SyButton
          label="Talep Gönder"
          onPress={onPress}
          style={styles.loginButton}
          labelStyle={styles.loginButtonLabel}
        ></SyButton>
      </View>
    </SyPageModal>
  );
}

/*
<SyInputText
        placeholder="Ad"
        value={firstName}
        onChangeText={changeFirstName}
        style={styles.input}
      ></SyInputText>
      <SyInputText
        placeholder="Soyad"
        value={lastName}
        onChangeText={changeLastName}
        style={styles.input}
      ></SyInputText>
      <SyInputText
        placeholder="Telefon"
        value={phone}
        onChangeText={changePhone}
        style={styles.input}
      ></SyInputText>
      <SyInputText
        placeholder="Adres"
        value={address}
        onChangeText={changeAddress}
        style={styles.address}
        multiline={true}
        lines={3}
      ></SyInputText>
*/
const styles = stylize({
  input: make().shadow(1),
  address: make().shadow(1).set({
    height: 100,
  }),
  loginButton: make().flex(1).background("primary"),
  loginButtonLabel: make().font("m", "l", "light"),
  signupButton: make().flex(1).background("light"),
  signupButtonLabel: make().font("m", "l", "main"),
  actions: make().row().spaceBetween().gap("m"),
  socialActions: make().column().gap("m"),
  passwordResetTitle: make(),
  footer: makeRow()
    .itemsCenter()
    .center()
    .set((theme) => ({
      marginTop: theme.size.l,
    })),
  text: make().color("primary"),
  passwordText: make().font("m", "m", "primary"),
  passwordResetModal: makeColumn("m"),
  passwordResetButton: make().background("primary"),
  passwordResetButtonLabel: make().font("m", "l", "light"),
  terms: makeRow("s").itemsCenter().center(),
  text: make().color("lightGrey3").textCenter(),
  linkButtonLabel: make().color("primary"),
  linkButton: make()
    .background("transparent")
    .border("n")
    .shadow(0)
    .padding("n"),
  termsSwitch: makeRow("s").itemsCenter(),
  header: make()
    .itemsCenter()
    .set((theme) => ({ marginBottom: theme.size.m, marginTop: theme.size.m })),
  reminderText: make().font("m", "m", "lightGrey3").textCenter(),
});
