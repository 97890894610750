import { Pressable, View } from "react-native";
import { make, stylize } from "../lib/style";
import { useCategories } from "../queries/category";
import { makeColumn } from "../themes/components";
import SyButton from "./SyButton";
import SyModalInput from "./SyModalInput";
import SyText from "./SyText";
import SyTitle from "./SyTitle";

export default function SyModalCategory({
  visible,
  changeVisible,
  changeCategory,
  showAll = true,
}) {
  const { loading, data, error } = useCategories();

  const updateCategory = (category) => {
    changeCategory(category);
    changeVisible(false);
  };

  const categories = [];

  if (showAll) {
    categories.push({
      id: "",
      name: "Tüm Kategoriler",
    });
  }

  if (data?.categories) {
    categories.push(...data?.categories);
  }

  return (
    <SyModalInput
      visible={visible}
      changeVisible={changeVisible}
      loading={loading}
      error={error}
    >
      {categories &&
        categories.map((category, index) => (
          <View key={category.id}>
            <Pressable
              style={styles.item}
              onPress={() => updateCategory(category)}
            >
              <SyText style={styles.itemLabel} content={category.name}></SyText>
            </Pressable>
            {index < categories.length - 1 && (
              <View style={styles.separator}></View>
            )}
          </View>
        ))}
    </SyModalInput>
  );
}

const styles = stylize({
  scrollview: make().height("carousel"),
  container: makeColumn("m").gap("m"),
  item: make()
    .shadow(0)
    .background("transparent")
    .itemsCenter()
    .padding("s", true, false),
  itemLabel: make().color("main"),
  separator: make()
    .height("u")
    .background("primary")
    .set({
      width: "65%",
    })
    .selfCenter(),
});
