import { useEffect, useRef, useState } from "react";
import {
  KeyboardAvoidingView,
  Pressable,
  ScrollView,
  View,
} from "react-native";
import SyIconPlane from "../icons/SyIconPlane";

import { make, stylize } from "../lib/style";
import { uuid } from "../lib/util";
import { formatDate, formatTime, profileVar } from "../queries/cache";
import { useChatMessages, useCreateMessage } from "../queries/chat";
import { messageReceivedSubscription } from "../queries/gql";

import SyChatListItem from "../widgets/SyChatListItem";
import SyInputText from "../widgets/SyInputText";
import SyPage from "../widgets/SyPage";
import SyText from "../widgets/SyText";
import SySpace from "../widgets/SySpace";
import { makeColumn, makeRow } from "../themes/components";
import SyIcon from "../widgets/SyIcon";
import { useReactiveVar } from "@apollo/client";

export default function ChatDetailPage({ route }) {
  const profile = useReactiveVar(profileVar);
  const chatId = route.params.id;
  const scrollViewRef = useRef(null);
  const chatRet = useChatMessages(chatId);
  const [newMessageText, changeNewMessageText] = useState("");
  const [sendMessage, sendMessageRet] = useCreateMessage();

  useEffect(() => {
    chatRet.subscribeToMore({
      document: messageReceivedSubscription,
      variables: { chatId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }
        const newMessage = subscriptionData.data.messageReceived;
        return Object.assign(
          {},
          {
            chat: {
              ...prev.chat,
              messages: [...prev.chat.messages, newMessage],
            },
          }
        );
      },
    });
  }, []);

  const sendNewMessage = async () => {
    if (newMessageText === "") {
      return;
    }

    changeNewMessageText("");

    await sendMessage({
      variables: { id: uuid(), chatId, content: newMessageText },
    });
  };

  const messages = chatRet.data?.chat.messages;
  const product = chatRet.data?.chat.product;
  const sender = chatRet.data?.chat.sender;
  const receiver = chatRet.data?.chat.receiver;

  return (
    <SyPage style={styles.page} loading={chatRet.loading} pullToRefresh={false}>
      <SyChatListItem
        id={chatId}
        product={product}
        sender={sender}
        receiver={receiver}
        border={false}
        isSeen={true}
      ></SyChatListItem>

      <ScrollView
        ref={scrollViewRef}
        contentContainerStyle={styles.messages}
        onContentSizeChange={() =>
          scrollViewRef.current.scrollToEnd({ animated: false })
        }
      >
        {profile &&
          messages &&
          messages.map((message, index) => {
            const rowStylez = [styles.messageRow];
            const stylez = [styles.message];
            let showIcon = false;
            if (message.from.id === profile.id) {
              showIcon = true;
              stylez.push(styles.userMessage);
              rowStylez.push(styles.userMessageRow);
            } else {
              stylez.push(styles.otherMessage);
              rowStylez.push(styles.otherMessageRow);
            }

            let showDateSection = false;

            if (!messages[index - 1]) {
              showDateSection = true;
            } else {
              const currentDay = new Date(message.createdAt).getDate();
              const prevDay = new Date(messages[index - 1].createdAt).getDate();

              if (currentDay !== prevDay) {
                showDateSection = true;
              }
            }

            return (
              <View style={styles.messageColumn} key={message.id}>
                {showDateSection && (
                  <View style={styles.dateSection}>
                    <SyText content={formatDate(new Date(message.createdAt))}></SyText>
                  </View>
                )}
                <View style={rowStylez}>
                  <View style={stylez}>
                    <View style={styles.contentContainer}>
                      <SyText
                        content={message.content}
                        style={styles.messageText}
                        multiline
                      ></SyText>
                      <View style={styles.footer}>
                        <SyText
                          style={styles.messageTime}
                          content={formatTime(new Date(message.createdAt))}
                        ></SyText>
                        {showIcon && (
                          <SyIcon
                            name={message.seenAt ? "check-all" : "check"}
                          ></SyIcon>
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            );
          })}
      </ScrollView>
      <View style={styles.input}>
        <SyInputText
          style={styles.text}
          placeholder="Yazmaya başla..."
          multiline={true}
          value={newMessageText}
          onChangeText={changeNewMessageText}
        ></SyInputText>
        <Pressable onPress={sendNewMessage}>
          <SyIconPlane width={36} height={36} stroke={primary}></SyIconPlane>
        </Pressable>
      </View>
      <SySpace color="light"></SySpace>
    </SyPage>
  );
}

const primary = make().theme.colors.primary;

const styles = stylize({
  page: make().column(),
  messages: make().column().gap("s").padding("s"),
  messageRow: make().row(),
  otherMessageRow: make().row().contentStart(),
  userMessageRow: make().row().contentEnd(),
  message: make().radius("m").set({
    maxWidth: "80%",
  }),
  otherMessage: make().background("otherMessageBackground"),
  userMessage: make().background("userMessageBackground"),
  contentContainer: make().padding("s"),
  messageTime: make().font("smallText").color("lightGrey4"),
  messageText: make().font("m", "m", "messageTextColor"),
  input: make().row().itemsCenter().padding("s").background("light"),
  text: make().flex(1).shadow(0).background("primaryBackground").padding("s"),
  sendIcon: make().set((theme) => ({
    fontSize: 24,
  })),
  chatTitle: make().padding("m").background("light"),
  footer: makeRow("s").end(),
  dateSection: make().padding('s').textCenter().width('dateSection').selfCenter(),
  messageColumn: makeColumn(),
});
