import { ScrollView } from "react-native";
import SyNA from "../widgets/SyNA";
import SyNotificationListItem from "../widgets/SyNotificationListItem";
import SyPage from "../widgets/SyPage";
import SySpace from "../widgets/SySpace";
import { useSystemMessages } from "../queries/operations/notification";
import { clearNotifications } from "../lib/notification";

export default function SystemMessagesPage() {
  clearNotifications("SystemMessage");

  const { loading, data, refetch } = useSystemMessages();

  const systemMessages = data?.profile?.systemMessages;

  if (!systemMessages || !systemMessages.length) {
    return <SyNA loading={loading}></SyNA>;
  }

  return (
    <SyPage
      loading={loading}
      authRequired={true}
      refetch={refetch}
      data={data}
    >
      <ScrollView>
          {systemMessages.map((systemMessage, index) => {
            return <SyNotificationListItem {...systemMessage} key={systemMessage.id}></SyNotificationListItem>;
          })}
        <SySpace mode="page"></SySpace>
      </ScrollView>
    </SyPage>
  );
}
