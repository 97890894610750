import { gql } from "@apollo/client";

export const fetchCategoriesQuery = gql`
  query GetCategories {
    categories {
      id
      name
    }
  }
`;

export const fetchChatQuery = gql`
  query GetChat($id: ID, $productId: ID, $senderId: ID, $receiverId: ID) {
    chat(
      id: $id
      productId: $productId
      senderId: $senderId
      receiverId: $receiverId
    ) {
      id
      isSeen
      createdAt
      updatedAt
      sender {
        id
        name
        image
      }
      receiver {
        id
        name
        image
      }
      createdDate @client
      createdDateTime @client
      createdTime @client

      updatedDate @client
      updatedDateTime @client
      updatedTime @client
    }
  }
`;

export const fetchChatMessagesQuery = gql`
  query GetChatMessages($chatId: ID!) {
    chat(id: $chatId) {
      id
      isSeen
      createdAt
      updatedAt
      product {
        id
        name
        thumbnail
        owner {
          id
          name
        }
      }
      sender {
        id
        name
        image
      }
      receiver {
        id
        name
        image
      }
      messages {
        id
        createdAt
        content
        from {
          id
        }
        to {
          id
        }
        seenAt

        createdDate @client
        createdDateTime @client
        createdTime @client

        updatedDate @client
        updatedDateTime @client
        updatedTime @client
      }
      createdDate @client
      createdDateTime @client
      createdTime @client

      updatedDate @client
      updatedDateTime @client
      updatedTime @client
    }
  }
`;

export const fetchProductQuery = gql`
  query GetProduct($id: ID!) {
    product(id: $id) {
      id
      shortId
      name
      description
      images
      sayklPoints
      category {
        id
        name
      }
      owner {
        id
        name
        image
        rating
        isBlocked
      }
      favoritedByUsers
      isFavorite
      locationType
      location {
        latitude
        longitude
        region
        subregion
      }
      shipmentType
      condition
      createdAt
      availability
      isOwner @client
      isFavorite @client
      date @client
      time @client
      datetime @client
    }
  }
`;

export const fetchProductBasicQuery = gql`
  query GetProduct($id: ID!) {
    product(id: $id) {
      id
      shortId
      name
      description
      thumbnail
      shipmentType
      sayklPoints
      owner {
        id
        name
        image
        rating
      }
      category {
        id
        name
      }
      availability
      favoritedByUsers
      createdAt
      isOwner @client
      isFavorite @client
      date @client
      time @client
      datetime @client
    }
  }
`;

export const fetchProductsQuery = gql`
  query SearchProducts(
    $search: String
    $category: String
    $condition: String
    $distance: Float
    $shipment: String
    $page: Int
  )
  @connection(
    key: "search-id"
    filter: ["search", "category", "condition", "distance", "shipment"]
  ) {
    profile {
      id
    }

    products(
      search: $search
      category: $category
      condition: $condition
      distance: $distance
      shipment: $shipment
      page: $page
    ) {
      id
      shortId
      name
      thumbnail
      favoritedByUsers
      sayklPoints
      location {
        region
        subregion
      }
      category {
        id
        name
      }
      owner {
        id
      }
      sayklPoints
      createdAt
      isOwner @client
      isFavorite @client
      date @client
      time @client
      datetime @client
    }
  }
`;

export const fetchUserProductsQuery = gql`
  query GetUserProducts($userId: ID!) {
    user(id: $userId) {
      products {
        id
        shortId
        name
        thumbnail
        owner {
          id
          rating
        }
        category {
          id
          name
        }
        sayklPoints
        favoritedByUsers
        isOwner @client
        isFavorite @client
        date @client
        time @client
        datetime @client
      }
    }
  }
`;

export const fetchProfileQuery = gql`
  query GetProfile {
    profile {
      id
      name
      email
      sayklPoints
      image
      phone
      rating
      account
      location {
        region
        subregion
        latitude
        longitude
      }
      readableLocation @client
      favorites {
        id
        owner {
          id
        }
        isOwner @client
        isFavorite @client
      }
      settings {
        theme
        notificationsEnabled
        emailEnabled
        adsEnabled
        hideLastname
        hidePhone
        hideSeen
      }
    }
  }
`;

export const fetchProfileProductsQuery = gql`
  query GetProfileProducts {
    profile {
      id
      readableLocation @client
      products {
        id
        shortId
        name
        thumbnail
        favoritedByUsers
        createdAt
        approvalState
        sayklPoints
        owner {
          id
        }
        category {
          id
          name
        }
        isOwner @client
        isFavorite @client
        date @client
        time @client
        datetime @client
      }
    }
  }
`;

export const fetchProfileFavoritesQuery = gql`
  query GetProfileFavorites {
    profile {
      id
      favorites {
        id
        name
        thumbnail
        favoritedByUsers
        owner {
          id
        }
      }
    }
  }
`;

export const fetchProfileChatsQuery = gql`
  query GetProfileChats {
    profile {
      id
      chats {
        id
        isSeen
        createdAt
        updatedAt
        product {
          id
          name
          thumbnail
          owner {
            id
          }
        }

        sender {
          id
          name
        }
        receiver {
          id
          name
        }
      }
    }
  }
`;

export const fetchProfileIncomingRequestsQuery = gql`
  query GetProfileIncomingRequests {
    profile {
      id
      incomingRequests {
        id
        state
        shipmentType
        product {
          id
          name
          thumbnail
          sayklPoints
          owner {
            id
            name
          }
        }
        sender {
          id
          name
        }
        receiver {
          id
          name
        }
        updatedAt
        createdAt
        senderCompleted
        receiverCompleted
        isSender @client
        isReceiver @client
        date @client
        time @client
        datetime @client
        updatedDate @client
        updatedTime @client
        updatedDatetime @client
      }
    }
  }
`;

export const fetchProfileOutgoingRequestsQuery = gql`
  query GetProfileOutgoingRequests {
    profile {
      id
      outgoingRequests {
        id
        state
        shipmentType
        product {
          id
          name
          thumbnail
          sayklPoints
          owner {
            id
            name
          }
        }
        sender {
          id
          name
        }
        receiver {
          id
          name
        }

        senderCompleted
        receiverCompleted
        isSender @client
        isReceiver @client

        createdAt
        date @client
        time @client
        datetime @client

        updatedAt
        updatedDate @client
        updatedTime @client
        updatedDatetime @client
      }
    }
  }
`;

export const fetchProfileCompleteIncomingRequestsQuery = gql`
  query GetProfileCompleteIncomingRequests {
    profile {
      id
      completeIncomingRequests {
        id
        state
        shipmentType
        product {
          id
          name
          thumbnail
          sayklPoints
          owner {
            id
            name
          }
        }
        sender {
          id
          name
        }
        receiver {
          id
          name
        }
        updatedAt
        createdAt
        senderCompleted
        receiverCompleted
        isSender @client
        isReceiver @client
        date @client
        time @client
        datetime @client
        updatedDate @client
        updatedTime @client
        updatedDatetime @client
      }
    }
  }
`;

export const fetchProfileCompleteOutgoingRequestsQuery = gql`
  query GetProfileCompleteOutgoingRequests {
    profile {
      id
      completeOutgoingRequests {
        id
        state
        shipmentType
        product {
          id
          name
          thumbnail
          sayklPoints
          owner {
            id
            name
          }
        }
        sender {
          id
          name
        }
        receiver {
          id
          name
        }

        senderCompleted
        receiverCompleted
        isSender @client
        isReceiver @client

        createdAt
        date @client
        time @client
        datetime @client

        updatedAt
        updatedDate @client
        updatedTime @client
        updatedDatetime @client
      }
    }
  }
`;

export const fetchProfileNotificationsQuery = gql`
  query GetProfileNotifications {
    profile {
      id
      notifications {
        id
        type
        request {
          id
        }
        chat {
          id
        }
        createdAt
      }
    }
  }
`;

export const fetchProfileSystemMessagesQuery = gql`
  query GetProfileSystemMessages {
    profile {
      id
      systemMessages {
        id
        type
        title
        content
        createdAt
      }
    }
  }
`;

export const fetchUserQuery = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      id
      isBlocked
      name
      image
      rating
      phone
      location {
        region
        subregion
      }
      readableLocation @client
      products {
        id
        name
        thumbnail
        favoritedByUsers
        sayklPoints
        location {
          region
          subregion
        }
        owner {
          id
        }
      }
    }
  }
`;

export const requestQuery = gql`
  query GetRequest($id: ID!) {
    request(id: $id) {
      id
      state
      shipmentType
      product {
        id
        name
        thumbnail
        sayklPoints
      }
      sender {
        id
        name
        image
      }
      receiver {
        id
        name
        image
      }
      senderCompleted
      receiverCompleted
      isSender @client
      isReceiver @client

      createdAt
      date @client
      time @client
      datetime @client

      updatedAt
      updatedDate @client
      updatedTime @client
      updatedDatetime @client
    }
  }
`;

export const toggleFavoriteMutation = gql`
  mutation ToggleFavorite($productId: ID!) {
    toggleFavorite(productId: $productId) {
      id
      product {
        id
        favoritedByUsers
      }
      user {
        id
      }
    }
  }
`;

export const createMessageMutation = gql`
  mutation CreateMessage($id: ID!, $chatId: ID!, $content: String!) {
    createMessage(id: $id, chatId: $chatId, content: $content) {
      id
    }
  }
`;

export const createChatMutation = gql`
  mutation CreateChat(
    $id: ID!
    $productId: ID!
    $message: String!
    $otherId: ID!
  ) {
    createChat(
      id: $id
      productId: $productId
      message: $message
      otherId: $otherId
    ) {
      id
    }
  }
`;

export const deleteChatMutation = gql`
  mutation DeleteChat($id: ID!) {
    deleteChat(id: $id)
  }
`;

export const saveProductMutation = gql`
  mutation SaveProduct(
    $id: ID!
    $shortId: String!
    $name: String!
    $description: String
    $categoryId: ID!
    $images: [String]!
    $condition: Condition!
    $shipmentType: ShipmentType
    $locationType: LocationType!
    $location: LocationInput
    $sayklPoints: Int
  ) {
    saveProduct(
      id: $id
      shortId: $shortId
      name: $name
      description: $description
      categoryId: $categoryId
      images: $images
      condition: $condition
      shipmentType: $shipmentType
      locationType: $locationType
      location: $location
      sayklPoints: $sayklPoints
    ) {
      id
      shortId
      name
      description
      images
      sayklPoints
      category {
        id
        name
      }
      owner {
        id
        name
      }
      locationType
      shipmentType
      condition
      createdAt
      isOwner @client
      isFavorite @client
      date @client
    }
  }
`;

export const removeProductMutation = gql`
  mutation RemoveProduct($id: ID!) {
    removeProduct(id: $id)
  }
`;

export const logoutMutation = gql`
  mutation UserLogout {
    logout
  }
`;

export const loginMutation = gql`
  mutation UserLogin($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      access
      refresh
    }
  }
`;

export const socialLoginMutation = gql`
  mutation UserSocialLogin(
    $account: String!
    $accountId: String!
    $token: String!
    $email: String
    $name: String
    $image: String
  ) {
    socialLogin(
      account: $account
      accountId: $accountId
      token: $token
      email: $email
      name: $name
      image: $image
    ) {
      access
      refresh
      isNewUser
    }
  }
`;

export const signupMutation = gql`
  mutation UserSignup(
    $name: String!
    $email: String!
    $password: String!
    $invitedById: ID
  ) {
    signup(
      name: $name
      email: $email
      password: $password
      invitedById: $invitedById
    ) {
      access
      refresh
    }
  }
`;

export const resetPasswordMutation = gql`
  mutation UserResetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;

export const saveProfileMutation = gql`
  mutation UserSave(
    $name: String
    $image: String
    $email: String
    $phone: String
    $currentPassword: String
    $password: String
    $passwordRepeat: String
    $location: LocationInput
    $resetPasswordCode: String
  ) {
    saveProfile(
      name: $name
      image: $image
      email: $email
      phone: $phone
      currentPassword: $currentPassword
      password: $password
      passwordRepeat: $passwordRepeat
      location: $location
      resetPasswordCode: $resetPasswordCode
    ) {
      id
      name
      email
      image
      phone
      location {
        region
        subregion
        latitude
        longitude
      }
      readableLocation @client
    }
  }
`;

export const deleteProfileMutation = gql`
  mutation UserDelete {
    deleteProfile
  }
`;

export const saveProfileSettingsMutation = gql`
  mutation SettingsSave($settings: SettingsInput) {
    saveProfileSettings(settings: $settings)
  }
`;

export const savePushTokenMutation = gql`
  mutation SavePushToken($token: String!) {
    savePushToken(token: $token)
  }
`;

export const createRequestMutation = gql`
  mutation CreateRequest($id: ID!, $productId: ID!, $shipmentType: String!) {
    createRequest(id: $id, productId: $productId, shipmentType: $shipmentType) {
      id
      state
      shipmentType
      product {
        id
        name
        thumbnail
      }
      sender {
        id
        name
      }
      receiver {
        id
        name
      }
      isSender @client
      isReceiver @client
      updatedDate @client
      updatedTime @client
      updatedDatetime @client
    }
  }
`;

export const acceptRequestMutation = gql`
  mutation AcceptRequest($id: ID!) {
    acceptRequest(id: $id) {
      id
      state
      updatedAt
      updatedDate @client
      updatedTime @client
      updatedDatetime @client
    }
  }
`;

export const declineRequestMutation = gql`
  mutation DeclineRequest($id: ID!) {
    declineRequest(id: $id) {
      id
      state
      updatedAt
      updatedDate @client
      updatedTime @client
      updatedDatetime @client
    }
  }
`;

export const confirmDeliveryRequestMutation = gql`
  mutation ConfirmDeliveryRequest($id: ID!) {
    confirmDeliveryRequest(id: $id) {
      id
      state
      updatedAt
      senderCompleted
      receiverCompleted
      updatedDate @client
      updatedTime @client
      updatedDatetime @client
    }
  }
`;

export const confirmReceivalRequestMutation = gql`
  mutation ConfirmReceivalRequest($id: ID!) {
    confirmReceivalRequest(id: $id) {
      id
      state
      updatedAt
      senderCompleted
      receiverCompleted
      updatedDate @client
      updatedTime @client
      updatedDatetime @client
    }
  }
`;

export const cancelRequestMutation = gql`
  mutation CancelRequest($id: ID!) {
    cancelRequest(id: $id) {
      id
      state
      updatedAt
      updatedDate @client
      updatedTime @client
      updatedDatetime @client
    }
  }
`;

export const senderCompleteMutation = gql`
  mutation CompleteSenderRequest($id: ID!, $rating: Int!) {
    completeSenderRequest(id: $id, rating: $rating) {
      id
      state
      senderCompleted
      receiverCompleted
      updatedAt
      updatedDate @client
      updatedTime @client
      updatedDatetime @client
    }
  }
`;

export const receiverCompleteMutation = gql`
  mutation CompleteReceiverRequest($id: ID!, $rating: Int!) {
    completeReceiverRequest(id: $id, rating: $rating) {
      id
      state
      senderCompleted
      receiverCompleted
      updatedAt
      updatedDate @client
      updatedTime @client
      updatedDatetime @client
    }
  }
`;

export const blockUserMutation = gql`
  mutation BlockUser($id: ID!) {
    blockUser(id: $id)
  }
`;

export const unblockUserMutation = gql`
  mutation UnblockUser($id: ID!) {
    unblockUser(id: $id)
  }
`;

export const reportUserMutation = gql`
  mutation ReportUser($id: ID!, $reason: String!) {
    reportUser(id: $id, reason: $reason)
  }
`;

export const confirmEmailMutation = gql`
  mutation ConfirmEmail($code: String!) {
    confirmEmail(code: $code)
  }
`;

export const messageReceivedSubscription = gql`
  subscription MessageReceived($chatId: ID!) {
    messageReceived(chatId: $chatId) {
      id
      createdAt
      chat {
        id
        isSeen
        createdAt
        updatedAt
        product {
          id
          name
          thumbnail
          owner {
            id
            name
          }
        }
        sender {
          id
          name
          image
        }
        receiver {
          id
          name
          image
        }
      }
      content
      to {
        id
      }

      from {
        id
      }
    }
  }
`;

export const requestStateChangedSubscription = gql`
  subscription RequestStateChanged($id: ID!) {
    requestStateChanged(id: $id) {
      id
      state
      senderCompleted
      receiverCompleted
      updatedAt
      updatedDate @client
      updatedTime @client
      updatedDatetime @client
    }
  }
`;

export const convertSayklPointMutation = gql`
  mutation ConvertSayklPoint {
    convertSayklPoint
  }
`;
