import { View } from "react-native";
import { useState } from "react";

import { make, stylize } from "../lib/style";
import { useProducts } from "../queries/product";
import { graphqlClient } from "../lib/graphql";
import SyInputText from "../widgets/SyInputText";
import SyPage from "../widgets/SyPage";
import SyProductGrid from "../widgets/SyProductGrid";
import { makeRow } from "../themes/components";
import {
  CATEGORIES_DEFAULT,
  CONDITIONS,
  CONDITIONS_DEFAULT,
  DISTANCES,
  DISTANCES_DEFAULT,
  SHIPMENT_TYPES_DEFAULT,
  SHIPMENT_TYPES_SEARCH,
} from "../lib/constants";

import SyModalCategory from "../widgets/SyModalCategory";
import SyButtonSelection from "../widgets/SyButtonSelection";
import SyModalSelection from "../widgets/SyModalSelection";
import {
  categoryVar,
  conditionVar,
  distanceVar,
  paginationVar,
  searchVar,
  shipmentVar,
} from "../queries/cache";
import { useReactiveVar } from "@apollo/client";

export default function SearchPage() {
  const page = useReactiveVar(paginationVar);
  const search = useReactiveVar(searchVar);
  const category = useReactiveVar(categoryVar);
  const distance = useReactiveVar(distanceVar);
  const shipment = useReactiveVar(shipmentVar);
  const condition = useReactiveVar(conditionVar);

  const [categoryModalVisible, changeCategoryModalVisible] = useState(false);
  const [conditionModalVisible, changeConditionModalVisible] = useState(false);
  const [distanceModalVisible, changeDistanceModalVisible] = useState(false);
  const [shipmentModalVisible, changeShipmentModalVisible] = useState(false);

  const { data, loading, refetch, fetchMore } = useProducts(
    search,
    category?.id,
    condition?.id,
    distance?.value,
    shipment?.id,
    page
  );

  const fetchPage = (page) => {
    if (page === 0) {
      graphqlClient.cache.evict({ id: "ROOT_QUERY", fieldName: "products" });
      paginationVar(0);
      return;
    }
    return fetchMore({
      variables: {
        search: search,
        category: category?.id,
        condition: condition?.id,
        distance: distance?.value,
        shipment: shipment?.id,
        page,
      },
    });
  };

  const updateFilter = async (value, cb) => {
    paginationVar(0);
    graphqlClient.cache.evict({ id: "ROOT_QUERY", fieldName: "products" });
    await cb(value);
    await refetch();
  };

  return (
    <SyPage authRequired={false}>
      <View style={styles.header}>
        <View style={styles.search.container}>
          <SyInputText
            placeholder="İhtiyacın olan ürünü ara"
            style={styles.search.input}
            value={search}
            onChangeText={(value) => updateFilter(value, searchVar)}
          ></SyInputText>
        </View>
        <View style={styles.filter}>
          <SyButtonSelection
            item={category}
            defaultState={CATEGORIES_DEFAULT}
            changeVisible={changeCategoryModalVisible}
          ></SyButtonSelection>
          <SyButtonSelection
            item={condition}
            defaultState={CONDITIONS_DEFAULT}
            changeVisible={changeConditionModalVisible}
          ></SyButtonSelection>
          <SyButtonSelection
            item={shipment}
            defaultState={SHIPMENT_TYPES_DEFAULT}
            changeVisible={changeShipmentModalVisible}
          ></SyButtonSelection>
          <SyButtonSelection
            id="value"
            item={distance}
            defaultState={DISTANCES_DEFAULT}
            changeVisible={changeDistanceModalVisible}
          ></SyButtonSelection>
        </View>
      </View>
      <SyProductGrid
        style={styles.grid}
        products={data?.products}
        loading={loading}
        hasBanner={true}
        refetch={refetch}
        fetchMore={fetchPage}
        paginate={true}
        page={page}
        changePage={paginationVar}
      ></SyProductGrid>
      <SyModalCategory
        visible={categoryModalVisible}
        changeVisible={changeCategoryModalVisible}
        changeCategory={(value) => updateFilter(value, categoryVar)}
      ></SyModalCategory>
      {
      <SyModalSelection
        items={CONDITIONS}
        visible={conditionModalVisible}
        changeVisible={changeConditionModalVisible}
        changeItem={(value) => updateFilter(value, conditionVar)}
      ></SyModalSelection>}
      <SyModalSelection
        items={SHIPMENT_TYPES_SEARCH}
        visible={shipmentModalVisible}
        changeVisible={changeShipmentModalVisible}
        changeItem={(value) => updateFilter(value, shipmentVar)}
      ></SyModalSelection>
      <SyModalSelection
        id="value"
        items={DISTANCES}
        visible={distanceModalVisible}
        changeVisible={changeDistanceModalVisible}
        changeItem={(value) => updateFilter(value, distanceVar)}
      ></SyModalSelection>
    </SyPage>
  );
}

const styles = stylize({
  page: make().column().spaceAround(),
  header: make().background("primaryBackground"),
  search: {
    container: make()
      .row("u")
      .itemsCenter()
      .padding("s", false, true)
      .set((theme) => ({
        paddingTop: theme.size.s,
      })),
    input: make().flex(1),
    button: make().flex(1),
  },
  categories: make().row().padding("s", true, false),
  grid: make().flex(1),
  filter: makeRow("s").padding("s"),

  filterButton: make().flex(1).background("light"),

  activeFilterButton: make().background("primary"),
  activeFilterButtonLabel: make().color("light"),

  inactiveFilterButton: make().background("light"),
  inactiveFilterButtonLabel: make(),

  filterRow: makeRow("l"),
  filterColumn: make()
    .flex(0.5)
    .radius("x")
    .set((theme) => ({
      borderWidth: 4,
      borderColor: theme.colors.light,
    }))
    .shadow(1)
    .overflowHidden()
    .background("light"),
});
