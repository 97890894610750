import { Linking, Platform, View } from "react-native";
import { SyIconSad } from "../icons/SyIconSad";
import { SHIPMENT_TYPES_REQUIRED } from "../lib/constants";
import { make, stylize } from "../lib/style";
import { uuid } from "../lib/util";
import { useProductBasic } from "../queries/product";
import { useCreateRequest } from "../queries/request";
import { makeBox } from "../themes/components";
import SyButton from "../widgets/SyButton";

import SyPage from "../widgets/SyPage";
import SySelection from "../widgets/SySelection";
import SyText from "../widgets/SyText";
import SyTitle from "../widgets/SyTitle";
import { useState } from "react";

export default function NewRequestPage({ navigation, route }) {
  const { loading, error, data } = useProductBasic(route.params.id);
  const [createRequest] = useCreateRequest();
  const [selectedShipmentType, changeSelectedShipmentType] = useState(null);
  const product = data?.product;
  const shipmentType = product?.shipmentType;

  const shipmentTypes = SHIPMENT_TYPES_REQUIRED.map((s) => {
    const ret = { ...s };
    if (shipmentType && s.id !== shipmentType) {
      ret.disabled = true;
    }

    return ret;
  });

  const availability = product?.availability;
  const gotoPurchase = () => {
    /*
    Linking.openURL("https://saykl.com/puan-al");
    return;
    */

    if (Platform.OS === "web") {
      navigation.replace("Purchase");
    } else {
      Linking.openURL("https://saykl.com/puan-al");
    }
  };

  const selectShipmentType = (shipmentType) => {
    if (
      shipmentType.id === "Manual" &&
      /*availability !== "NoSayklPoints"*/ availability !== "NoCash"
    ) {
      createRequest({
        variables: {
          id: uuid(),
          productId: route.params.id,
          shipmentType: shipmentType.id,
        },
      });
      return;
    }

    changeSelectedShipmentType(shipmentType.id);
  };

  let modalContent = null;

  if (/*Platform.OS === "web"*/ false) {
    modalContent = <WebRequestModal></WebRequestModal>;
  } else {
    console.log(availability);

    switch (selectedShipmentType) {
      case "Manual":
        if (/*availability === "NoSayklPoints"*/ availability === "NoCash") {
          modalContent = (
            <NoSayklPointsModal
              gotoPurchase={gotoPurchase}
            ></NoSayklPointsModal>
          );
        }
        break;
      case "Shipping":
        if (
          availability === "NoShippingPoints" ||
          availability === "NoPoints"
        ) {
          modalContent = (
            <NoShippingPointsModal
              gotoPurchase={gotoPurchase}
            ></NoShippingPointsModal>
          );
        }
        break;
      default:
        modalContent = (
          <SelectShipmentTypeModal
            shipmentTypes={shipmentTypes}
            selectShipmentType={selectShipmentType}
          />
        );
        break;
    }
  }

  /*switch (availability) {
    case "NoSayklPoints":
      modalContent = (
        <NoSayklPointsModal gotoPurchase={gotoPurchase}></NoSayklPointsModal>
      );
      break;
    case "NoPoints":
      modalContent = (
        <NoPointsModal gotoPurchase={gotoPurchase}></NoPointsModal>
      );
      break;
    case "NoShippingPoints":
      modalContent = (
        <NoShippingPointsModal
          gotoPurchase={gotoPurchase}
        ></NoShippingPointsModal>
      );
      break;
    case "Available":
  }*/

  return (
    <SyPage loading={loading} error={error} showExit={true}>
      <View style={styles.page}>{modalContent}</View>
    </SyPage>
  );
}

const colors = make().theme.colors;

const styles = stylize({
  page: make().flex(1).row().center().itemsCenter(),
  modal: makeBox(),
  modalContent: make().itemsCenter().padding("m").gap("s"),
  successButton: make().background("primary"),
  successButtonLabel: make().font("m", "l", "light"),
  reminder: make()
    .itemsCenter()
    .set((theme) => ({ marginTop: theme.size.xl })),
  reminderTop: make()
    .itemsCenter()
    .set((theme) => ({ marginBottom: theme.size.xl })),
  header: make()
    .itemsCenter()
    .set((theme) => ({ marginBottom: theme.size.xl })),
  reminderText: make().font("smallText", "m", "lightGrey3").textCenter(),
});

function NoSayklPointsModal({ gotoPurchase }) {
  return (
    <View style={styles.modal}>
      <View style={styles.modalContent}>
        <SyIconSad width={128} height={128} fill={colors.blank}></SyIconSad>
        <SyTitle content="Üzgünüz"></SyTitle>
        <View>
          <SyText content="Kullanılabilir saykl puanın yok."></SyText>
          <SyText content="saykl puan almak ister misin?"></SyText>
        </View>
        <View style={styles.reminderText}>
          <SyText content="(Satın alma işlemi için"></SyText>
          <SyText content="web sitemize yönlendirileceksin.)"></SyText>
        </View>
      </View>
      <SyButton
        style={styles.successButton}
        labelStyle={styles.successButtonLabel}
        label="İsterim"
        redirect="Purchase"
        redirectParent="Main"
        onPress={gotoPurchase}
      ></SyButton>
    </View>
  );
}
function NoShippingPointsModal({ gotoPurchase }) {
  return (
    <View style={styles.modal}>
      <View style={styles.modalContent}>
        <SyIconSad width={128} height={128} fill={colors.blank}></SyIconSad>
        <SyTitle content="Üzgünüz"></SyTitle>
        <View style={styles.centered}>
          <SyText content="Kullanılabilir kargo hakkın yok."></SyText>
          <SyText content="89.90 TL'ye kargo hakkı almak ister misin?"></SyText>
        </View>
        <View style={styles.reminderText}>
          <SyText content="(Satın alma işlemi için"></SyText>
          <SyText content="web sitemize yönlendirileceksin.)"></SyText>
        </View>
      </View>
      <SyButton
        style={styles.successButton}
        labelStyle={styles.successButtonLabel}
        label="İsterim"
        redirect="Purchase"
        redirectParent="Main"
        onPress={gotoPurchase}
      ></SyButton>
    </View>
  );
}
function NoPointsModal({ gotoPurchase }) {
  return (
    <View style={styles.modal}>
      <View style={styles.modalContent}>
        <SyIconSad width={128} height={128} fill={colors.blank}></SyIconSad>
        <SyTitle content="Üzgünüz"></SyTitle>
        <View style={styles.centered}>
          <SyText content="Kullanılabilir kargo hakkın yok."></SyText>
          <SyText content="89.90 TL'ye kargo hakkı almak ister misin?"></SyText>
        </View>
        <View style={styles.reminderText}>
          <SyText content="(Satın alma işlemi için"></SyText>
          <SyText content="web sitemize yönlendirileceksin.)"></SyText>
        </View>
      </View>
      <SyButton
        style={styles.successButton}
        labelStyle={styles.successButtonLabel}
        label="İsterim"
        redirect="Purchase"
        redirectParent="Main"
        onPress={gotoPurchase}
      ></SyButton>
    </View>
  );
}

function WebRequestModal() {
  return (
    <View style={styles.modal}>
      <View style={styles.modalContent}>
        <SyIconSad width={128} height={128} fill={colors.blank}></SyIconSad>
        <SyTitle content="Üzgünüz"></SyTitle>
        <View style={styles.centered}>
          <SyText content="Geçici bir süre Web üzerinden talep gönderilememektedir."></SyText>
          <SyText content="Mobil uygulama üzerinden işleme devam edebilirsin."></SyText>
        </View>
      </View>
    </View>
  );
}

function SelectShipmentTypeModal({ shipmentTypes, selectShipmentType }) {
  return (
    <View style={styles.modal}>
      <View style={styles.reminderTop}>
        <SyText
          content="Talep gönderdikten sonra 'Giden Talepler' bölümünde"
          style={styles.reminderText}
        ></SyText>
        <SyText
          content="detaylara tıklayarak ilan sahibi ile sohbet başlatıp"
          style={styles.reminderText}
        ></SyText>
        <SyText
          content="teslimat detaylarını görüşebilirsin."
          style={styles.reminderText}
        ></SyText>
      </View>
      <SySelection
        items={shipmentTypes}
        changeItem={selectShipmentType}
      ></SySelection>
      <View style={styles.reminder}>
        <SyText
          content="Taleple birlikte 1 saykl puan rezerve edilecektir."
          style={styles.reminderText}
        ></SyText>
        <SyText
          content="(İptal/red durumunda hesabına geri aktarılır.)"
          style={styles.reminderText}
        ></SyText>
      </View>
    </View>
  );
}
