import Common from "./common";

export default {
  ...Common,

  colors: {
    transparent: "transparent",
    main: "#aaa",
    placeholder: "#999",
    primary: "#05e605",
    secondary: "#e605e6",
    primaryBackground: "#222",
    tabBarColor: "#111",
    tabBarBorderColor: "#111",
    headerColor: "#111",
    headerBorderColor: "transparent",
    shadow: "#999",
    light: "#111",
    lightGrey: "#eee",
    lightGrey2: "#444",
    lightGrey3: "#999",
    lightGrey4: "#666",
    lightGrey4Alpha:"#222A",
    disable: "#ddd",
    active: "#7366F0",
    inactive: "#eee",
    red: "#EE5C6E",
    dark: "#2B2B2B",
    black: "#000000",
    yellow: "#ffff00",
    magenta: "#e605e6",
    mint: "#e6fff4",
    mint2: "#05e684",
    blank: "#fff",
    white:"#fff",
    modalBackground: "rgba(0,0,0,0.5)",
    headerIconColor:"#fff",
    buttonBackground:'transparent',
    otherMessageBackground:'#fff',
    userMessageBackground:'#05e684',
    messageTextColor:'#2B2B2B',
    inputBackground:'#212121',
    borderLight: "rgb(23, 23, 23)",
    webBorderColor:'#111',
  },
};
