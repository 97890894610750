import { Image, Pressable, View } from "react-native";
import { useNavigation } from "@react-navigation/native";

import { make, stylize } from "../lib/style";

const SAYKL_LOGO_LIGHT = require("../assets/icon-w-text-light.png");
const SAYKL_LOGO_DARK = require("../assets/icon-w-text-dark.png");

export default function SyBrandIcon() {
  const navigation = useNavigation();

  const SAYKL_LOGO = make().name === "light" ? SAYKL_LOGO_LIGHT : SAYKL_LOGO_DARK;

  return (
    <Pressable
      style={styles.container}
      onPress={() => navigation.navigate("Main", { screen: "Search" })}
    >
      <Image source={SAYKL_LOGO} style={styles.image}></Image>
    </Pressable>
  );
}

const styles = stylize({
  container: make().column().itemsCenter().ratio(2.2).set({
    overflow: "hidden",
    height: 32,
  }),

  image: make().ratio(2.2).flex(1).set({
    resizeMode: "contain",
  }),
});
