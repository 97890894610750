import { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import { formatLocation, useLocation } from "../lib/location";
import { make, stylize } from "../lib/style";
import { makeRow } from "../themes/components";
import SyButton from "./SyButton";
import SyFieldInput from "./SyFieldInput";
import SyInputText from "./SyInputText";

export default function SyFieldLocation({
  showTitle = true,
  immediate = false,
  lastKnown = false,
  location,
  changeLocation,
}) {
  const { error, loading, data, refetch } = useLocation(true, lastKnown);
  
  useEffect(() => {
    changeLocation(data);
  }, [data]);

  const updateLocation = async () => {
    await refetch();
  };

  const clearLocation = async () => {
    const empty = {
      latitude: null,
      longitude: null,
      region: null,
      subregion: null,
    };
    changeLocation(empty);
  };

  const input = (
    <View style={styles.row}>
      <SyInputText
        value={formatLocation(
          location,
          "Konum ilçe, İl şeklinde paylaşılır."
        )}
        disabled
        style={styles.input}
      ></SyInputText>
      {changeLocation && (
        <>
          <SyButton
            icon="map-marker"
            style={styles.button}
            iconStyle={styles.buttonIcon}
            onPress={updateLocation}
          ></SyButton>
          <SyButton
            icon="delete"
            style={styles.button}
            iconStyle={styles.buttonIcon}
            onPress={clearLocation}
          ></SyButton>
        </>
      )}
    </View>
  );

  if (showTitle) {
    return <SyFieldInput title="Konum">{input}</SyFieldInput>;
  } else {
    return input;
  }
}

const styles = stylize({
  row: makeRow("s").itemsCenter(),
  input: make().flex(1),
  button: make().background("primary").shadow(1),
  buttonIcon: make().color("light"),
});
