import { View } from "react-native";
import SyText from "./SyText";
import SyIcon from "./SyIcon";
import { make, stylize } from "../lib/style";
import { makeBox, makeRow } from "../themes/components";
import { Link } from "@react-navigation/native";
import { SyIconAddFriend } from "../icons/SyIconAddFriend";
import { useReactiveVar } from "@apollo/client";
import { loggedInVar } from "../queries/cache";

export default function SyProductCard() {
  const loggedIn = useReactiveVar(loggedInVar);

  const screen = loggedIn ? "NewProduct" : "Login";

  return (
    <View style={styles.help}>
      <SyText
        style={styles.contactTitle}
        content="saykl puan kazanmak ister misin?"
      ></SyText>
      <View style={styles.contact}>
        <SyIcon name="plus-circle" style={styles.contactIcon}></SyIcon>
        <SyText content="Hemen kullanmadığın bir ürün için ilan ver, talep edene ulaştır ve +1 saykl puan kazan!"></SyText>
      </View>
      <View>
        <Link style={styles.contactButton} to={{ screen }}>
          <SyText
            content="İlan ver"
            style={styles.contactButtonLabel}
          ></SyText>
        </Link>
      </View>
    </View>
  );
}

const styles = stylize({
  help: makeBox().gap("s").margin("n").flex(1).center(),
  contact: makeRow("s").itemsCenter(),
  contactTitle: make().font("l", "l", "main"),
  contactIcon: make().font("x", "m", "main"),
  contactText: make().font("m", "m", "main"),
  contactButton: make().background("transparent").shadow(0).padding("n"),
  contactButtonLabel: make().font("m", "l", "primary"),
  contactIcon:make().font("xl", "l", "primary")
});
