import { Platform } from "react-native";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import { useEffect, useRef, useState } from "react";
import { loggedInVar, notificationsVar, profileVar } from "../queries/cache";
import { useReactiveVar } from "@apollo/client";
import { useClearNotifications } from "../queries/operations/notification";
import { useIsFocused } from "@react-navigation/native";

export function initializeNotifications() {
  Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: true,
      shouldPlaySound: false,
      shouldSetBadge: false,
    }),
  });
}

export async function sendPushNotification({
  expoPushToken,
  title,
  body,
  data,
}) {
  const message = {
    to: expoPushToken,
    sound: "default",
    title,
    body,
    data,
  };

  await fetch("https://exp.host/--/api/v2/push/send", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Accept-encoding": "gzip, deflate",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(message),
  });
}

export async function registerForPushNotificationsAsync() {
  let token;
  if (Device.isDevice) {
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();

    let finalStatus = existingStatus;

    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== "granted") {
      alert("Failed to get push token for push notification!");
      return;
    }
    token = (await Notifications.getExpoPushTokenAsync()).data;
  } else {
    alert("Must use physical device for Push Notifications");
  }

  if (Platform.OS === "android") {
    Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
    });
  }

  return token;
}

export function usePushNotifications() {
  const profile = useReactiveVar(profileVar);
  const [expoPushToken, setExpoPushToken] = useState("");
  const [notification, setNotification] = useState(false);
  const notificationListener = useRef();
  const responseListener = useRef();

  const remove = useEffect(() => {
    if (profile && Platform.OS !== "web") {
      registerForPushNotificationsAsync()
        .then((token) => setExpoPushToken(token))

      notificationListener.current =
        Notifications.addNotificationReceivedListener((notification) => {
          setNotification(notification);
        });

      responseListener.current =
        Notifications.addNotificationResponseReceivedListener((response) => {});

      return () => {
        Notifications.removeNotificationSubscription(
          notificationListener.current
        );
        Notifications.removeNotificationSubscription(responseListener.current);
      };
    }
  }, []);

  return {
    expoPushToken,
    notification,
    notificationListener,
    responseListener,
    remove,
  };
}

export function clearNotifications(type) {
  const [clear] = useClearNotifications();
  const isFocused = useIsFocused();

  useEffect(() => {
    const loggedIn = loggedInVar();

    if (!isFocused || !loggedIn) {
      return;
    }

    const notifications = notificationsVar();

    let senderRequest = notifications.SenderRequest;
    let receiverRequest = notifications.ReceiverRequest;
    let userMessage = notifications.UserMessage;
    let systemMessage = notifications.SystemMessage;

    switch (type) {
      case "SenderRequest":
        senderRequest = false;
        break;
      case "ReceiverRequest":
        receiverRequest = false;
        break;
      case "UserMessage":
        userMessage = false;
        break;
      case "SystemMessage":
        systemMessage = false;
        break;
    }
    notificationsVar({
      SenderRequest: senderRequest,
      ReceiverRequest: receiverRequest,
      UserMessage: userMessage,
      SystemMessage: systemMessage,
      Chats: userMessage || systemMessage,
      Requests: senderRequest || receiverRequest,
    });

    if(type !== 'SystemMessage'){
      clear({ variables: { type } });
    }
  }, []);
}
