import { useReactiveVar } from "@apollo/client";
import { Image, ScrollView, View } from "react-native";

import { loggedInVar, profileVar } from "../queries/cache";
import { make, stylize } from "../lib/style";

import SyPage from "../widgets/SyPage";
import SyUserCard from "../widgets/SyUserCard";
import SyProfileButtons from "../widgets/SyProfileButtons";
import SySpace from "../widgets/SySpace";
import { useProfile } from "../queries/profile";

export default function ProfilePage() {
  const loggedIn = useReactiveVar(loggedInVar);
  const { data, loading, refetch } = useProfile();
  
  return (
    <SyPage
      style={styles.page}
      authRequired={true}
      showContent={true}
      loading={loading}
      data={data}
      refetch={refetch}
    >
      <ScrollView>
        {loggedIn && <SyUserCard {...data?.profile}></SyUserCard>}
        <SyProfileButtons loggedIn={loggedIn}></SyProfileButtons>
        <SySpace mode="page"></SySpace>
      </ScrollView>
    </SyPage>
  );
}

const styles = stylize({
  page: make(),
});
