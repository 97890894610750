import { Modal, ScrollView, View } from "react-native";
import { make, stylize } from "../lib/style";
import { makeBox, makeColumn } from "../themes/components";
import SyButton from "./SyButton";
import SySpinner from "./SySpinner";
import SyAlert from "./SyAlert";

export default function SyModalInput({
  visible,
  changeVisible,
  showExit = true,
  children,
  error,
  loading,
  style,
  transparent = true,
}) {
  const cancel = () => {
    changeVisible(false);
  };

  return (
    <Modal transparent={transparent} visible={visible} animationType="fade">
      <ScrollView contentContainerStyle={styles.container}>
        <View style={[styles.content, style]}>
          {error && <SyAlert error={error} />}
          {loading && <SySpinner style={styles.loading} />}
          {showExit && changeVisible && (
            <View style={styles.actions}>
              <SyButton
                icon="times"
                onPress={cancel}
                style={styles.cancel}
                iconStyle={styles.iconStyle}
              ></SyButton>
            </View>
          )}
          <View style={styles.view}>{children}</View>
        </View>
      </ScrollView>
    </Modal>
  );
}

const styles = stylize({
  container: make()
    .column()
    .flex(1)
    .itemsCenter()
    .center()
    .background("modalBackground"),
  content: makeBox()
    .width("smallModal")
    .selfCenter()
    .radius("l")
    .overflowVisible()
    .column(),
  actions: make(),
  cancel: make()
    .set({
      position: "absolute",
      left: -32,
      top: -32,
      width: 48,
      height: 48,
      borderRadius: 24,
    })
    .shadow(1),
  iconStyle: make().font("cancelIcon", "l", "primary"),
  view: makeColumn("l"),
});
