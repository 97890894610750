import { useEffect, useRef, useState } from "react";
import { Animated, Pressable, View } from "react-native";
import { Circle, G, Rect, Svg } from "react-native-svg";
import { Platform } from "react-native";
import { make, stylize } from "../lib/style";
import { notificationsVar } from "../queries/cache";
import { useReactiveVar } from "@apollo/client";
import { makeRow } from "../themes/components";

import { items } from "../lib/menu.js";
import { useScreenState } from "../lib/screen";
import SySpace from "./SySpace";

const icons = items;
const theme = make().theme;
const tabbarHeight = theme.size.tabbarHeight;
const tabbarBackground = theme.colors.light;
const primary = theme.colors.primary;
const secondary = theme.colors.secondary;
const inactive = theme.colors.lightGrey3;
const iconSize = theme.size.tabbarIconSize;
const buttonSize = theme.size.tabbarButtonSize;

export default function SyTabBar({ navigation }) {
  const { style, screenWidth, contentWidth } = useScreenState();
  const notifications = useReactiveVar(notificationsVar);
  const initialIndex = navigation.getState().index;

  return (
    <>
      <View style={[styles.container, style]}>
        {icons.map(({ route, icon, screen }, index) => {
          const hasNotification = !!notifications[screen] && index !== initialIndex;

          return (
            <Pressable
              style={styles.button}
              key={index}
              onPress={() => {
                navigation.navigate(route, { screen });
              }}
            >
              <View
                style={[
                  styles.circle,
                  index === initialIndex ? styles.active : null,
                ]}
              >
                {icon({
                  color: index === initialIndex ? theme.colors.white : inactive,
                  width: iconSize,
                  height: iconSize,
                })}
                {hasNotification && <View style={styles.notification}></View>}
              </View>

              
            </Pressable>
          );
        })}
      </View>
      <SySpace color="light"></SySpace>
    </>
  );
}

/*
export default function SyTabBar({ navigation }) {
  const { style, screenWidth, contentWidth } = useScreenState();
  const notifications = useReactiveVar(notificationsVar);
  const initialIndex = navigation.getState().index;
  const initialX = iconPosition(
    contentWidth,
    icons.length,
    buttonSize,
    initialIndex,
    initialIndex,
    buttonSize
  );

  const initialPath = generatePath(
    contentWidth,
    tabbarHeight,
    buttonSize,
    initialX
  );

  const [path, changePath] = useState(initialPath);
  const [activeButtonX, changeActiveButtonX] = useState(initialX);

  const activeX = useRef(new Animated.Value(initialX)).current;

  useEffect(() => {
    const nextX = iconPosition(
      contentWidth,
      icons.length,
      buttonSize,
      initialIndex,
      initialIndex,
      buttonSize
    );
    activeX.current = nextX;
    const path = generatePath(
      contentWidth,
      tabbarHeight,
      buttonSize,
      activeX.current
    );
    changePath(path);
    changeActiveButtonX(activeX.current);
  }, [initialIndex, activeX, contentWidth]);

  const y = (tabbarHeight - iconSize) / 2;

  return (
    <>
      <View style={[styles.container, style]}>
        <Svg width={contentWidth} height={tabbarHeight} style={styles.bar}>
          <Rect
            width={contentWidth}
            height={tabbarHeight}
            fill={tabbarBackground}
          ></Rect>
          {icons.map(({ route, icon, screen }, index) => {
            if (initialIndex === index) {
              return null;
            }

            const hasNotification = !!notifications[screen];
            const x = iconPosition(
              contentWidth,
              icons.length,
              buttonSize,
              initialIndex,
              index,
              iconSize
            );

            if (Platform.OS === "web") {
              return (
                <G
                  x={x}
                  y={y}
                  key={index}
                  onClick={() => {
                    navigation.navigate(route, { screen });
                  }}
                  width={iconSize}
                  height={iconSize}
                >
                  <Circle
                    r={iconSize}
                    cx={iconSize / 2}
                    cy={iconSize / 2}
                    fill="transparent"
                  ></Circle>
                  {hasNotification && (
                    <Circle
                      r={4}
                      cx={iconSize}
                      cy={iconSize}
                      fill={secondary}
                    ></Circle>
                  )}
                  {icon({
                    color: inactive,
                    width: iconSize,
                    height: iconSize,
                  })}
                </G>
              );
            } else {
              return (
                <G
                  x={x}
                  y={y}
                  width={iconSize / 2}
                  height={iconSize / 2}
                  key={index}
                  onPress={() => {
                    navigation.navigate(route, { screen });
                  }}
                >
                  <Circle
                    r={iconSize}
                    cx={iconSize / 2}
                    cy={iconSize / 2}
                    fill="transparent"
                  ></Circle>

                  {hasNotification && (
                    <Circle
                      r={4}
                      cx={iconSize}
                      cy={iconSize}
                      fill={secondary}
                    ></Circle>
                  )}

                  <G>
                    {icon({
                      color: inactive,
                      width: iconSize,
                      height: iconSize,
                    })}
                  </G>
                </G>
              );
            }
          })}
        </Svg>

        <SyActiveButton
          x={activeButtonX}
          y={0}
          radius={buttonSize}
          iconSize={iconSize}
          index={initialIndex}
        ></SyActiveButton>
      </View>
      <SySpace color="light"></SySpace>
    </>
  );
}*/

function SyActiveButton({ x, y, radius, iconSize, index }) {
  return (
    <Svg
      width={iconSize * 2}
      height={iconSize * 2}
      style={[
        styles.primary,
        {
          left: x,
          top: -iconSize / 2,
          transform: [{ translateX: -iconSize }],
        },
      ]}
    >
      <Circle
        cx={iconSize}
        cy={iconSize}
        r={iconSize}
        fill={theme.colors.primary}
      ></Circle>
      <G x={iconSize / 2} y={iconSize / 2}>
        {icons[index].icon({
          color: theme.colors.white,
          width: iconSize,
          height: iconSize,
        })}
      </G>
    </Svg>
  );
}

function generatePath(width, height, radius, x) {
  return `M 0 0 L 0 ${height} L ${width} ${height} L ${width} 0 L ${
    x + radius
  } 0 A ${radius} ${radius} 0 0 1 ${x} ${radius} A ${radius} ${radius} 0 0 1 ${
    x - radius
  } 0 L 0 0 z`;
}

function iconPosition(
  width,
  totalCount,
  buttonSize,
  currentIndex,
  index,
  iconWidth
) {
  const cellWidth = width / totalCount;
  const position = cellWidth * index;

  if (currentIndex === index) {
    return position + cellWidth / 2;
  }
  return position - iconSize / 2 + cellWidth / 2;
}

const styles = stylize({
  container: makeRow()
    .end()
    .background("light")
    .set((theme) => ({
      height: tabbarHeight,
    }))
    .spaceAround(),
  primary: make().set({
    position: "absolute",
    zIndex: 1000,
    fill: primary,
  }),
  button: make()
    .flex(1)
    .itemsCenter()
    .center()
    .set({
      width: buttonSize,
      borderRadius: buttonSize / 2,
    }),
  circle: make()
    .center()
    .itemsCenter()
    .set({
      height: buttonSize,
      width: buttonSize,
      borderRadius: buttonSize / 2,
    }),
  active: make().set({
    height: iconSize * 2,
    width: iconSize * 2,
    borderRadius: iconSize,
    backgroundColor: primary,
    marginBottom: 16,
  }),
  notification: make()
    .set({
      position:'absolute',
      right:0,
      bottom:0,
      width: 8,
      height: 8,
      borderRadius: 4,
    })
    .background("secondary"),
});
