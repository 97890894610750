import { useNavigation } from "@react-navigation/native";
import { Image, Pressable, View } from "react-native";
import { make, stylize } from "../lib/style";
import { formatDateTime, profileVar } from "../queries/cache";
import { useDeleteChat } from "../queries/chat";
import { makeListItem } from "../themes/components";
import SyButton from "./SyButton";
import SyIcon from "./SyIcon";
import SyText from "./SyText";
import SyUserImage from "./SyUserImage";

export default function SyChatListItem({
  id,
  updatedAt,
  product,
  sender,
  receiver,
  isSeen,
  border = true,
  showAvatar = true,
}) {
  const profile = profileVar();
  const navigation = useNavigation();
  const [deleteChat] = useDeleteChat();
  let other;

  if (profile.id === sender?.id) {
    other = receiver;
  } else {
    other = sender;
  }

  const containerStyle = [styles.container];

  if (!border) {
    containerStyle.push(styles.noBorder);
  }

  if (!isSeen) {
    containerStyle.push(styles.newMessage);
  }

  const navigate = () => {
    if (border) {
      navigation.navigate("ChatDetail", { id });
    } else {
      navigation.navigate("UserDetail", { id: other.id });
    }
  };

  const remove = async () => {
    await deleteChat({
      variables: { id },
      update(cache) {
        const normalizedId = cache.identify({ id, __typename: "Chat" });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
    navigation.navigate("Main", { screen: "Chats" });
  };

  const datetime = formatDateTime(new Date(updatedAt));

  return (
    <Pressable onPress={navigate} style={containerStyle}>
      <View style={styles.imageContainer}>
        <Image
          source={{ uri: product?.thumbnail }}
          style={styles.image}
        ></Image>
      </View>
      <View style={styles.content}>
        <View style={styles.header}>
          <SyText
            content={other?.name}
            style={styles.userName}
            lines={1}
          ></SyText>
          {border && (
            <SyText content={datetime} style={styles.date}></SyText>
          )}
          {!border && <SyButton icon="delete" onPress={remove}></SyButton>}
        </View>
        <View style={styles.footer}>
          <SyText content={product?.name} lines={1}></SyText>
          {!isSeen && (
            <SyIcon name="circle" style={styles.newMessageIcon}></SyIcon>
          )}
        </View>
      </View>
    </Pressable>
  );
}

const listItem = makeListItem();

listItem.header.spaceBetween();
listItem.container.margin("s");
listItem.content.center();

const styles = stylize({
  ...listItem,
  noBorder: make()
    .shadow(0)
    .margin("n")
    .radius("n")
    .padding("s")
    .set({
      borderBottomWidth: 1,
    })
    .borderColor("borderLight"),
  userImage: make().set((theme) => ({
    position: "absolute",
    bottom: -6,
    right: -6,
  })),
  userName: make().font("l", "l"),
  date: make().font("m"),
  count: make()
    .font("l")
    .set((theme) => ({
      textAlign: "center",
      position: "absolute",
      bottom: 0,
      right: 0,
      width: 24,
      height: 24,
      borderRadius: 12,
      backgroundColor: theme.colors.primary,
      color: theme.colors.light,
    })),
  footer: make().row().spaceBetween().itemsCenter(),
  newMessage: make().background("mint"),
  newMessageIcon: make().font("l", "m", "primary"),
});
