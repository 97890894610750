import { useEffect, useState } from "react";
import { Modal, ScrollView, View } from "react-native";
import { useNavigation } from "@react-navigation/native";

import { profileVar } from "../queries/cache";
import { useProductSave } from "../queries/product";
import { make, stylize } from "../lib/style";
import { makeRow } from "../themes/components";
import {
  CATEGORIES_DEFAULT_FORM,
  CONDITIONS_DEFAULT_FORM,
  CONDITIONS_STATES,
  findCondition,
  findShipmentType,
  // ---------------------------------
  findLocationType,
  LOCATION_TYPES,
  LOCATION_TYPES_DEFAULT_FORM,
  // ---------------------------------
  SHIPMENT_TYPES,
  SHIPMENT_TYPES_DEFAULT_FORM,
} from "../lib/constants";

import SyCarousel from "./SyCarousel";
import SyInputText from "./SyInputText";
import SyPage from "./SyPage";
import SyTitle from "./SyTitle";
import SyButtonSelection from "./SyButtonSelection";
import SyModalCategory from "./SyModalCategory";
import SyModalSelection from "./SyModalSelection";
// ---------------------------------
import { formatLocation, useLocation } from "../lib/location";
// ---------------------------------
import { processState } from "../lib/graphql";
import { useReactiveVar } from "@apollo/client";
import SyButton from "./SyButton";
import SySwitch from "./SySwitch";
import SyText from "./SyText";

// ---------------------------------
function getLocationType(type, profileLocation, productLocation) {
  let name;
  switch (type) {
    case "Profile":
      name = formatLocation(profileLocation);
      break;
    case "Current":
      name = formatLocation(productLocation);
      break;
  }

  if (!name) {
    return findLocationType(type);
  }

  return {
    id: type,
    name,
  };
}

function getLocation(
  type,
  profileLocation,
  newProduct,
  gpsLocation,
  productLocation
) {
  switch (type) {
    case "Profile":
      return profileLocation;
    case "Current":
      return getCurrentLocation(newProduct, gpsLocation, productLocation);
  }
}

function getCurrentLocation(newProduct, gpsLocation, productLocation) {
  if (newProduct) {
    return gpsLocation.data;
  }

  return productLocation;
}

function generateLocationTypeList(profile, location) {
  return LOCATION_TYPES.map((t) => getLocationType(t.id, profile, location));
}

export default function SyProductForm({
  error,
  loading,
  id,
  shortId,
  name = "",
  description = "",
  category = "",
  shipmentType,
  locationType,
  location,
  newProduct = false,
  condition,
  sayklPoints = 1,
  images = [],
}) {
  const profile = useReactiveVar(profileVar);
  const navigation = useNavigation();
  const [newName, changeName] = useState(name);
  const [newDescription, changeDescription] = useState(description);
  const [newImages, changeImages] = useState(images);
  const [newCategory, changeCategory] = useState(category);

  const [newShipmentType, changeShipmentType] = useState(
    findShipmentType(shipmentType, newProduct)
  );
  const [newCondition, changeCondition] = useState(findCondition(condition));

  const [categoryModalVisible, changeCategoryModalVisible] = useState(false);
  const [shipmentTypeModalVisible, changeShipmentTypeModalVisible] =
    useState(false);
  const [conditionModalVisible, changeConditionModalVisible] = useState(false);
  const [saveProduct, saveResponse] = useProductSave();

  const [newSayklPoints, changeNewSayklPoints] = useState(!sayklPoints);

  // ---------------------------------
  const [locationTypeModalVisible, changeLocationTypeModalVisible] =
    useState(false);
  // ---------------------------------

  const updateSayklPoints = (val) => {
    changeNewSayklPoints(val);
  };

  const ACTIONS = [
    {
      label: "Kaydet",
      authRequired: true,
      onPress: () => {
        let location = null;

        if (profile.location) {
          location = {
            latitude: profile.location.latitude,
            longitude: profile.location.longitude,
            region: profile.location.region,
            subregion: profile.location.subregion,
          };
        }

        saveProduct({
          variables: {
            id: id,
            shortId: shortId,
            name: newName,
            description: newDescription,
            categoryId: newCategory?.id,
            condition: newCondition?.id,
            shipmentType:
              newShipmentType?.id === "Any" ? null : newShipmentType?.id,
            locationType: "Profile",
            location,
            sayklPoints: newSayklPoints ? 0 : 1,
            images: newImages,
          },
        });
      },
    },
    {
      label: "İptal",
      authRequired: true,
      onPress: () => navigation.replace("Main", { screen: "Products" }),
    },
  ];

  const state = processState(saveResponse, { error, loading });

  return (
    <SyPage graphql={state} error={error} loading={loading} actions={ACTIONS}>
      <ScrollView>
        <SyCarousel
          images={newImages}
          changeImages={changeImages}
          showBadge={newSayklPoints}
        ></SyCarousel>
        <View style={styles.info}>
          <View style={styles.priceContainer}>
            <View style={styles.priceText}>
              <SyTitle
                content="Ürün karşılığında saykl puan talebim yok."
                noCaps={true}
              ></SyTitle>
              <SyText content="(Karşılıksız verilir, saykl puan kazanamazsın.)"></SyText>
            </View>
            <SySwitch
              value={newSayklPoints}
              changeValue={updateSayklPoints}
            ></SySwitch>
          </View>
          <View>
            <SyTitle content="Ürün Adı"></SyTitle>
            <SyInputText
              value={newName}
              onChangeText={changeName}
              placeholder="Ürün Adı"
            ></SyInputText>
          </View>
          <View>
            <SyTitle content="Açıklama"></SyTitle>
            <SyInputText
              value={newDescription}
              onChangeText={changeDescription}
              multiline={true}
              lines={6}
              placeholder="Açıklama"
              style={styles.description}
            ></SyInputText>
          </View>
          <View style={styles.row}>
            <View style={styles.half}>
              <SyTitle content="Kategori"></SyTitle>
              <SyButtonSelection
                item={newCategory}
                defaultState={CATEGORIES_DEFAULT_FORM}
                changeVisible={changeCategoryModalVisible}
                noSelectionError={true}
                style={styles.selectionButton}
              ></SyButtonSelection>
            </View>

            <View style={styles.half}>
              <SyTitle content="Durumu"></SyTitle>
              <SyButtonSelection
                item={newCondition}
                defaultState={CONDITIONS_DEFAULT_FORM}
                changeVisible={changeConditionModalVisible}
                noSelectionError={true}
                style={styles.selectionButton}
              ></SyButtonSelection>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.half}>
              <SyTitle content="Teslimat"></SyTitle>
              <SyButtonSelection
                item={newShipmentType}
                defaultState={SHIPMENT_TYPES_DEFAULT_FORM}
                changeVisible={changeShipmentTypeModalVisible}
                noSelectionError={true}
                style={styles.selectionButton}
              ></SyButtonSelection>
            </View>
            <View style={styles.half}>
              <SyTitle content="Konum"></SyTitle>
              <SyButton
                label={formatLocation(profile?.location, "Bilinmiyor")}
                style={styles.selectionButton}
              ></SyButton>
            </View>
          </View>
        </View>
      </ScrollView>
      <SyModalCategory
        visible={categoryModalVisible}
        changeVisible={changeCategoryModalVisible}
        changeCategory={changeCategory}
        showAll={false}
      ></SyModalCategory>
      <SyModalSelection
        items={SHIPMENT_TYPES}
        visible={shipmentTypeModalVisible}
        changeVisible={changeShipmentTypeModalVisible}
        changeItem={changeShipmentType}
      ></SyModalSelection>
      <SyModalSelection
        items={CONDITIONS_STATES}
        visible={conditionModalVisible}
        changeVisible={changeConditionModalVisible}
        changeItem={changeCondition}
      ></SyModalSelection>
    </SyPage>
  );
}

const styles = stylize({
  info: make().padding("m").gap("m"),
  description: make().set({
    height: 200,
  }),
  row: makeRow("m"),
  half: make().flex(1),
  selectionButton: make().itemsStart().start().flex(1),
  priceContainer: makeRow().itemsCenter(),
  priceText: make().flex(),
});

/*<View style={styles.half}>
  <SyTitle content="Konum"></SyTitle>
  <SyButtonSelection
    item={newLocationType}
    defaultState={LOCATION_TYPES_DEFAULT_FORM}
    changeVisible={changeLocationTypeModalVisible}
    noSelectionError={true}
    style={styles.selectionButton}
  ></SyButtonSelection>
</View>; 
*/
