import { make } from "../lib/style";

export function makeBox(shadow = true) {
  const style = make()
    .padding("m")
    .radius("m")
    .background("light")
    .overflowHidden();

  if (shadow) {
    style.shadow(1);
  }

  return style;
}

export function makeError() {
  return make()
    .row()
    .center()
    .itemsCenter()
    .fullWidth()
    .autoHeight()
    .radius("m")
    .padding("l")
    .set({
      position: "absolute",
      top: 48,
      left: 0,
      zIndex:999
    });
}

export function makeListItem() {
  return {
    container: makeBox().row(),
    imageContainer: make()
      .width("thumbnail")
      .ratio(1)
      .radius("s")
      .overflowVisible(),
    image: make().flex(1).radius("m"),
    content: make()
      .flex(1)
      .set((theme) => ({
        marginLeft: theme.size.m,
      })),
    header: make().row(),
    title: make().font("l", "l"),
  };
}

export function makeRow(gap) {
  return make().row().gap(gap);
}

export function makeColumn(gap, horizontalAlign, verticalAlign) {
  return make().column().gap(gap);
}
