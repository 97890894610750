import { View } from "react-native";
import SyPageModal from "./SyPageModal";
import SyBrandSlogan from "./SyBrandSlogan";
import SyTitle from "./SyTitle";
import SyButton from "./SyButton";
import { make, stylize } from "../lib/style";
import { makeColumn } from "../themes/components";
import SyText from "./SyText";
import { useNavigation } from "@react-navigation/native";

export default function SySignupSuccess() {
  const navigation = useNavigation();
  return (
    <SyPageModal authRequired={false} showExit={false}>
      <View style={styles.modalContent}>
        <SyBrandSlogan></SyBrandSlogan>
        <SyText style={styles.successMessage} content="Daha iyi bir kullanıcı deneyimi için profil bilgilerini güncelle."></SyText>
      </View>
      <SyButton
        style={styles.successButton}
        labelStyle={styles.successButtonLabel}
        onPress={() => navigation.replace("UpdateProfile")}
        label="Profile Git"
      ></SyButton>
      <SyButton
        style={styles.successButton}
        labelStyle={styles.successButtonLabel}
        onPress={() => navigation.replace("Main", { screen: "Search" })}
        label="İlanlara Git"
      ></SyButton>
      <SyText style={styles.successMessage} content="Unutma, saykl'da satış ya da eski usul takas yok, tüm ürünler saykl puan ile alınır."></SyText>
    </SyPageModal>
  );
}

const styles = stylize({
  modalContent: makeColumn().padding("m").itemsCenter().gap("s"),
  successMessage: make().textCenter(),
  successButton: make().background("primary"),
  successButtonLabel: make().font("m", "l", "light"),
});
