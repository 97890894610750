import { View } from "react-native";
import { make, stylize } from "../lib/style";
import { makeColumn } from "../themes/components";
import SyText from "./SyText";

export default function SyHeading({ title, subtitle }) {
  return (
    <View style={styles.header}>
      <SyText style={styles.title} content={title}></SyText>
      <SyText style={styles.subtitle} content={subtitle}></SyText>
    </View>
  );
}
const styles = stylize({
  header: makeColumn().gap('n'),
  title: make().font("l", "l"),
  subtitle: make().font("m", "m", "lightGrey2"),
});
