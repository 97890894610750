import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { make } from "../lib/style";

import FavoritesPage from "../pages/FavoritesPage";
import ProductsPage from "../pages/ProductsPage";

const Tab = createMaterialTopTabNavigator();

export default function DashboardLayout() {
  return (
    <Tab.Navigator screenOptions={screenOptions} id="Dashboard">
      <Tab.Screen
        name="Products"
        component={ProductsPage}
        options={options.products}
      ></Tab.Screen>
      <Tab.Screen
        name="Favorites"
        component={FavoritesPage}
        options={options.favorites}
      ></Tab.Screen>
    </Tab.Navigator>
  );
}
const colors = make().theme.colors;
const screenOptions = {
  tabBarIndicatorStyle: make().background("primary").style,
  tabBarLabelStyle: make().capitalize().color("main").style,
  tabBarStyle: {
    backgroundColor: colors.headerColor,
    borderBottomColor: colors.dark,
  },
};

const options = {
  products: {
    title: "İlanlarım",
    icon: "list",
  },
  favorites: {
    title: "Favorilerim",
    icon: "star",
  },
};
