import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
  split,
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { getToken } from "../storage/token";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { loggedInVar, cache, errorVar } from "../queries/cache";
import { useEffect } from "react";
import Constants from "expo-constants";

const PROTOCOL = Constants.expoConfig.extra.SAYKL_API_PROTOCOL;
const HOST = Constants.expoConfig.extra.SAYKL_API_HOST;

const httpLink = createHttpLink({
  uri: PROTOCOL + "://" + HOST + "/graphql",
});

const wsLink = new GraphQLWsLink(
  
  createClient({
    url:
      (PROTOCOL === "https" ? "wss" : "ws") + "://" + HOST + "/subscriptions",
    connectionParams: async () => {
      const token = await getToken();

      //console.log("socket", token);

      if (!token) {
        return {};
      }

      return {
        authorization: token,
      };
    },
    on: {
      connected(){
        //console.log('connected',arguments);
      },
      closed(){
        //console.log('closed',arguments);
      },
      error(){
        //console.log('error',arguments);
      }
    }
  })
);

const errorLink = onError(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors) {
    const index = graphQLErrors.findIndex(
      (err) => err.message == "İzin yok" || err.message == "Bulunamadı"
    );
    if (index === -1) {
      errorVar(graphQLErrors);
    }
  }
  if (networkError) {
  }
});

const authLink = setContext(async (_, { headers }) => {
  const token = await getToken();
  loggedInVar(!!token);

  //console.log("api", token);

  if (token) {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  }
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  from([authLink, errorLink, httpLink])
);

export const graphqlClient = new ApolloClient({
  link: splitLink,
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      errorPolicy: "none",
    },
    mutate: {
      errorPolicy: "none",
    },
  },
  cache: new InMemoryCache(cache),
});

export function gQuery(query, options, manual = false) {
  if (manual) {
    const ret = useLazyQuery(query, {
      //fetchPolicy: "cache-and-network",
      ...options,
    });

    return ret;
  }

  const ret = useQuery(query, {
    //fetchPolicy: "cache-and-network",
    ...options,
  });

  return ret;
}

export function gMutation(query, options) {
  const ret = useMutation(query, {
    ...options,
  });
  return ret;
}

export function gSubscription(query, options) {
  const ret = useSubscription(query, {
    ...options,
  });

  return ret;
}

export function localQuery(query, options) {
  return graphqlClient.readQuery({ query, ...options });
}

export function localMutation(query, options) {
  return graphqlClient.writeQuery({ query, ...options });
}

export function clearCache() {
  graphqlClient.resetStore();
}

export function useAutoRedraw(data) {
  useEffect(empty, [data]);
}

export function processState(...args) {
  return args.reduce(({ loading, error, data }, current) => {
    return {
      loading: loading || current.loading,
      error: error || current.error,
    };
  });
}
