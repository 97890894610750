import { Image, Pressable, TextInput, View } from "react-native";
import { make, stylize } from "../lib/style";
import { makeBox, makeColumn, makeRow } from "../themes/components";
import SyPage from "../widgets/SyPage";
import SyText from "../widgets/SyText";
import SyModalInput from "../widgets/SyModalInput";
import { useRef, useState } from "react";
import SyFieldInput from "../widgets/SyFieldInput";
import SyInputText from "../widgets/SyInputText";
import SyButton from "../widgets/SyButton";
import {
  useCreatePayment,
  useStartPayment,
} from "../queries/operations/transaction";
import { useNavigation } from "@react-navigation/native";
import SyTitle from "../widgets/SyTitle";
import { SyIconHAppyHat } from "../icons/SyIconHappyHat";
import RenderHtml, {
  HTMLContentModel,
  HTMLElementModel,
} from "react-native-render-html";
import SyInformatique from "../widgets/SyInformatique";
import { useConvertSayklPoint } from "../queries/user";
import Constants from "expo-constants";

const VM = require("../assets/visa-mastercard.png");
const IP = require("../assets/iyzipay.png");
const COIN_1_LIGHT = require("../assets/images/coin-1.png");
const COIN_5_LIGHT = require("../assets/images/coin-5.png");
const COIN_10_LIGHT = require("../assets/images/coin-10.png");
const COIN_1_DARK = require("../assets/images/coin-1-d.png");
const COIN_5_DARK = require("../assets/images/coin-5-d.png");
const COIN_10_DARK = require("../assets/images/coin-10-d.png");
const INFO_IMAGE = require("../assets/info.png");

const SAYKL_CHIP = require("../assets/images/saykl-chip.png");
const SHIPMENT_CHIP = require("../assets/images/shipment-chip.png");
const PAZARAMA = require("../assets/images/pazarama-icon.png");
const PAZARAMA_TEXT = require("../assets/images/pazarama-text.png");

const PROTOCOL = Constants.expoConfig.extra.SAYKL_API_PROTOCOL;
const HOST = Constants.expoConfig.extra.SAYKL_API_HOST;
const PURCHASE_URL = PROTOCOL + "://" + HOST + "/odeme/3ds";
const customHTMLElementModels = {
  form: HTMLElementModel.fromCustomModel({
    tagName: "form",
    contentModel: HTMLContentModel.block,
  }),
};

function SySayklAmount({ amount, price, discount, showPayment }) {
  let src = 0;
  let theme = make().name;
  let title = amount + " saykl puan";

  if (theme === "light") {
    switch (amount) {
      case "1":
        src = SAYKL_CHIP;
        break;
      case "5":
        src = COIN_5_LIGHT;
        break;
      case "10":
        src = COIN_10_LIGHT;
        break;
      case "shipment":
        src = SHIPMENT_CHIP;
        title = "1 kargo hakkı";
        break;
      case "pazarama":
        src = PAZARAMA;
        title = "200TL Pazarama hediye çeki";
        break;
      default:
        src = COIN_1_LIGHT;
        break;
    }
  } else {
    switch (amount) {
      case "1":
        src = SAYKL_CHIP;
        break;
      case "5":
        src = COIN_5_DARK;
        break;
      case "10":
        src = COIN_10_DARK;
        break;
      case "shipment":
        src = SHIPMENT_CHIP;
        title = "1 kargo hakkı";
        break;
      case "pazarama":
        src = PAZARAMA;
        title = "200TL Pazarama hediye çeki";
        break;
      default:
        src = COIN_1_DARK;
        break;
    }
  }

  const triggerPayment = () => {
    showPayment(amount, price);
  };

  return (
    <Pressable style={styles.amountBox} onPress={triggerPayment}>
      <View style={styles.amountImageContainer}>
        <Image source={src} style={styles.amountImage}></Image>
      </View>
      <View style={styles.amountTextContainer}>
        <View style={styles.amountHeader}>
          <SyText
            content={discount ? "-%" + discount : ""}
            style={styles.discount}
          ></SyText>
        </View>

        <View style={styles.amountFooter}>
          <SyText content={title} style={styles.amount}></SyText>

          <SyText content={price} style={styles.price}></SyText>
        </View>
      </View>
    </Pressable>
  );
}

function removeHtmlComments(inputString) {
  // Regular expression to match HTML comments <!-- ... -->
  var commentRegex = /<!--[\s\S]*?-->/g;

  // Remove HTML comments from the input string
  var stringWithoutComments = inputString.replace(commentRegex, "");

  return stringWithoutComments;
}

function SyPaymentModal({ visible, changeVisible, price, amount, showThreeD }) {
  const [startPayment] = useStartPayment();
  const [name, changeName] = useState("");
  const cardNumber1Ref = useRef();
  const cardNumber2Ref = useRef();
  const cardNumber3Ref = useRef();
  const cardNumber4Ref = useRef();
  const [cardNumber1, changeCardNumber1] = useState("");
  const [cardNumber2, changeCardNumber2] = useState("");
  const [cardNumber3, changeCardNumber3] = useState("");
  const [cardNumber4, changeCardNumber4] = useState("");
  const [cardExp1, changeCardExp1] = useState("");
  const [cardExp2, changeCardExp2] = useState("");
  const [cardCvc, changeCardCvc] = useState("");

  const updateCardNumber1 = (value) => {
    if (value.length <= 4) {
      changeCardNumber1(value);
    }
    if (value.length >= 4) {
      cardNumber2Ref.current.focus();
    }
  };

  const updateCardNumber2 = (value) => {
    if (value.length <= 4) {
      changeCardNumber2(value);
    }
    if (value.length >= 4) {
      cardNumber3Ref.current.focus();
    }
  };

  const updateCardNumber3 = (value) => {
    if (value.length <= 4) {
      changeCardNumber3(value);
    }

    if (value.length >= 4) {
      cardNumber4Ref.current.focus();
    }
  };

  const updateCardNumber4 = (value) => {
    if (value.length <= 4) {
      changeCardNumber4(value);
    }
  };

  const initializePayment = async () => {
    const cardNumber = cardNumber1 + cardNumber2 + cardNumber3 + cardNumber4;
    const cardExpiration = cardExp1 + "/" + cardExp2;
    const response = await startPayment({
      variables: {
        name: name,
        cardNumber: cardNumber,
        cardExpiration: cardExpiration,
        cardCvc: cardCvc,
        amount: amount,

        /*name: "asd asd",
        cardNumber: "4603450000000000",
        cardExpiration: "02/26",
        cardCvc: "123",
        amount: amount,*/
      },
    });

    if (!response.error) {
      changeVisible(false);

      //let html = atob(response.data.startPayment);
      //const doc = new DOMParser().parseFromString(html, "text/html");
      const content = response.data.startPayment;

      const threeDS = window.open(
        PURCHASE_URL + "?content=" + encodeURIComponent(content),
        ""
      );

      /*const parent = threeDS.document.body.parentNode;
      
      parent.childNodes[0].remove();
      parent.childNodes[0].remove();
      parent.appendChild(doc.head);
      parent.appendChild(doc.body);*/

      /*doc.html.childNodes.forEach((c) => threeDS.document.head.appendChild(c));
      doc.body.childNodes.forEach((c) => threeDS.document.body.appendChild(c));*/
    }
  };

  return (
    <SyModalInput visible={visible} changeVisible={changeVisible}>
      <View style={styles.payment}>
        <SyText content="Ödenecek Tutar:" style={styles.paymentTitle}></SyText>
        <SyText content={price} style={styles.paymentPrice}></SyText>
      </View>
      <View style={styles.inputs}>
        <SyFieldInput title="Ad Soyad">
          <SyInputText
            placeholder="Ad Soyad"
            inputMode="text"
            value={name}
            onChangeText={changeName}
          ></SyInputText>
        </SyFieldInput>
        <SyFieldInput title="Kart Numarası">
          <View style={styles.inputRow}>
            <SyInputText
              ref={cardNumber1Ref}
              value={cardNumber1}
              inputMode="numeric"
              keyboardType="number-pad"
              maxLength={4}
              onChangeText={updateCardNumber1}
            ></SyInputText>
            <SyInputText
              ref={cardNumber2Ref}
              value={cardNumber2}
              inputMode="numeric"
              keyboardType="number-pad"
              maxLength={4}
              onChangeText={updateCardNumber2}
            ></SyInputText>
            <SyInputText
              ref={cardNumber3Ref}
              value={cardNumber3}
              inputMode="numeric"
              keyboardType="number-pad"
              maxLength={4}
              onChangeText={updateCardNumber3}
            ></SyInputText>
            <SyInputText
              ref={cardNumber4Ref}
              value={cardNumber4}
              inputMode="numeric"
              keyboardType="number-pad"
              maxLength={4}
              onChangeText={updateCardNumber4}
            ></SyInputText>
          </View>
        </SyFieldInput>
        <View style={styles.fieldRow}>
          <SyFieldInput title="Geçerlilik Tarihi" style={styles.fieldHalf}>
            <View style={styles.inputRow}>
              <SyInputText
                style={styles.inputSection}
                placeholder="Ay"
                maxLength={2}
                inputMode="numeric"
                keyboardType="number-pad"
                value={cardExp1}
                onChangeText={changeCardExp1}
              ></SyInputText>
              <SyInputText
                style={styles.inputSection}
                placeholder="Yıl"
                value={cardExp2}
                inputMode="numeric"
                keyboardType="number-pad"
                maxLength={4}
                onChangeText={changeCardExp2}
              ></SyInputText>
            </View>
          </SyFieldInput>
          <SyFieldInput title="Güvenlik Kodu" style={styles.fieldHalf}>
            <SyInputText
              style={styles.inputSection}
              placeholder="123"
              maxLength={3}
              inputMode="numeric"
              keyboardType="number-pad"
              value={cardCvc}
              onChangeText={changeCardCvc}
            ></SyInputText>
          </SyFieldInput>
        </View>
        <View style={styles.ipContainer}>
          <Image source={IP} style={styles.ip}></Image>
        </View>
        <SyButton
          label="Ödeme Yap"
          style={styles.loginButton}
          labelStyle={styles.loginButtonLabel}
          onPress={initializePayment}
        ></SyButton>
      </View>
    </SyModalInput>
  );
}

function SyPazaramaModal({
  visible,
  changeVisible,
  changePazaramaSuccessVisible,
  changePazaramaCode,
}) {
  const [convertSayklPoint] = useConvertSayklPoint();

  const convert = async () => {
    const res = await convertSayklPoint();

    if (!res.errors && !res.error) {
      changePazaramaCode(res.data.convertSayklPoint);
      changeVisible(false);
      changePazaramaSuccessVisible(true);
    }
  };

  return (
    <SyModalInput visible={visible} changeVisible={changeVisible}>
      <View>
        <SyText
          content="1 saykl puan dönüştürüyorsun"
          style={styles.pazaramaTitle}
        ></SyText>
      </View>
      <View style={styles.ipContainer}>
        <Image source={PAZARAMA_TEXT} style={styles.ip}></Image>
      </View>
      <SyText
        content="1.000TL üzeri alışverişlerde kullanılmak üzere 200TL Pazarama hediye çeki"
        style={styles.pazaramaText}
      ></SyText>
      <SyButton
        label="Dönüştür"
        style={styles.loginButton}
        labelStyle={styles.loginButtonLabel}
        onPress={convert}
      ></SyButton>
    </SyModalInput>
  );
}

function SyPazaramaSuccessModal({ visible, changeVisible, pazaramaCode }) {
  const close = async () => {
    changeVisible(false);
  };

  return (
    <SyModalInput visible={visible} changeVisible={changeVisible}>
      <View>
        <View style={styles.modalContent}>
          <SyIconHAppyHat
            width={128}
            height={128}
            fill={colors.blank}
          ></SyIconHAppyHat>
          <SyTitle content="Tebrikler!"></SyTitle>
          <View style={styles.modalTextGroup}>
            <SyText content="Hediye çekin hazır:"></SyText>
            <SyTitle content={pazaramaCode}></SyTitle>
            <SyText content="e-posta ile paylaşıldı."></SyText>
          </View>
        </View>

        <SyButton
          label="Kapat"
          style={styles.loginButton}
          labelStyle={styles.loginButtonLabel}
          onPress={close}
        ></SyButton>
      </View>
    </SyModalInput>
  );
}

function SyPaymentSuccessModal({ visible, changeVisible, amount }) {
  const navigation = useNavigation();

  const gotoProfile = () => {
    navigation.navigate("Profile");
  };

  return (
    <SyModalInput
      visible={visible}
      changeVisible={changeVisible}
      showExit={false}
    >
      <View>
        <View style={styles.modalContent}>
          <SyIconHAppyHat
            width={128}
            height={128}
            fill={colors.blank}
          ></SyIconHAppyHat>
          <SyTitle content="Tebrikler!"></SyTitle>
          <View style={styles.modalTextGroup}>
            <SyText content={"Ödeme başarıyla alındı."}></SyText>
            <SyText
              content={amount + " saykl puan hesabına aktarıldı."}
            ></SyText>
          </View>
        </View>

        <SyButton
          label="Profile Git"
          style={styles.loginButton}
          labelStyle={styles.loginButtonLabel}
          onPress={gotoProfile}
        ></SyButton>
      </View>
    </SyModalInput>
  );
}

function SyThreeDModal({ visible, changeVisible, html }) {
  return (
    <SyModalInput
      visible={visible}
      changeVisible={changeVisible}
      showExit={false}
    >
      <View style={styles.modalContent}>
        <RenderHtml
          source={{ html: html }}
          contentWidth={300}
          customHTMLElementModels={customHTMLElementModels}
        ></RenderHtml>
      </View>
    </SyModalInput>
  );
}

export default function PurchasePage() {
  const [price, changePrice] = useState("");
  const [amount, changeAmount] = useState("");
  const [paymentVisible, changePaymentVisible] = useState(false);

  const [paymentSuccessVisible, changePaymentSuccessVisible] = useState(false);
  const [paymentThreeDVisible, changePaymentThreeDVisible] = useState(false);
  const [paymentThreeDHtml, changePaymentThreeDHtml] = useState("");
  const [pazaramaVisible, changePazaramaVisible] = useState(false);
  const [pazaramaSuccessVisible, changePazaramaSuccessVisible] =
    useState(false);
  const [pazaramaCode, changePazaramaCode] = useState("");

  const showPayment = (amount, price) => {
    if (amount === "pazarama") {
      changePazaramaVisible(true);
      return;
    }
    changePrice(price);
    changeAmount(amount);
    changePaymentVisible(true);
  };

  const showThreeD = (html) => {
    console.log(html);
    changePaymentThreeDHtml(html);
    changePaymentThreeDVisible(true);
  };

  return (
    <>
      <SyInformatique
        image={INFO_IMAGE}
        text="3D Secure ödeme işlemi gerçekleşirken tarayıcının pop-up izinlerinin açık olduğundan emin ol."
      ></SyInformatique>
      <View>
        <View style={styles.amountContainer}>
          <SySayklAmount
            amount="1"
            price="149,90 TL"
            showPayment={showPayment}
          ></SySayklAmount>
          {/*<SySayklAmount
            amount="shipment"
            price="89,90 TL"
            showPayment={showPayment}
  ></SySayklAmount>*/}
          <SySayklAmount
            amount="pazarama"
            price=""
            showPayment={showPayment}
          ></SySayklAmount>
          {/*<SySayklAmount
            amount="5"
            discount="8"
            price="689,90 TL"
            showPayment={showPayment}
  ></SySayklAmount>*/}

          {/*<SySayklAmount
            amount="10"
            discount="10"
            price="808,90 TL"
            showPayment={showPayment}
  ></SySayklAmount>*/}
        </View>
        <View style={styles.vmContainer}>
          <Image source={VM} style={styles.vm}></Image>
        </View>
      </View>
      <SyPaymentModal
        price={price}
        amount={amount}
        visible={paymentVisible}
        changeVisible={changePaymentVisible}
        showThreeD={showThreeD}
      ></SyPaymentModal>
      <SyPazaramaModal
        visible={pazaramaVisible}
        changeVisible={changePazaramaVisible}
        changePazaramaSuccessVisible={changePazaramaSuccessVisible}
        changePazaramaCode={changePazaramaCode}
      ></SyPazaramaModal>
      <SyPaymentSuccessModal
        amount={amount}
        visible={paymentSuccessVisible}
        changeVisible={changePaymentSuccessVisible}
      ></SyPaymentSuccessModal>
      <SyPazaramaSuccessModal
        visible={pazaramaSuccessVisible}
        changeVisible={changePazaramaSuccessVisible}
        pazaramaCode={pazaramaCode}
      ></SyPazaramaSuccessModal>
      <SyThreeDModal
        visible={paymentThreeDVisible}
        changeVisible={changePaymentThreeDVisible}
        html={paymentThreeDHtml}
      ></SyThreeDModal>
    </>
  );
}

const colors = make().theme.colors;

const styles = stylize({
  page: makeColumn("m").padding("m").flex(1).spaceBetween(),
  amountContainer: makeColumn("s").textCenter().margin("m", true, false),
  amountTextContainer: makeColumn("s").flex(1).center(),
  amountBox: makeBox().padding("m").row().gap("l"),
  amountImage: make().height("purchaseImage").ratio(1).selfCenter(),
  amountImageContainer: make(),
  amountHeader: makeRow().end(),
  amountFooter: makeRow().spaceBetween().itemsCenter(),
  price: make().font("l", "l", "main").textCenter(),
  amount: make().font("m", "l", "main").textCenter(),
  discount: make().font("lx", "m", "secondary").textCenter(),
  vm: make()
    .height("visaMastercard")
    .ratio(956 / 182),
  vmContainer: make().itemsCenter(),
  ip: make()
    .height("iyzipay")
    .ratio(1050 / 155),
  ipContainer: make().itemsCenter().padding("m", true, false),
  inputs: makeColumn("m"),
  inputRow: makeRow("s"),
  fieldRow: makeRow("xl"),
  fieldHalf: make().flex(0.5),
  inputSection: make().flex(1).width("cardCvc"),
  loginButton: make().flex(1).background("primary"),
  loginButtonLabel: make().font("l", "l", "light"),
  payment: makeRow()
    .spaceBetween()
    .padding("l", true, false)
    .margin("l", true, false),
  paymentTitle: make().font("l", "l", "secondary"),
  paymentPrice: make().font("l", "l", "secondary"),
  modalContent: make().itemsCenter().padding("m").itemsCenter().gap("s"),
  modalTextGroup: makeColumn().itemsCenter().gap("n"),
  pazaramaText: make().textCenter(),
  pazaramaTitle: make().font("l", "m", "secondary").textCenter(),
});
