import { View } from "react-native";
import SyText from "./SyText";
import SyIcon from "./SyIcon";
import SyButton from "./SyButton";
import { make, stylize } from "../lib/style";
import { makeBox, makeRow } from "../themes/components";
import { Link } from "@react-navigation/native";

export default function SySupportCard() {
  return (
    <View style={styles.help}>
      <SyText
        style={styles.contactTitle}
        content="Destek talebi ve öneriler için:"
      ></SyText>
      <View style={styles.contact}>
        <SyIcon style={styles.contactIcon} name="chat-question"></SyIcon>
        <Link style={styles.contactButton} to={{ screen: "Faq" }}>
          <SyText
            content="Sıkça Sorulan Sorular"
            style={styles.contactButtonLabel}
          ></SyText>
        </Link>
      </View>

      <View style={styles.contact}>
        <SyIcon style={styles.contactIcon} name="whatsapp"></SyIcon>
        <SyText style={styles.contactText} content="543 953 5999"></SyText>
      </View>
      <View style={styles.contact}>
        <SyIcon style={styles.contactIcon} name="email"></SyIcon>
        <SyText style={styles.contactText} content="info@saykl.com"></SyText>
      </View>
    </View>
  );
}

const styles = stylize({
  help: makeBox().gap("s").margin('n').flex(1).center(),
  contact: makeRow("s").itemsCenter(),
  contactTitle: make().font("l", "l", "main"),
  contactIcon: make().font("x", "m", "main"),
  contactText: make().font("m", "m", "main"),
  contactButton: make().background("transparent").shadow(0).padding("n"),
  contactButtonLabel: make().font("m", "l", "primary"),
});
