import { FlatList } from "react-native";
import SyProductListItem from "./SyProductListItem";
import SyBanner from "./SyBanner";

const BANNERS = [
  require("../assets/images/banner-hero-4.png"),
];

export default function SyProductList({ products }) {
  return (
    <FlatList
      data={products}
      keyExtractor={(item) => item.id}
      ListHeaderComponent={() => <SyBanner banners={BANNERS}></SyBanner>}
      renderItem={({ item }) => (
        <SyProductListItem {...item}></SyProductListItem>
      )}
    ></FlatList>
  );
}
