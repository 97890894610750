import { StyleSheet, PixelRatio, Appearance, Platform } from "react-native";
import light from "../themes/light";
import dark from "../themes/dark";

class Stylize {
  _style = {};
  _name;
  _theme;

  constructor(name, theme) {
    this._theme = theme;
    this._name = name;
  }

  get theme() {
    return this._theme;
  }

  get name() {
    return this._name;
  }

  get style() {
    return this._style;
  }

  set(style) {
    let calc = style;
    if (typeof style === "function") {
      calc = style(this._theme);
    }
    Object.assign(this._style, calc);
    return this;
  }

  row() {
    return this.set({
      flexDirection: "row",
    });
  }

  column() {
    return this.set({
      flexDirection: "column",
    });
  }

  wrap() {
    return this.set({
      flexWrap: "wrap",
    });
  }

  center() {
    return this.set({
      justifyContent: "center",
    });
  }

  end() {
    return this.set({
      justifyContent: "flex-end",
    });
  }

  start() {
    return this.set({
      justifyContent: "flex-start",
    });
  }

  spaceAround() {
    return this.set({
      justifyContent: "space-around",
    });
  }

  spaceBetween() {
    return this.set({
      justifyContent: "space-between",
    });
  }

  spaceEvenly() {
    return this.set({
      justifyContent: "space-evenly",
    });
  }

  selfCenter() {
    return this.set({
      alignSelf: "center",
    });
  }

  selfStart() {
    return this.set({
      alignSelf: "flex-start",
    });
  }

  selfEnd() {
    return this.set({
      alignSelf: "flex-end",
    });
  }

  selfStretch() {
    return this.set({
      alignSelf: "stretch",
    });
  }

  contentCenter() {
    return this.set({
      justifyContent: "center",
    });
  }

  contentStart() {
    return this.set({
      justifyContent: "flex-start",
    });
  }

  contentEnd() {
    return this.set({
      justifyContent: "flex-end",
    });
  }

  contentStretch() {
    return this.set({
      justifyContent: "stretch",
    });
  }

  itemsCenter() {
    return this.set({
      alignItems: "center",
    });
  }

  itemsStretch() {
    return this.set({
      alignItems: "stretch",
    });
  }

  itemsBaseline() {
    return this.set({
      alignItems: "baseline",
    });
  }

  itemsStart() {
    return this.set({
      alignItems: "flex-start",
    });
  }

  itemsEnd() {
    return this.set({
      alignItems: "flex-end",
    });
  }

  flex(value = 1) {
    return this.set({
      flex: value,
    });
  }

  padding(size = "m", vertical = true, horizontal = true) {
    const value = this._getThemeValue("size", size);

    if (vertical && horizontal) {
      return this.set({
        padding: value,
      });
    }

    if (vertical) {
      return this.set({
        paddingVertical: value,
      });
    }

    if (horizontal) {
      return this.set({
        paddingHorizontal: value,
      });
    }
  }

  margin(size = "m", vertical = true, horizontal = true) {
    const value = this._getThemeValue("size", size);

    if (vertical && horizontal) {
      return this.set({
        margin: value,
      });
    }

    if (vertical) {
      return this.set({
        marginVertical: value,
      });
    }

    if (horizontal) {
      return this.set({
        marginHorizontal: value,
      });
    }
  }

  font(size = "m", weight = "m", color = "main", family = "main") {
    const sizeValue = this._getThemeValue("size", size);
    const familyValue = this._getThemeValue("font", family);
    const colorValue = this._getThemeValue("colors", color);

    return this.set({
      fontFamily: familyValue[weight],
      fontSize: PixelRatio.getFontScale() * sizeValue,
      color: colorValue,
    });
  }

  color(value) {
    const colorValue = this._getThemeValue("colors", value);
    return this.set({
      color: colorValue,
    });
  }

  size(width, height) {
    const widthValue = this._getThemeValue("size", width);
    const heightValue = height
      ? this._getThemeValue("size", width)
      : widthValue;

    return this.set({
      width: widthValue,
      height: heightValue,
    });
  }

  width(width) {
    const widthValue = this._getThemeValue("size", width);

    return this.set({
      width: widthValue,
    });
  }

  height(height) {
    const heightValue = this._getThemeValue("size", height);

    return this.set({
      height: heightValue,
    });
  }

  fullWidth() {
    return this.set({
      width: "100%",
    });
  }

  fullHeight() {
    return this.set({
      height: "100%",
    });
  }

  autoWidth() {
    return this.set({
      width: "auto",
    });
  }

  autoHeight() {
    return this.set({
      height: "auto",
    });
  }

  fullSize() {
    return this.set({
      height: "100%",
      width: "100%",
    });
  }

  gap(size = "m") {
    const value = this._getThemeValue("size", size);

    return this.set({
      gap: value,
    });
  }

  background(color) {
    const colorValue = this._getThemeValue("colors", color);

    return this.set({
      backgroundColor: colorValue,
    });
  }

  radius(radius = "m") {
    const value = this._getThemeValue("radius", radius);

    return this.set({
      borderRadius: value,
      overflow: "hidden",
    });
  }

  ratio(value) {
    return this.set({
      aspectRatio: value,
    });
  }

  shadow(value) {
    if (value === 0) {
      return this.set({
        shadowOffset: null,
        shadowRadius: 0,
      });
    }
    const colorValue = this._getThemeValue("colors", "shadow");
    const shadowProps = calculateShadow(value, colorValue);

    return this.set(shadowProps);
  }

  border(width) {
    const value = this._getThemeValue("size", width);

    return this.set({
      borderWidth: value,
    });
  }

  borderColor(color) {
    const colorValue = this._getThemeValue("colors", color);

    return this.set({
      borderColor: colorValue,
    });
  }

  uppercase() {
    return this.set({
      textTransform: "uppercase",
    });
  }

  lowercase() {
    return this.set({
      textTransform: "lowercase",
    });
  }

  capitalize() {
    return this.set({
      textTransform: "capitalize",
    });
  }

  circular(size = "m") {
    const value = this._getThemeValue("size", size);

    return this.set({
      width: value,
      height: value,
      borderRadius: value / 2,
    });
  }

  textCenter() {
    return this.set({
      textAlign: "center",
    });
  }

  overflowHidden() {
    return this.set({
      overflow: "hidden",
    });
  }

  overflowVisible() {
    return this.set({
      overflow: "visible",
    });
  }

  relativePosition() {
    return this.set({
      position: "relative",
    });
  }

  absolutePosition() {
    return this.set({
      position: "absolute",
    });
  }

  _getThemeValue(key, modifier) {
    const value = this._theme[key][modifier];

    if (typeof value === "number") {
      return value * this._theme.ratio;
    }

    return value;
  }
}

export function make() {
  let colorScheme = Appearance.getColorScheme();
  let theme;

  if (colorScheme === "dark") {
    theme = dark;
  } else {
    colorScheme = "light"; // test
    theme = light;
  }

  return new Stylize(colorScheme, theme);
}

export function stylize(props) {
  const styles = {};

  for (let key in props) {
    const prop = props[key];
    if (prop instanceof Stylize) {
      styles[key] = prop.style;
    } else {
      styles[key] = stylize(prop);
    }
  }

  return StyleSheet.create(styles);
}

// @see https://github.com/material-components/material-components-web/blob/master/packages/mdc-elevation/_variables.scss
const androidDepth = {
  umbra: [
    "0px 2px 1px -1px",
    "0px 3px 1px -2px",
    "0px 3px 3px -2px",
    "0px 2px 4px -1px",
    "0px 3px 5px -1px",
    "0px 3px 5px -1px",
    "0px 4px 5px -2px",
    "0px 5px 5px -3px",
    "0px 5px 6px -3px",
    "0px 6px 6px -3px",
    "0px 6px 7px -4px",
    "0px 7px 8px -4px",
    "0px 7px 8px -4px",
    "0px 7px 9px -4px",
    "0px 8px 9px -5px",
    "0px 8px 10px -5px",
    "0px 8px 11px -5px",
    "0px 9px 11px -5px",
    "0px 9px 12px -6px",
    "0px 10px 13px -6px",
    "0px 10px 13px -6px",
    "0px 10px 14px -6px",
    "0px 11px 14px -7px",
    "0px 11px 15px -7px",
  ],
  penumbra: [
    "0px 1px 1px 0px",
    "0px 2px 2px 0px",
    "0px 3px 4px 0px",
    "0px 4px 5px 0px",
    "0px 5px 8px 0px",
    "0px 6px 10px 0px",
    "0px 7px 10px 1px",
    "0px 8px 10px 1px",
    "0px 9px 12px 1px",
    "0px 10px 14px 1px",
    "0px 11px 15px 1px",
    "0px 12px 17px 2px",
    "0px 13px 19px 2px",
    "0px 14px 21px 2px",
    "0px 15px 22px 2px",
    "0px 16px 24px 2px",
    "0px 17px 26px 2px",
    "0px 18px 28px 2px",
    "0px 19px 29px 2px",
    "0px 20px 31px 3px",
    "0px 21px 33px 3px",
    "0px 22px 35px 3px",
    "0px 23px 36px 3px",
    "0px 24px 38px 3px",
  ],
  ambient: [
    "0px 0px 0px 0px",
    "0px 1px 3px 0px",
    "0px 1px 5px 0px",
    "0px 1px 8px 0px",
    "0px 1px 10px 0px",
    "0px 1px 14px 0px",
    "0px 1px 18px 0px",
    "0px 2px 16px 1px",
    "0px 3px 14px 2px",
    "0px 3px 16px 2px",
    "0px 4px 18px 3px",
    "0px 4px 20px 3px",
    "0px 5px 22px 4px",
    "0px 5px 24px 4px",
    "0px 5px 26px 4px",
    "0px 6px 28px 5px",
    "0px 6px 30px 5px",
    "0px 6px 32px 5px",
    "0px 7px 34px 6px",
    "0px 7px 36px 6px",
    "0px 8px 38px 7px",
    "0px 8px 40px 7px",
    "0px 8px 42px 7px",
    "0px 9px 44px 8px",
    "0px 9px 46px 8px",
  ],
};

const androidColor = {
  umbra: "rgba(200,200,200,0.2)",
  penumbra: "rgba(200,200,200,0.14)",
  ambient: "rgba(200,200,200,0.12)",
};

function parseShadow(raw) {
  const values = raw.split(" ").map((val) => +val.replace("px", ""));
  return {
    x: values[0],
    y: values[1],
    blur: values[2],
    spread: values[3], // unused
  };
}

function interpolate(i, a, b, a2, b2) {
  return ((i - a) * (b2 - a2)) / (b - a) + a2;
}

//https://ethercreative.github.io/react-native-shadow-generator/
function calculateShadow(depth, color) {
  const s = parseShadow(androidDepth.penumbra[depth]);
  const y = s.y === 1 ? 1 : Math.floor(s.y * 0.5);

  if (Platform.OS === "web") {
    const rgba = hexToRgba(color, 0.35);

    return {
      boxShadow: `${s.x}px ${y}px ${s.blur}px 0 ${rgba}`,
    };

    return {
      boxShadow: `
        ${androidDepth.umbra[depth]} ${androidColor.umbra},
        ${androidDepth.penumbra[depth]} ${androidColor.penumbra},
        ${androidDepth.ambient[depth]} ${androidColor.ambient}
		  `,
    };
  } else {
    return {
      shadowOffset: {
        width: 0,
        height: y,
      },
      shadowOpacity: Number.parseFloat(
        interpolate(depth, 1, 24, 0.2, 0.6).toFixed(2)
      ),
      shadowRadius: Number.parseFloat(
        interpolate(s.blur, 1, 38, 1, 16).toFixed(2)
      ), //(s.blur * 0.45).toFixed(2);
      elevation: depth,
      shadowColor: color,
    };
  }
}

// chatgpt
function hexToRgba(hex, alpha) {
  hex = hex.replace("#", ""); // remove "#" character if present

  var r, g, b;

  // Expand shorthand hex color codes
  if (hex.length === 3) {
    r = parseInt(hex[0] + hex[0], 16);
    g = parseInt(hex[1] + hex[1], 16);
    b = parseInt(hex[2] + hex[2], 16);
  } else if (hex.length === 6) {
    r = parseInt(hex.slice(0, 2), 16);
    g = parseInt(hex.slice(2, 4), 16);
    b = parseInt(hex.slice(4, 6), 16);
  } else {
    throw new Error("Invalid hex color code: " + hex);
  }

  return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
}
