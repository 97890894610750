import Common from "./common";

export default {
  ...Common,

  colors: {
    main: "#2B2B2B",
    placeholder:'#888',
    primary: "#05e605",
    secondary: "#e605e6",
    transparent: "transparent",
    primaryBackground: "#f5f5f5",
    tabBarColor: "#fff",
    shadow: "#222",
    headerColor:"#fff",
    headerBorderColor:"#ccc",
    light: "#fff",
    lightGrey: "#eee",
    lightGrey2: "#bbb",
    lightGrey3: "#999",
    lightGrey4: "#666",
    lightGrey4Alpha:"#66666633",
    borderLight: "rgb(230, 230, 230)",
    disable: "#ddd",
    active: "#7366F0",
    inactive: "#eee",
    red: "#EE5C6E",
    dark: "#2B2B2B",
    black: "#000000",
    yellow: "#ffff00",
    magenta: "#e605e6",
    mint: "#e6fff4",
    mint2: "#05e684",
    modalBackground: "rgba(0,0,0,0.6)",
    blank: "#000",
    white:"#fff",
    headerIconColor:"#000",
    buttonBackground:'#eee',
    otherMessageBackground:'#fff',
    userMessageBackground:'#05e684',
    messageTextColor:'#2B2B2B',
    inputBackground:'#fff',
    webBorderColor:'#fff',
  },
};
