import { View } from "react-native";
import { make, stylize } from "../lib/style";
import SyButton from "./SyButton";
import SySpace from "./SySpace";

export default function SyPageActions({ actions = [], showSpace = true }) {
  return (
    <View style={styles.container}>
      <View style={styles.actions}>
        {actions.map((action, index) => (
          <SyButton
            {...action}
            key={index}
            style={[
              styles.requestButton,
              action.negative && styles.negativeButton,
            ]}
            labelStyle={styles.requestButtonLabel}
            iconStyle={styles.requestButtonIcon}
            disabled={action.disabled}
          ></SyButton>
        ))}
      </View>
      {showSpace && <SySpace></SySpace>}
    </View>
  );
}

const styles = stylize({
  container: make().column().set({
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  }),
  actions: make().row().gap("m").padding("m").background("transparent").flex(1),
  requestButton: make().background("primary").flex(1),
  requestButtonLabel: make().font("l", "l", "light"),
  negativeButton: make().background("secondary").flex(1),
  requestButtonIcon: make().font("x", "l", "light"),
});
