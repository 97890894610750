import { Image, View } from "react-native";

import { make, stylize } from "../lib/style";

import SyIconAstronaut from "../icons/SyIconAstronaut";

export default function SyUserImage({ image, size = "m" }) {
  const containerStyle = [styles.container];
  const imageStyle = [styles.image];
  let svgStyle = {};
  switch (size) {
    case "t":
      containerStyle.push(styles.containerTiny);
      imageStyle.push(styles.imageTiny);
      svgStyle.width = styles.imageTiny.width;
      svgStyle.height = styles.imageTiny.height;
      break;
    case "s":
      containerStyle.push(styles.containerSmall);
      imageStyle.push(styles.imageSmall);
      svgStyle.width = styles.imageSmall.width;
      svgStyle.height = styles.imageSmall.height;
      break;
    case "m":
      containerStyle.push(styles.containerMedium);
      imageStyle.push(styles.imageMedium);
      svgStyle.width = styles.imageMedium.width;
      svgStyle.height = styles.imageMedium.height;
      break;
  }

  return (
    <View style={containerStyle}>
      {image && <Image source={{ uri: image }} style={imageStyle}></Image>}
      {!image && (
        <SyIconAstronaut
          width={svgStyle.width}
          height={svgStyle.height}
        ></SyIconAstronaut>
      )}
    </View>
  );
}

const styles = stylize({
  container: make()
    .itemsCenter()
    .center()
    .overflowHidden()
    .set({
      borderWidth: 1,
    })
    .background("light"),
  containerMedium: make().set({
    width: 144,
    height: 144,
    borderRadius: 72,
  }),
  containerSmall: make().set({
    width: 60,
    height: 60,
    borderRadius: 30,
  }),
  containerTiny: make().set({
    width: 36,
    height: 36,
    borderRadius: 18,
  }),
  image: make().flex(1),
  imageMedium: make().set({
    width: 144,
    height: 144,
  }),
  imageSmall: make().set({
    width: 60,
    height: 60,
  }),
  imageTiny: make().set({
    width: 36,
    height: 36,
  }),
});
