import { ActivityIndicator, View } from "react-native";
import { make, stylize } from "../lib/style";

const secondary = make().theme.colors.secondary;

export default function SySpinner() {
  return (
    <View style={styles.spinnerContainer}>
      <ActivityIndicator style={styles.spinner} color={secondary} size="large" />
    </View>
  );
}

const styles = stylize({
  spinnerContainer: make().flex(1).itemsCenter().center(),
  spinner: make().margin("l").set({}),
});
