import { Svg, Path } from "react-native-svg";

export default function SyIconSearch(props) {
  return (
    <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 121.13 121.13"
    {...props}
  >
    <Path
      d="M117.17 100.206s-16.216-14.184-20.094-18.058c-5.693-5.663-7.97-8.39-5.961-12.995 8.79-17.928 5.75-40.184-9.16-55.09-18.746-18.75-49.148-18.75-67.894 0-18.748 18.752-18.748 49.144 0 67.898 14.9 14.901 37.164 17.942 55.085 9.154 4.605-2.008 7.34.27 13 5.965 3.877 3.876 18.053 20.094 18.053 20.094 7.355 7.351 13.014 2.827 16.411-.565 3.39-3.395 7.916-9.051.561-16.404zM67.663 67.664c-10.859 10.852-28.458 10.852-39.312 0s-10.855-28.456 0-39.308c10.854-10.852 28.453-10.852 39.312 0 10.855 10.853 10.855 28.456 0 39.308z"
      fill={props.color}
    />
  </Svg>
  );
}
