import { View } from "react-native";
import { formatLocation } from "../lib/location";
import { make, stylize } from "../lib/style";
import { makeBox, makeRow } from "../themes/components";
import SyText from "./SyText";
import SyUserImage from "./SyUserImage";
import SyUserRating from "./SyUserRating";
import { capitalize } from "../lib/util";
import { profileVar } from "../queries/cache";
import SyTitle from "./SyTitle";

export default function SyUserCard({
  id,
  name,
  email,
  phone,
  image,
  location,
  sayklPoints,
  rating,
  children,
  title
}) {
  const profile = profileVar();
  const cappedName = name ? capitalize(name) : "";

  const isSelf = profile && profile.id === id;

  return (
    <View style={styles.profile}>
      
      {title && <SyTitle content={title}></SyTitle>}

      <View style={styles.profileBox}>
        <SyUserImage image={image}></SyUserImage>
        <SyText style={styles.name} content={cappedName}></SyText>
        <SyUserRating rating={rating}></SyUserRating>
        <SyText style={styles.phone} content={email}></SyText>
        <SyText style={styles.phone} content={phone}></SyText>
        <SyText
          style={styles.location}
          content={formatLocation(location)}
        ></SyText>
        {(isSelf || !!sayklPoints) && (
          
            <View style={styles.row}>
              <SyText style={styles.sayklPoints} content={sayklPoints}></SyText>
              <SyText style={styles.saykl} content="saykl puan"></SyText>
            </View>
        )}
        {children}
      </View>
    </View>
  );
}

const styles = stylize({
  row: makeRow("s").itemsCenter(),
  name: make().font("x"),
  phone: make().font("l"),
  location: make().font("l"),
  saykl: make().font("l"),
  sayklPoints: make().font("l", "m"),
  profile: make().column().gap('m').flex(1).padding("m"),
  profileBox: makeBox().column().itemsCenter().center().padding("m").flex(1),
});
