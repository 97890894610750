import * as Linking from "expo-linking";
import { pageVar } from "../queries/cache";

const prefix = Linking.createURL("/");
const config = {
  screens: {
    ProductDetail: {
      path: "ilanlar/:id",
    },
    UserDetail: {
      path: "uyeler/:id",
    },
    ChatDetail: {
      path: "mesajlar/:id",
    },

    Invitation: {
      path: "yeni-davet",
    },

    InvitationDetail: {
      path: "davetler/:id",
    },

    ConfirmationDetail: {
      path: "onaylar/:id",
    },

    ResetPasswordDetail: {
      path: "sifre-sifirlama/:id",
    },

    Camera: {
      path: "kamera",
    },

    Faq: {
      path: "sss",
    },

    Help: {
      path: "yardim",
    },

    About: {
      path: "hakkimizda",
    },

    Settings: {
      path: "ayarlar",
    },

    PrivacyStatement: {
      path: "gizlilik-politikasi",
    },

    UsersAgreement: {
      path: "kullanici-sozlesmesi",
    },

    Login: {
      path: "giris",
    },

    Signup: {
      path: "kaydol",
    },

    SocialLogin: {
      path: "sosyal-giris",
    },

    Download: {
      path: "indir",
    },
    Purchase: {
      path: "puan-al",
    },
    NewChat: {
      path: "yeni-mesaj",
    },
    NewRequest: {
      path: "yeni-talep/:id",
    },
    NewProduct: {
      path: "yeni-ilan",
    },
    NewShipping: {
      path: "anlasmali-kargo/:id",
    },
    ProductUpdate: {
      path: "ilan-güncelleme/:id",
    },
    Report: {
      path: "sorun-bildir",
    },
    DeliveryNotification: {
      path: "kargo",
    },
    Conversion: {
      path: "donustur",
    },
    Main: {
      screens: {
        Dashboard: {
          path: "ilanlar",
          screens: {
            Products: {
              path: "ilanlarim",
            },
            Favorites: {
              path: "favorilerim",
            },
          },
        },
        Search: {
          path: "arama",
        },
        Requests: {
          path: "talepler",
          screens: {
            IncomingRequests: {
              path: "gelen",
            },
            OutgoingRequests: {
              path: "giden",
            },
          },
        },
        Chats: {
          path: "mesajlar",
          screens: {
            MemberMessages: {
              path: "uye",
            },
            SystemMessages: {
              path: "sistem",
            },
          },
        },
        Profile: {
          path: "profil",
        },
      },
    },
  },
};
export const linking = {
  prefixes: [prefix, "saykl://", "https://www.saykl.com", "https://saykl.com"],
  config,
};

export function saveUrl() {
  const url = Linking.useURL();

  if (url) {
    const { path } = Linking.parse(url);

    if (path === "giris" || path === "kayit") {
      return;
    }

    pageVar({
      link: path,
    });
  }
}
