import { Image, ScrollView, View } from "react-native";
import { make, stylize } from "../lib/style";
import SyText from "./SyText";
import { useScreenState } from "../lib/screen";

export default function SyBanner({ banners }) {
  const { style } = useScreenState();

  return (
    <ScrollView style={style} horizontal={true} pagingEnabled={true}>
      {banners.map((banner, index) => (
        <View style={[styles.imageContainer, style]} key={index}>
          <View style={styles.imageWrapper}>
            <Image source={banner} style={styles.image}></Image>
            {banners.length > 1 && (
              <SyText
                style={styles.counter}
                content={index + 1 + "/" + banners.length}
              ></SyText>
            )}
          </View>
        </View>
      ))}
    </ScrollView>
  );
}

const styles = stylize({
  imageWrapper: make().flex(1).ratio(2),
  imageContainer: make().row().ratio(2).padding("m").flex(1),
  image: make().radius("m").ratio(2).flex(1).shadow(1),

  counter: make()
    .absolutePosition()
    .set({
      top: 0,
      right: 0,
    })
    .font("sm", "m", "lightGrey")
    .margin("s")
    .padding("s", false, true)
    .radius("m")
    .background("lightGrey4"),
});
