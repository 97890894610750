import { gMutation, gQuery, gSubscription } from "../lib/graphql";
import {
  createChatMutation,
  createMessageMutation,
  deleteChatMutation,
  fetchChatMessagesQuery,
  fetchChatQuery,
  fetchProfileChatsQuery,
  messageReceivedSubscription,
} from "./gql";

export function useChat(id, productId, senderId, receiverId, lazy = false) {
  return gQuery(
    fetchChatQuery,
    {
      variables: { id, productId, senderId, receiverId },
    },
    lazy,
    false
  );
}

export function useChatMessages(chatId, onCompleted) {
  return gQuery(fetchChatMessagesQuery, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    refetchQueries: [fetchProfileChatsQuery],
    variables: { chatId },
    onCompleted,
  });
}

export function useCreateMessage() {
  return gMutation(createMessageMutation);
}

export function useCreateChat() {
  return gMutation(createChatMutation);
}

export function useDeleteChat() {
  return gMutation(deleteChatMutation);
}

export function useMessageReceived(chatId, options) {
  return gSubscription(messageReceivedSubscription, {
    ...options,
    variables: {
      chatId,
    },
  });
}
