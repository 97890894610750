import { gql } from "@apollo/client";
import { gMutation } from "../../lib/graphql";

export const startPaymentMutation = gql`
  mutation StartPayment(
    $name: String!,
    $cardNumber: String!,
    $cardExpiration: String!,
    $cardCvc: String!,
    $amount: String!
  ) {
    startPayment(
      name: $name,
      cardNumber: $cardNumber,
      cardExpiration: $cardExpiration,
      cardCvc: $cardCvc,
      amount: $amount
    )
  }
`;

export const createPaymentMutation = gql`
  mutation CreatePayment($id:String){
    createPayment(id: $id)
  }
`;

export function useStartPayment() {
  return gMutation(startPaymentMutation);
}

export function useCreatePayment() {
  return gMutation(createPaymentMutation);
}
