import { Platform, ScrollView, View } from "react-native";
import SyPage from "../widgets/SyPage";
import { make, stylize } from "../lib/style";
import { makeColumn } from "../themes/components";
import WebView from "react-native-webview";

export default function PrivacyStatementPage() {
  return (
    <SyPage authRequired={false}>
      <ScrollView contentContainerStyle={styles.container}>
        {Platform.OS !== "web" && (
          <WebView
            source={{ uri: "https://saykl-text.surge.sh/privacy-statement.html" }}
          ></WebView>
        )}
        {Platform.OS === "web" && (
          <iframe
            style={styles.iframe}
            src="https://saykl-text.surge.sh/privacy-statement.html"
          ></iframe>
        )}
      </ScrollView>
    </SyPage>
  );
}

const styles = stylize({
  container: makeColumn("m").itemsStretch().flex(1),
  title: make()
    .borderColor("lightGrey4")
    .set((theme) => ({
      marginBottom: theme.size.m,
      paddingBottom: theme.size.s,
      borderBottomWidth: 1,
    })),
  iframe: make().set({
    flex: 1,
    border: "none",
    height: "100%",
  }),
});
