import { ScrollView, View } from "react-native";
import { make, stylize } from "../lib/style";
import { useUser } from "../queries/user";
import { makeBox } from "../themes/components";
import SyPage from "../widgets/SyPage";
import SyProductGrid from "../widgets/SyProductGrid";
import SyTitle from "../widgets/SyTitle";
import SyUserCard from "../widgets/SyUserCard";
import { useBlockUser, useUnblockUser } from "../queries/profile";

export default function UserDetailPage({ route, navigation }) {
  const userRet = useUser(route.params.id);
  const user = userRet.data?.user;
  const products = userRet.data?.user?.products;
  const [blockUser] = useBlockUser();
  const [unblockUser] = useUnblockUser();

  const actions = [];

  if (!user) {
    return;
  }

  const report = async () => {
    navigation.navigate("Report", { userId: user.id });
  };

  if (user.isBlocked) {
    actions.push({
      label: "Engeli Kaldır",
      negative: false,
      onPress: () => {
        unblockUser({ variables: { id: route.params.id } });
      },
    });
  } else {
    actions.push({
      label: "Engelle",
      negative: true,
      onPress: () => {
        blockUser({ variables: { id: route.params.id } });
      },
    });
    actions.push({
      label: "Bildir",
      negative: true,
      onPress: report,
    });
  }

  return (
    <SyPage style={styles.page} actions={actions}>
      <View style={styles.userWrapper}>
        <SyUserCard {...user}></SyUserCard>
      </View>
      <View style={styles.userWrapper}>
        <View style={styles.titleContainer}>
          <SyTitle
            style={styles.productsTitle}
            content="Üyenin Tüm İlanları"
          ></SyTitle>
        </View>

        <SyProductGrid
          products={products}
          paginate={false}
          loading={userRet.loading}
        ></SyProductGrid>
      </View>
    </SyPage>
  );
}

const styles = stylize({
  userWrapper: make().flex(1),
  titleContainer: make().itemsCenter(),
  productsTitle: make(),
  gridContainer: makeBox().padding("n").margin("m", false, true),
  blockContainer: make().itemsCenter().center().flex(1),
  blockIcon: make().font("x", "m", "main"),
  blockTitle: make().font("x", "m", "main"),
  scrollContent: make().flex(1),
});
