import { Image, ScrollView, View } from "react-native";
import { make, stylize } from "../lib/style";
import SyPage from "./SyPage";
import { useScreenState } from "../lib/screen";
import SyButton from "./SyButton";
import { useRef, useState } from "react";
import SySignupSuccess from "./SySignupSuccess";

const images = [
  require("../assets/images/boarding-1.png"),
  require("../assets/images/boarding-2.png"),
  require("../assets/images/boarding-3.png"),
  require("../assets/images/boarding-4.png"),
];

export default function SyBoarding() {
  const { state, style, screenHeight, contentWidth } = useScreenState();
  const scrollRef = useRef();
  const [imageIndex, updateImageIndex] = useState(1);
  return (
    <SyPage authRequired={false}>
      <ScrollView
        ref={scrollRef}
        style={style}
        horizontal={true}
        pagingEnabled={true}
        contentContainerStyle={styles.content}
        showsHorizontalScrollIndicator={false}
        scrollEnabled={false}
      >
        {images.map((image, index) => (
          <Image
            source={image}
            style={[style, { height: screenHeight }]}
            key={index}
          ></Image>
        ))}
        <View style={[style, { height: screenHeight }]}>
        <SySignupSuccess ></SySignupSuccess>
        </View>
      </ScrollView>
      {imageIndex <= images.length && <View style={styles.actions}>
        <View style={styles.flexed}></View>
        <SyButton
          label="İleri"
          style={styles.negativeButton}
          labelStyle={styles.requestButtonLabel}
          onPress={() => {
            if (imageIndex === images.length + 1) {
              return;
            }

            scrollRef.current.scrollTo({
              x: imageIndex * contentWidth,
              y: 0,
              animated: true,
            });
            updateImageIndex(imageIndex + 1);
          }}
        ></SyButton>
      </View>}
    </SyPage>
  );
}

const styles = stylize({
  container: make().column(),
  flexed: make().flex(1),
  actions: make()
    .row()
    .gap("m")
    .padding("m")
    .background("transparent")
    .flex(1)
    .set({
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
    }),
  requestButtonLabel: make().font("l", "l", "black"),
  negativeButton: make().background("primary").flex(1),
});
