import { gql } from "@apollo/client";
import { gMutation, gQuery, gSubscription } from "../../lib/graphql";
import {
  fetchProfileNotificationsQuery,
  fetchProfileSystemMessagesQuery,
} from "../gql";

export const newNotificationSubscription = gql`
  subscription NewNotification {
    newNotification {
      id
      type
      user {
        id
      }
    }
  }
`;

export const clearNotificationsMutation = gql`
  mutation ClearNotifications($type: String!) {
    clearNotifications(type: $type)
  }
`;

export const deleteNotificationMutation = gql`
  mutation DeleteNotification($id: ID!) {
    deleteNotification(id: $id)
  }
`;

export function useNotification() {
  return gSubscription(newNotificationSubscription, {});
}

export function useNotifications() {
  return gQuery(fetchProfileNotificationsQuery);
}

export function useClearNotifications() {
  return gMutation(clearNotificationsMutation, {
    refetchQueries: [fetchProfileNotificationsQuery],
  });
}

export function useDeleteNotification() {
  return gMutation(deleteNotificationMutation);
}

export function useSystemMessages() {
  return gQuery(fetchProfileSystemMessagesQuery, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });
}
