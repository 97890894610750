import { Linking, Platform, ScrollView, View } from "react-native";
import { make, stylize } from "../lib/style";
import SyPage from "../widgets/SyPage";
import { makeColumn } from "../themes/components";
import SyText from "../widgets/SyText";
import SyInformatique from "../widgets/SyInformatique";
import SyButton from "../widgets/SyButton";
import PurchasePage from "./PurchasePage";

const RECYCLE_IMAGE = require("../assets/recycle.png");
const LINK_IMAGE = require("../assets/link.png");
const DELIVERY_IMAGE = require("../assets/delivery-package.png");
const ACTIONS = [
  {
    label: "Siteye git",
    authRequired: false,
    onPress: () => {
      Linking.openURL("https://saykl.com/donustur");
    },
  },
];

export default function ConversionPage({ navigation }) {
  const actions = Platform.OS === "web" ? [] : ACTIONS;

  return (
    <SyPage authRequired={true} actions={actions}>
      <ScrollView contentContainerStyle={styles.sections}>
        <SyInformatique
          image={RECYCLE_IMAGE}
          text="İlanına talep gelmesini beklemeden, istediğin ürünü hemen almak için saykl puan satın alabilirsin. Fazladan saykl puan'a sahipsen ve kullanmayacaksan, hediye çekine dönüştürerek harcayabilirsin."
        ></SyInformatique>
        <SyInformatique
          image={DELIVERY_IMAGE}
          text="MNG ve Aras Kargo'da geçerli 10 Desiye kadar anlaşmalı kargo hizmetinden faydalanmak için kargo hakkı satın alabilirsin."
        ></SyInformatique>
        {Platform.OS !== "web" && (
          <SyInformatique
            image={LINK_IMAGE}
            text="Web sitemizi ziyaret edip kullanıcı adın ve şifren ile giriş yaptıktan sonra tüm saykl puan dönüştürme işlemlerini gerçekleştirebilirsin."
          ></SyInformatique>
        )}
        {Platform.OS === "web" && (
          <>
            <PurchasePage></PurchasePage>
          </>
        )}
      </ScrollView>
    </SyPage>
  );
}

const styles = stylize({
  sections: makeColumn("m").padding("m"),
  section: make(),
  title: make().font("m", "l"),
  successButton: make().background("primary"),
  successButtonLabel: make().font("l", "l", "light"),
  successButtonIcon: make().color("light"),
});
