import { FlatList, View } from "react-native";
import SyIconOwl from "../icons/SyIconOwl";
import { make, stylize } from "../lib/style";
import SyBanner from "./SyBanner";

import SyProductGridItem from "./SyProductGridItem";
import SySpinner from "./SySpinner";
import SyText from "./SyText";
import { useScreenState } from "../lib/screen";
import { useCallback, useEffect, useState } from "react";
import { graphqlClient } from "../lib/graphql";

const BANNERS = [
  require("../assets/images/banner-hero-1.png"),
  require("../assets/images/banner-hero-2.png"),
  require("../assets/images/banner-hero-3.png"),
];

export default function SyProductGrid({
  loading = false,
  products,
  hasBanner = false,
  refetch,
  fetchMore,
  paginate = false,
  page = 0,
  changePage,
}) {
  const { state } = useScreenState();
  const [refreshing, changeRefreshing] = useState(false);

  const onRefresh = useCallback(() => {
    (async () => {
      changeRefreshing(true);
      graphqlClient.cache.evict({ id: "ROOT_QUERY", fieldName: "products" });
      changePage(0);
      await refetch();
      changeRefreshing(false);
    })();
  }, [products]);

  useEffect(() => {
    (async () => {
      if (paginate && page !== 0) {
        await fetchMore(page);
        changeRefreshing(false);
      }
    })();
  }, [page]);

  if (loading) {
    return <SySpinner></SySpinner>;
  }

  let grid = 2;

  if (state === "tablet") {
    grid = 4;
  }

  return (
    <FlatList
      key={grid}
      data={products}
      keyExtractor={(item) => item.id}
      numColumns={grid}
      horizontal={false}
      contentContainerStyle={styles.imageContainer}
      ListHeaderComponent={() => (hasBanner ? <SyBanner banners={BANNERS}></SyBanner> : null)}
      ListEmptyComponent={
        <View style={styles.emptyContainer}>
          <View style={styles.modal}>
            <SyIconOwl width={96} height={96} fill={colors.blank}></SyIconOwl>
            <SyText style={styles.text} content="bulamadım ki"></SyText>
          </View>
        </View>
      }
      renderItem={({ item }) => {
        return <SyProductGridItem {...item} grid={grid}></SyProductGridItem>;
      }}
      removeClippedSubviews={false}
      showsHorizontalScrollIndicator={false}
      refreshing={refreshing || loading}
      onRefresh={onRefresh}
      onEndReachedThreshold={0.5}
      onEndReached={() => {
        if (paginate && !refreshing) {
          changeRefreshing(true);
          changePage(page + 1);
        }
      }}
      ListFooterComponent={refreshing && <SySpinner></SySpinner>}
    ></FlatList>
  );
}

const colors = make().theme.colors;

const styles = stylize({
  imageContainer: make(),
  modal: make().width("modal").itemsCenter(),
  text: make().font("l", "l", "blank").margin("m", true),
  emptyContainer: make()
    .flex(1)
    .itemsCenter()
    .center()
    .margin("xl", true, false),
});
