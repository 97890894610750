import { ScrollView } from "react-native";
import { stylize } from "../lib/style";
import { useProfileOutgoingRequests } from "../queries/profile";

import SyPage from "../widgets/SyPage";
import SyRequestListItem from "../widgets/SyRequestListItem";
import { clearNotifications } from "../lib/notification";
import SySpace from "../widgets/SySpace";
import SyInformatique from "../widgets/SyInformatique";

const ACTIONS = [
  {
    label: "Geçmiş Talepler",
    navigate: "OutgoingRequestsArchive",
  },
];

const INFO_IMAGE = require("../assets/outgoing.png");

export default function OutgoingRequestsPage() {
  const { loading, data, refetch } = useProfileOutgoingRequests();
  const outgoingRequests = data?.profile?.outgoingRequests;

  clearNotifications("ReceiverRequest");

  return (
    <SyPage
      loading={loading}
      authRequired={true}
      actions={ACTIONS}
      refetch={refetch}
      data={data}
    >
      <ScrollView>
        <SyInformatique
          margin={true}
          image={INFO_IMAGE}
          text="Talep ettiğin ürünü ilan sahibinin elinden ya da kargo aracılığıyla teslim aldığında “Teslim Aldım” butonunu işaretlemeyi unutma."
        ></SyInformatique>
        {outgoingRequests &&
          outgoingRequests.map((request) => {
            if (!request.sender || !request.product || !request.receiver) {
              return null;
            }

            return (
              <SyRequestListItem
                {...request}
                incoming={false}
                key={request.id}
              ></SyRequestListItem>
            );
          })}
        <SySpace mode="page"></SySpace>
      </ScrollView>
    </SyPage>
  );
}

const styles = stylize({});
