import { View } from "react-native";
import SyPageModal from "../widgets/SyPageModal";
import SyBrandSlogan from "../widgets/SyBrandSlogan";
import SyButton from "../widgets/SyButton";
import { make, stylize } from "../lib/style";
import { makeColumn } from "../themes/components";
import SyText from "../widgets/SyText";
import SyIcon from "../widgets/SyIcon";
import { useReportUser } from "../queries/profile";

export default function ReportSuccessPage({ navigation, route }) {
  return (
    <SyPageModal authRequired={false} showExit={false}>
      <View style={styles.modalContent}>
        <SyIcon
          name="alert-decagram-outline"
          style={styles.alertStyle}
        ></SyIcon>
        <SyText
          style={styles.successMessage}
          content="Başarıyla iletildi"
        ></SyText>
      </View>
      <SyButton
        style={styles.successButton}
        labelStyle={styles.successButtonLabel}
        onPress={() => navigation.navigate("Search")}
        label="İlanlara git"
      ></SyButton>
      <SyButton
        style={styles.successButton}
        labelStyle={styles.successButtonLabel}
        onPress={() =>
          navigation.navigate("UserDetail", { id: route.params.userId })
        }
        label="Üyeyi görüntüle"
      ></SyButton>
    </SyPageModal>
  );
}

const styles = stylize({
  modalContent: makeColumn().padding("m").itemsCenter().gap("s"),
  successMessage: make().textCenter(),
  successButton: make().background("secondary"),
  successButtonLabel: make().font("m", "l", "white"),
  alertStyle: make().font("xxl", "m", "secondary"),
});
