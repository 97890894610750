import { useEffect, useState } from "react";
import { Pressable, View } from "react-native";
import { make, stylize } from "../lib/style";
import { errorVar } from "../queries/cache";
import { makeBox, makeError } from "../themes/components";
import SyIcon from "./SyIcon";
import SyText from "./SyText";

export default function SyAlert({ error }) {
  const [visible, changeVisible] = useState(true);
  const [timeout, changeTimeout] = useState(0);

  const arr = Array.isArray(error) ? error : [error];

  useEffect(() => {
    if (!visible) {
      return;
    }

    if (timeout) {
      clearTimeout(timeout);
    }

    const timeoutId = setTimeout(() => {
      errorVar(null);
      changeVisible(false);
    }, 5000);

    changeTimeout(timeoutId);
  }, [visible]);

  const clearError = () => {
    errorVar(null);
    changeVisible(false);
  };

  const err = arr?.[0];

  const icon = error.type === "success" ? "check" : "alert-circle";
  const style = error.type === "success" ? styles.success : styles.error;

  return (
    visible && (
      <View style={styles.container}>
        <View style={[styles.body, style]}>
          <View style={styles.text}>
            <SyIcon name={icon} style={styles.icon}></SyIcon>
            <SyText style={styles.message} content={err.message}></SyText>
          </View>
          <Pressable onPress={clearError}>
            <SyIcon name="close" style={styles.icon}></SyIcon>
          </Pressable>
        </View>
      </View>
    )
  );

  /*return (
    visible && (
      <View style={styles.container}>
        <View style={styles.error}>
          {arr &&
            arr.map((err, index) => {
              return (
                <View style={styles.text} key={index}>
                  <SyIcon name="alert-circle" style={styles.icon}></SyIcon>
                  <SyText style={styles.message} content={err.message}></SyText>
                </View>
              );
            })}
          <Pressable onPress={clearError}>
            <SyIcon name="close" style={styles.icon}></SyIcon>
          </Pressable>
        </View>
      </View>
    )
  );*/
}

const styles = stylize({
  container: makeError().center(),
  body: makeBox().row().width("carousel").itemsCenter().spaceBetween(),
  error: make().background("secondary"),
  success: make().background("primary"),
  message: make().color("light"),
  icon: make().font("icon", "l", "light"),
  text: make().row().gap("m").itemsCenter(),
});
