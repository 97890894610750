import { Platform } from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';

const Storage = Platform.OS === 'web' ? localStorage : AsyncStorage;

export async function setItem(key,value){
    await Storage.setItem(key,value);
}

export async function getItem(key){
    return await Storage.getItem(key);
}

export async function removeItem(key){
    await Storage.removeItem(key);
}