import { gMutation, gQuery, gSubscription } from "../lib/graphql";
import { navigate } from "../lib/navigation";
import {
  acceptRequestMutation,
  cancelRequestMutation,
  confirmDeliveryRequestMutation,
  confirmReceivalRequestMutation,
  createRequestMutation,
  declineRequestMutation,
  fetchProfileOutgoingRequestsQuery,
  receiverCompleteMutation,
  requestQuery,
  requestStateChangedSubscription,
  senderCompleteMutation,
} from "./gql";

export function useCreateRequest(cb) {
  return gMutation(createRequestMutation, {
    refetchQueries: [{ query: fetchProfileOutgoingRequestsQuery }],
    onCompleted() {
      navigate("Requests", {
        screen: "OutgoingRequests",
      });
    },
  });
}

export function useRequest(id) {
  return gQuery(requestQuery, { variables: { id } });
}

export function useRequestStateChanged(id) {
  return gSubscription(requestStateChangedSubscription, {
    variables: {
      id,
    },
  });
}

export function useAcceptRequest(id) {
  return gMutation(acceptRequestMutation);
}

export function useDeclineRequest(id) {
  return gMutation(declineRequestMutation);
}

export function useConfirmDeliveryRequest(id) {
  return gMutation(confirmDeliveryRequestMutation);
}

export function useConfirmReceivalRequest(id) {
  return gMutation(confirmReceivalRequestMutation);
}

export function useCancelRequest(id) {
  return gMutation(cancelRequestMutation);
}

export function useCompleteSenderRequest(id) {
  return gMutation(senderCompleteMutation);
}

export function useCompleteReceiverRequest(id) {
  return gMutation(receiverCompleteMutation);
}
