import { useNavigation } from "@react-navigation/native";
import { Image, View } from "react-native";
import { make, stylize } from "../lib/style";
import { formatDateTime, profileVar } from "../queries/cache";
import { makeListItem } from "../themes/components";
import SyButton from "./SyButton";
import SyIcon from "./SyIcon";
import SyText from "./SyText";
import { useDeleteNotification } from "../queries/operations/notification";

const SAYKL_LOGO_LIGHT = require("../assets/small-icon.png");

export default function SyNotificationListItem({
  id,
  createdAt,
  title,
  content,
  border = true,
}) {
  const profile = profileVar();
  const navigation = useNavigation();
  const [deleteNotification] = useDeleteNotification();
  
  const SAYKL_LOGO = SAYKL_LOGO_LIGHT;

  const datetime = formatDateTime(new Date(createdAt));

  const onPress = async () => {
    await deleteNotification({
      variables: { id },
      update(cache) {
        const normalizedId = cache.identify({ id, __typename: "Notification" });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
  };

  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <Image source={SAYKL_LOGO} style={styles.image}></Image>
      </View>
      <View style={styles.content}>
        <View style={styles.header}>
          <SyText content={title} style={styles.userName} lines={1}></SyText>
          <SyText content={datetime} style={styles.date}></SyText>
        </View>
        <View style={styles.footer}>
          <SyText content={content} lines={1}></SyText>
          <SyButton icon="delete" onPress={onPress}></SyButton>
        </View>
      </View>
    </View>
  );
}

const listItem = makeListItem();

listItem.header.spaceBetween();
listItem.container.margin("s");
listItem.content.center();

const styles = stylize({
  ...listItem,
  userName: make().font("l", "l"),
  date: make().font("m"),
  count: make()
    .font("l")
    .set((theme) => ({
      textAlign: "center",
      position: "absolute",
      bottom: 0,
      right: 0,
      width: 24,
      height: 24,
      borderRadius: 12,
      backgroundColor: theme.colors.primary,
      color: theme.colors.light,
    })),
  footer: make().row().spaceBetween().itemsCenter(),
  newMessage: make().background("mint"),
  newMessageIcon: make().font("l", "m", "primary"),
});
