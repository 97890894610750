import { useNavigation } from "@react-navigation/native";
import { useEffect, useRef, useState } from "react";
import {
  Image,
  Modal,
  Platform,
  Pressable,
  ScrollView,
  View,
} from "react-native";
import * as Sharing from "expo-sharing";
import ImageViewer from "react-native-image-zoom-viewer";
import { make, stylize } from "../lib/style";
import { pickImage } from "../lib/util";

import SyButton from "./SyButton";
import { useToggleFavorite } from "../queries/product";
import { useScreenState } from "../lib/screen";
import SyText from "./SyText";
import { useReactiveVar } from "@apollo/client";
import { cameraImageVar } from "../queries/cache";

const BADGE = require("../assets/badge.png");

export default function SyCarousel({
  images,
  changeImages,
  isFavorite = false,
  showFavorite = true,
  favoritedByUsers,
  id = "",
  showBadge = false,
}) {
  const { state, style, contentWidth } = useScreenState();
  const navigation = useNavigation();
  const scrollRef = useRef();
  const [toggleFavorite] = useToggleFavorite();
  const cameraImage = useReactiveVar(cameraImageVar);
  const [zoomed, changeZoomed] = useState({ image: null, index: null });
  useEffect(() => {
    if (cameraImage && changeImages) {
      changeImages([...images, cameraImage]);
      cameraImageVar(null);
    }
  }, [cameraImage]);

  let imageContainer;
  if (state === "tablet" || state === "large") {
    imageContainer = make().set({
      width: contentWidth / 2,
    }).style;
  } else {
    imageContainer = style;
  }

  const addImage = async () => {
    const image = await pickImage();
    if (image) {
      changeImages([...images, image]);
    }
  };

  const takePhoto = () => {
    navigation.navigate("Camera");
  };

  const removeImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    changeImages(newImages);
  };

  const setFavorite = () => {
    toggleFavorite({ variables: { productId: id } });
  };

  const share = async () => {
    const available = await Sharing.isAvailableAsync();

    if (available) {
      Sharing.shareAsync("https://saykl.com/ilanlar/" + id);
    }
  };

  const handleImagePress = (image, index) => {
    changeZoomed({ image, index });
  };

  const zoomedImages = images.map((image) => ({
    url: image,
    props: {},
  }));

  let carouselWidth = "90%";
  if (Platform.OS === "web") {
    carouselWidth = 500;
  }

  const modelContentStyle = make().set({ width: carouselWidth }).style;

  return (
    <>
      <ScrollView
        ref={scrollRef}
        horizontal={true}
        pagingEnabled={true}
        style={style}
        onContentSizeChange={() => {
          if (!changeImages) {
            return;
          }

          const x = (images.length - 1) * contentWidth;
          scrollRef.current.scrollTo({ x, y: 0, animated: true });
        }}
      >
        {images.map((image, index) => (
          <View style={[styles.imageContainer, imageContainer]} key={index}>
            <Pressable
              style={styles.imageWrapper}
              onPress={() => handleImagePress(image, index)}
            >
              <Image source={{ uri: image }} style={styles.image}></Image>
              {changeImages && (
                <SyButton
                  icon="delete"
                  onPress={() => removeImage(index)}
                  style={styles.removeButtonStyle}
                ></SyButton>
              )}
              {changeImages && (
                <SyButton
                  icon="plus"
                  onPress={addImage}
                  style={styles.addButtonStyle}
                ></SyButton>
              )}
              {!changeImages && showFavorite && (
                <View style={styles.favoriteSection}>
                  <SyText
                    content={favoritedByUsers}
                    style={styles.favoritedByUsers}
                  ></SyText>
                  <SyButton
                    icon="heart"
                    solid={isFavorite ? true : false}
                    iconStyle={styles.favorite}
                    onPress={setFavorite}
                    authRequired={true}
                  ></SyButton>
                </View>
              )}
              {!changeImages && Platform.OS === "web" && (
                <SyButton
                  icon="share"
                  onPress={() => share()}
                  style={styles.addButtonStyle}
                  iconStyle={styles.share}
                ></SyButton>
              )}
              {showBadge && (
                <View style={styles.triangle}>
                  <Image source={BADGE} style={styles.badgeImage}></Image>
                </View>
              )}
              <SyText
                style={styles.counter}
                content={index + 1 + "/" + images.length}
              ></SyText>
            </Pressable>
          </View>
        ))}

        {changeImages && (
          <View style={[styles.newImage, style]}>
            <SyButton
              label="Galeriden Seç"
              onPress={addImage}
              style={styles.buttonStyle}
              labelStyle={styles.labelStyle}
            ></SyButton>
            <SyButton
              label="Fotoğraf Çek"
              onPress={takePhoto}
              style={styles.buttonStyle}
              labelStyle={styles.labelStyle}
            ></SyButton>
          </View>
        )}
      </ScrollView>

      <Modal
        transparent={true}
        visible={zoomed.index !== null}
        animationType="fade"
      >
        <View style={styles.modalContainer}>
          <View style={[styles.modalContent, modelContentStyle]}>
            <ImageViewer
              imageUrls={zoomedImages}
              index={zoomed.index}
            ></ImageViewer>
            <SyButton
              icon="times"
              onPress={() => changeZoomed({ url: null, index: null })}
              style={styles.modalCancel}
              iconStyle={styles.modalCancelIconStyle}
            ></SyButton>
          </View>
        </View>
      </Modal>

      {/*state === "large" && zoomed && (
        <Modal transparent={true} animationType="fade">
          <View style={styles.modalContainer}>
            <View style={styles.modalContent}>
              <Image
                source={{ uri: zoomed }}
                style={styles.zoomedImage}
              ></Image>
              <SyButton
                icon="times"
                onPress={() => changeZoomed(null)}
                style={styles.modalCancel}
                iconStyle={styles.modalCancelIconStyle}
              ></SyButton>
            </View>
          </View>
        </Modal>
      )*/}
    </>
  );
}

const styles = stylize({
  imageWrapper: make().flex(1),
  imageContainer: make().row().ratio(1).padding("l"),
  image: make().radius("l").ratio(1).flex(1),
  newImage: make()
    .radius("l")
    .row()
    .ratio(1)
    .itemsCenter()
    .gap("m")
    .padding("m"),
  removeButtonStyle: make().margin("m").set({
    position: "absolute",
    right: 0,
    top: 0,
  }),
  addButtonStyle: make().margin("m").set({
    position: "absolute",
    right: 0,
    bottom: 0,
  }),
  buttonStyle: make().background("light").flex(1),
  labelStyle: make().font("m", "l"),
  favorite: make().font("starSize", "m", "primary"),
  share: make().font("x", "m", "primary"),
  favoriteSection: make().margin("m").row("m").itemsCenter().set({
    position: "absolute",
    right: 0,
    top: 0,
  }),
  /*favoriteButton: make().margin("m").set({
    position: "absolute",
    right: 0,
    top: 0,
  })*/
  counter: make()
    .absolutePosition()
    .set({
      top: 0,
      left: 0,
    })
    .font("sm", "m", "lightGrey")
    .margin("s")
    .padding("s", false, true)
    .radius("m")
    .background("lightGrey4"),
  zoomedImage: make().fullSize().radius("l"),
  modalCancel: make()
    .set({
      position: "absolute",
      bottom: -64,
      left: "50%",
      width: 48,
      height: 48,
      borderRadius: 24,
      transform: { translateX: -24 },
    })
    .shadow(1),
  modalCancelIconStyle: make().font("cancelIcon", "l", "primary"),
  modalContainer: make()
    .column()
    .flex(1)
    .itemsCenter()
    .center()
    .background("black"),
  modalContent: make()
    .selfCenter()
    .radius("l")
    .overflowVisible()
    .column()
    .ratio(1),
  triangle: make().set({
    position: "absolute",
    left: 0,
    bottom: 0,
  }),
  favoritedByUsers: make()
    .background("primaryBackground")
    .padding("s")
    .radius("m")
    .margin("t", false, true),
  badgeImage: make().set({
    width: 64,
    height: 64,
  }),
});

/*
<SyButton
            label="Fotoğraf Çek"
            onPress={takePhoto}
            style={styles.buttonStyle}
            labelStyle={styles.labelStyle}
          ></SyButton>
          */
