import { Text } from "react-native";
import { make, stylize } from "../lib/style";

export default function SyText({ content, style, lines }) {
  const ellipsis = lines === 1 ? 'tail' : null;
  return (
    <Text style={[styles.text, style]} numberOfLines={lines} ellipsizeMode={ellipsis}>
      {content}
    </Text>
  );
}

const styles = stylize({
  text: make().font(),
});
