import { ScrollView, Switch, View } from "react-native";
import SyPage from "../widgets/SyPage";
import SyText from "../widgets/SyText";
import SyTitle from "../widgets/SyTitle";
import { make, stylize } from "../lib/style";
import { makeColumn, makeRow } from "../themes/components";
import SyButton from "../widgets/SyButton";
import { useEffect, useState } from "react";
import SyModalInput from "../widgets/SyModalInput";
import { useDeleteProfile, useSaveProfileSettings } from "../queries/profile";
import { loggedInVar, profileVar } from "../queries/cache";
import { useReactiveVar } from "@apollo/client";
import { removeToken } from "../storage/token";
import { clearCache } from "../lib/graphql";
import SySwitch from "../widgets/SySwitch";

const colors = make().theme.colors;
const primary = colors.primary;
const inactive = colors.lightGrey3;
const thumb = colors.light;

function SySetting({ title, children }) {
  return (
    <View style={styles.setting}>
      <SyText style={styles.settingTitle} content={title}></SyText>
      {children}
    </View>
  );
}

export default function SettingsPage({ navigation }) {
  const profile = useReactiveVar(profileVar);
  const settings = profile?.settings || {
    lastnameHidden: false,
    phoneHidden: false,
    seenHidden: false,
    notificationsEnabled: false,
    emailEnabled: false,
    adsEnabled: false,
  };

  const [saveSettings] = useSaveProfileSettings();
  const [deleteProfile] = useDeleteProfile();
  const [systemTheme, changeSystemTheme] = useState(false);
  const [darkTheme, changeDarkTheme] = useState(false);
  const [lastnameHidden, changeLastnameHidden] = useState(
    settings.lastnameHidden
  );
  const [phoneHidden, changePhoneHidden] = useState(settings.phoneHidden);
  const [seenHidden, changeSeenHidden] = useState(settings.seenHidden);
  const [notificationsEnabled, changeNotificationsEnabled] = useState(
    settings.notificationsEnabled
  );
  const [emailEnabled, changeEmailEnabled] = useState(settings.emailEnabled);
  const [adsEnabled, changeAdsEnabled] = useState(settings.adsEnabled);
  const [blockedAccountsVisible, changeBlockedAccountsVisible] =
    useState(false);
  const [deleteAccountVisible, changeDeleteAccountVisible] = useState(false);

  useEffect(() => {
    if (settings.theme === "auto") {
      changeSystemTheme(true);
    } else {
      changeSystemTheme(false);
      if (settings.theme === "light") {
        changeDarkTheme(false);
      } else {
        changeDarkTheme(true);
      }
    }

    changeLastnameHidden(settings.hideLastname);
    changePhoneHidden(settings.hidePhone);
    changeSeenHidden(settings.hideSeen);
    changeNotificationsEnabled(settings.notificationsEnabled);
    changeEmailEnabled(settings.emailEnabled);
    changeAdsEnabled(settings.adsEnabled);
  }, [settings]);

  const saveSingleSetting = (key, value, cb) => {
    const data = {
      settings: {
        [key]: value,
      },
    };
    profileVar({
      settings: {
        ...profile.settings,
        [key]: value,
      },
    });
    saveSettings({
      variables: data,
    });
    cb(!!value);
  };

  const isLastnameDisabled = () => {
    return profile?.name?.split(" ").length === 1;
  };

  const deleteAccount = async () => {
    await deleteProfile();
    await removeToken();
    loggedInVar(false);
    clearCache();
    navigation.replace("Main", { screen: "Profile" });
  };

  return (
    <SyPage authRequired={false}>
      <ScrollView>
        <View style={styles.page}>
          <SyTitle content="Ayarlar"></SyTitle>
          <SyTitle content="Bildirimler"></SyTitle>
          <SySetting title="Bildirimlere izin ver">
            <SySwitch
              value={notificationsEnabled}
              changeValue={(value) =>
                saveSingleSetting(
                  "notificationsEnabled",
                  value,
                  changeNotificationsEnabled
                )
              }
            ></SySwitch>
          </SySetting>

          <SySetting title="E-posta ile bilgilendir">
            <SySwitch
              value={emailEnabled}
              changeValue={(value) =>
                saveSingleSetting("emailEnabled", value, changeEmailEnabled)
              }
            ></SySwitch>
          </SySetting>

          <SyTitle content="Gizlilik"></SyTitle>
          <SySetting title="Soyadımı gizle">
            <SySwitch
              disabled={isLastnameDisabled()}
              value={lastnameHidden}
              changeValue={(value) =>
                saveSingleSetting("hideLastname", value, changeLastnameHidden)
              }
            ></SySwitch>
          </SySetting>
          <SySetting title="Telefon numaramı gizle">
            <SySwitch
              value={phoneHidden}
              changeValue={(value) =>
                saveSingleSetting("hidePhone", value, changePhoneHidden)
              }
            ></SySwitch>
          </SySetting>
          <SySetting title="Mesaj okundu bilgisi verme">
            <SySwitch
              value={seenHidden}
              changeValue={(value) =>
                saveSingleSetting("hideSeen", value, changeSeenHidden)
              }
            ></SySwitch>
          </SySetting>

          <SyTitle content="Bağlantılar"></SyTitle>

          <SySetting title="Engellenen hesaplar">
            <SyButton
              label="Göster"
              onPress={() => changeBlockedAccountsVisible(true)}
            ></SyButton>
          </SySetting>

          <SyTitle content="Hesap"></SyTitle>
          <SySetting title="Hesabımı silmek istiyorum">
            <SyButton
              label="Hesabı Sil"
              onPress={() => changeDeleteAccountVisible(true)}
            ></SyButton>
          </SySetting>
        </View>
        <SyModalInput
          visible={blockedAccountsVisible}
          changeVisible={changeBlockedAccountsVisible}
          showExit={true}
        >
          <SyText content="fetch"></SyText>
        </SyModalInput>

        <SyModalInput
          visible={deleteAccountVisible}
          changeVisible={changeDeleteAccountVisible}
          showExit={true}
        >
          <View style={styles.modalContent}>
            <SyTitle content="Emin miyiz?" noCaps={true}></SyTitle>
            <View style={styles.centerText}>
              <SyText content="Tüm verilerin kalıcı olarak silinecektir."></SyText>
              <SyText content="Onaylıyor musun?"></SyText>
            </View>
          </View>
          <SyButton
            style={styles.successButton}
            labelStyle={styles.successButtonLabel}
            label="Onaylıyorum"
            onPress={deleteAccount}
          ></SyButton>
        </SyModalInput>
      </ScrollView>
    </SyPage>
  );
}

/*
<SyTitle content="Görünüş"></SyTitle>
<SySetting title="Telefonum ile aynı">
  <SySwitch
    value={systemTheme}
    changeValue={(value) =>
      saveSingleSetting(
        "theme",
        value ? "auto" : darkTheme ? "dark" : "light",
        changeSystemTheme
      )
    }
  ></SySwitch>
</SySetting>

<SySetting title="Koyu renk seçimi">
  <SySwitch
    disabled={systemTheme}
    value={darkTheme}
    changeValue={(value) =>
      saveSingleSetting(
        "theme",
        value ? "dark" : "light",
        changeDarkTheme
      )
    }
  ></SySwitch>
</SySetting>
*/

/*
<SySetting title="Kampanyalardan haberdar et">
            <SySwitch
              value={adsEnabled}
              changeValue={(value) =>
                saveSingleSetting("adsEnabled", value, changeAdsEnabled)
              }
            ></SySwitch>
          </SySetting>

*/

const styles = stylize({
  page: makeColumn("m").padding("l"),
  setting: makeRow().spaceBetween().itemsCenter(),
  settingTitle: make().font("l", "m", "main"),
  modalContent: make().itemsCenter().padding("m").gap("s"),
  successButton: make().background("primary"),
  successButtonLabel: make().font("m", "l", "light"),
  centerText: make().textCenter(),
});
