import { ScrollView } from "react-native";
import SyPage from "../widgets/SyPage";
import SyInputText from "../widgets/SyInputText";
import { profileVar } from "../queries/cache";
import { useState } from "react";
import SyTitle from "../widgets/SyTitle";
import { make, stylize } from "../lib/style";
import { makeBox, makeColumn } from "../themes/components";
import { uuid } from "../lib/util";
import { useCreateChat } from "../queries/chat";
import { useProductBasic } from "../queries/product";

export default function NewChatPage({ route, navigation }) {
  const chatId = route.params.chatId;
  const [newMessage, changeNewMessage] = useState("");
  const [createChat] = useCreateChat();
  const productRet = useProductBasic(route.params.productId);

  const product = productRet?.data?.product;
  let otherId;
  let title; 

  if (route.params.otherId) {
    otherId = route.params.otherId;
    title = "saykl'a sorun bildir:";
  } else if (product) {
    otherId = product.owner.id;
    title = "İlan sahibine mesaj gönder:";
  }

  const sendMessage = async () => {
    if (!newMessage) {
      return;
    }

    if (!chatId) {
      const chatId = uuid();
      await createChat({
        variables: {
          id: chatId,
          productId: route.params.productId,
          message: newMessage,
          otherId: otherId,
        },
      });
      changeNewMessage("");
      navigation.replace("ChatDetail", { id: chatId });
    }
  };

  const ACTIONS = [
    {
      label: "Gönder",
      authRequired: true,
      onPress: sendMessage,
    },
  ];

  return (
    <SyPage actions={ACTIONS}>
      <ScrollView contentContainerStyle={styles.page}>
        <SyTitle content={title} noCaps={true}></SyTitle>
        <SyInputText
          style={styles.input}
          value={newMessage}
          onChangeText={changeNewMessage}
          multiline={true}
          placeholder="Mesaj yaz"
        ></SyInputText>
      </ScrollView>
    </SyPage>
  );
}

const styles = stylize({
  content: makeColumn("s"),
  input: make().set({
    height: 120,
  }),
  light: make().color("lightGrey3"),
  profileButton: make()
    .background("primary")
    .set({
      width: 42,
      height: 42,
      borderRadius: 21,
    })
    .center()
    .itemsCenter()
    .shadow(1),
  detail: makeBox(),
  page: makeColumn("m")
    .padding("m")
    .set((theme) => ({
      marginBottom: theme.size.l,
    })),
});
