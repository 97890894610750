import SyStoreBadges from "../widgets/SyStoreBadges";
import { make, stylize } from "../lib/style";
import SyModal from "../widgets/SyModal";

import { View } from "react-native";
import SyBrandSlogan from "../widgets/SyBrandSlogan";
export default function DownloadPage() {
  return (
    <SyModal showExit={true}>
      <View style={styles.container}>
        <SyBrandSlogan></SyBrandSlogan>
        <SyStoreBadges vertical={true}></SyStoreBadges>
      </View>
    </SyModal>
  );
}

const styles = stylize({
  container: make().column().gap("xl").padding("l", true, false),
  logo: make().selfCenter().ratio(2.2).width("authIcon").set({
    resizeMode: "contain",
  }),
});
