import * as React from "react";
import { Svg, Path } from "react-native-svg";

export default function SyIconUser(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 94.791 112.56"
      {...props}
    >
      <Path
        fill={props.color}
        d="M47.394 0C31.034 0 17.77 13.264 17.77 29.624s13.263 29.623 29.624 29.623 29.623-13.262 29.623-29.623C77.017 13.264 63.754 0 47.394 0zm0 11.85c9.816 0 17.774 7.958 17.774 17.774 0 9.815-7.958 17.773-17.774 17.773-9.817 0-17.774-7.958-17.774-17.773 0-9.816 7.957-17.774 17.774-17.774zm0 53.322c-11.813 0-23.133 1.506-31.73 5.324C6.901 74.387-.003 81.252-.003 91.832c0 2.765.505 5.912 2.386 8.913 1.881 3 4.77 5.27 8.503 6.959 7.163 3.24 18.639 4.865 36.508 4.865s29.345-1.624 36.507-4.865c3.732-1.689 6.622-3.96 8.503-6.96 1.881-3 2.386-6.146 2.386-8.912 0-10.58-6.903-17.445-15.667-21.336-8.597-3.818-19.918-5.324-31.73-5.324zm0 11.85c11.092 0 20.507 1.455 26.922 4.303 6.246 2.774 8.626 6.276 8.626 10.507 0 1.325-.235 2.075-.576 2.619-.34.544-1.154 1.465-3.347 2.457-4.686 2.12-13.946 3.811-31.624 3.811s-26.937-1.691-31.623-3.81c-2.193-.993-3.006-1.914-3.347-2.458-.341-.544-.576-1.294-.576-2.619 0-4.23 2.38-7.733 8.626-10.507 6.414-2.847 15.829-4.304 26.922-4.304z"
      />
    </Svg>
  );
}
