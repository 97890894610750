import { View } from "react-native";
import SyText from "./SyText";
import SyTitle from "./SyTitle";

export default function SyField({ style, title, content, lines = 1 }) {
  return (
    <View style={style}>
      <SyTitle content={title}></SyTitle>
      <SyText content={content} lines={lines}></SyText>
    </View>
  );
}
