import { ScrollView } from "react-native";
import { stylize } from "../lib/style";
import { useProfileChats } from "../queries/profile";
import SyChatListItem from "../widgets/SyChatListItem";
import SyNA from "../widgets/SyNA";
import SyPage from "../widgets/SyPage";
import { clearNotifications } from "../lib/notification";
import SySpace from "../widgets/SySpace";

export default function MemberMessagesPage() {
  const { loading, data, refetch } = useProfileChats();

  clearNotifications("UserMessage");

  const chats = data?.profile?.chats;

  if (!chats || !chats.length) {
    return <SyNA loading={loading}></SyNA>;
  }

  return (
    <SyPage
      loading={loading}
      authRequired={true}
      refetch={refetch}
      data={data}
    >
      <ScrollView>
        {chats &&
          chats.map((chat, index) => {
            return <SyChatListItem {...chat} key={chat.id}></SyChatListItem>;
          })}
        <SySpace mode="page"></SySpace>
      </ScrollView>
    </SyPage>
  );
}
