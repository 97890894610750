import { forwardRef, useState } from "react";
import { Platform, TextInput } from "react-native";
import { make, stylize } from "../lib/style";
import { makeBox } from "../themes/components";

export default forwardRef(function SyInputText(props, ref) {
  const [focused, changeFocus] = useState(false);
  const style = [
    styles.input,
    props.style,
    focused ? styles.borderPrimary : null,
    Platform.OS !== "android" ? styles.outlineNone : null,
  ];

  return (
    <TextInput
      {...props}
      ref={ref}
      style={style}
      placeholderTextColor={placeholderColor}
      onFocus={() => changeFocus(true)}
      onBlur={() => changeFocus(false)}
    ></TextInput>
  );
});

const placeholderColor = make().theme.colors.placeholder;

const styles = stylize({
  input: makeBox(false)
    .background("inputBackground")
    .shadow(1)
    .color("main")
    .border("u")
    .borderColor("lightGrey3"),
  borderPrimary: make()
    .border("u")
    .set((theme) => ({
      borderColor: theme.colors.primary,
    })),
  outlineNone: make().set({
    outlineWidth: 0,
  }),
  /*outlinePrimary: make().set((theme) => ({
    outlineStyle: "solid",
    outlineColor: theme.colors.primary,
    outlineWidth: 1,
  })),*/
});
