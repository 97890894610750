import { useState } from "react";
import { View } from "react-native";
import { fillEmpty, invalidEmail } from "../lib/error";

import { make, stylize } from "../lib/style";
import { useSignup } from "../queries/profile";
import { makeColumn, makeRow } from "../themes/components";

import SyButton from "../widgets/SyButton";
import SyInputText from "../widgets/SyInputText";
import SyText from "../widgets/SyText";
import SyPageModal from "../widgets/SyPageModal";
import SyBrandSlogan from "../widgets/SyBrandSlogan";
import { REGEX_EMAIL } from "../lib/regex";
import SyBoarding from "../widgets/SyBoarding";

export default function SignupPage({ navigation }) {
  const [name, changeName] = useState("");
  const [email, changeEmail] = useState("");
  const [password, changePassword] = useState("");
  const [signup, response] = useSignup();
  const [loading, setLoading] = useState(false);
  const [signupSuccess, changeSignupSuccess] = useState(false);

  const onPress = async () => {
    if (loading) {
      return;
    }

    if (!email || !password || !name) {
      fillEmpty();
      return;
    }

    if (!email.match(REGEX_EMAIL)) {
      invalidEmail();
      return;
    }

    setLoading(true);

    const res = await signup({
      variables: {
        name: name,
        email: email,
        password: password,
      },
    });

    setLoading(false);

    if (!res.errors && !res.error) {
      changeSignupSuccess(true);
    }
  };

  return (
    <>
      {!signupSuccess && (
        <SyPageModal graphql={response} authRequired={false} showExit={true}>
          <SyBrandSlogan></SyBrandSlogan>
          <SyInputText
            placeholder="Ad Soyad"
            value={name}
            onChangeText={changeName}
            autoComplete="off"
          ></SyInputText>
          <SyInputText
            placeholder="E-posta"
            value={email}
            onChangeText={changeEmail}
            autoComplete="off"
          ></SyInputText>
          <SyInputText
            placeholder="Şifre"
            value={password}
            secureTextEntry={true}
            onChangeText={changePassword}
            autoComplete="off"
          ></SyInputText>

          <View>
            <View style={styles.terms}>
              <SyButton
                style={styles.linkButton}
                labelStyle={styles.linkButtonLabel}
                label="Gizlilik Politikası"
                lines={1}
                navigate="PrivacyStatement"
              ></SyButton>
              <SyText style={styles.text} content="ve" lines={1}></SyText>

              <SyButton
                style={styles.linkButton}
                labelStyle={styles.linkButtonLabel}
                label="Kullanıcı Sözleşmesi"
                navigate="UsersAgreement"
                lines={1}
              ></SyButton>
            </View>
            <SyText
              style={styles.text}
              content="kaydolduğunda okunup onaylanmış sayılır."
            ></SyText>
          </View>
          <View style={styles.actions}>
            <SyButton
              label="Kaydol"
              onPress={onPress}
              style={styles.loginButton}
              labelStyle={styles.loginButtonLabel}
            ></SyButton>
            <SyButton
              label="Giriş Yap"
              navigate="Login"
              replace={true}
              style={styles.signupButton}
              labelStyle={styles.signupButtonLabel}
            ></SyButton>
          </View>
        </SyPageModal>
      )}
      {signupSuccess && <SyBoarding></SyBoarding>}
    </>
  );
}

const styles = stylize({
  container: make().column().gap("s"),
  logo: make()
    .selfCenter()
    .ratio(2.2)
    .width("authIcon")
    .set({
      resizeMode: "contain",
    })
    .margin("l", true, false),
  loginButton: make().flex(1).background("primary"),
  loginButtonLabel: make().font("m", "l", "light"),
  signupButton: make().flex(1).background("light"),
  signupButtonLabel: make().font("m", "l", "main"),
  actions: makeRow("m").spaceBetween(),
  terms: makeRow("s").itemsCenter().center(),
  text: make().color("lightGrey3").textCenter(),
  linkButtonLabel: make().color("primary"),
  linkButton: make()
    .background("transparent")
    .border("n")
    .shadow(0)
    .padding("n"),
  circle: make().color("lightGrey2"),
});
