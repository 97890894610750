import { Pressable } from "react-native";
import { make, stylize } from "../lib/style";
import { getAuthPress } from "../lib/util";
import SyIcon from "./SyIcon";
import SyText from "./SyText";

// if disabled cancel onPress

export default function SyButton({
  style,
  iconStyle,
  labelStyle,
  label,
  icon,
  solid,
  onPress,
  authRequired,
  redirect,
  redirectParent,
  params,
  navigate,
  replace,
  disabled,
}) {
  const onpress = getAuthPress(
    onPress,
    authRequired,
    redirect,
    redirectParent,
    params,
    navigate,
    replace
  );

  if (icon && label) {
    const stylez = [styles.iconLabelButton, style];

    if (disabled) {
      stylez.push(styles.disabled);
    }

    return (
      <Pressable onPress={onpress} style={stylez} disabled={disabled}>
        <SyIcon
          name={icon}
          style={[styles.icon, iconStyle]}
          solid={solid}
        ></SyIcon>
        <SyText content={label} style={labelStyle} lines={1}></SyText>
      </Pressable>
    );
  }

  if (icon) {
    const stylez = [styles.iconButton, style];

    if (disabled) {
      stylez.push(styles.disabled);
    }

    return (
      <Pressable onPress={onpress} style={stylez} disabled={disabled}>
        <SyIcon
          name={icon}
          style={[styles.icon, iconStyle]}
          solid={solid}
        ></SyIcon>
      </Pressable>
    );
  }

  const stylez = [styles.button, style];

  if (disabled) {
    stylez.push(styles.disabled);
  }

  return (
    <Pressable onPress={onpress} style={stylez} disabled={disabled}>
      <SyText content={label} style={labelStyle} lines={1}></SyText>
    </Pressable>
  );
}

const styles = stylize({
  button: make()
    .radius("m")
    .padding("s")
    .background("buttonBackground")
    .itemsCenter(),
  iconButton: make()
    .row()
    .itemsCenter()
    .center()
    .padding("n")
    .background("primaryBackground")
    .radius("m")
    .set((theme) => ({
      width: 36,
      height: 36,
    })),
  iconLabelButton: make()
    .row()
    .itemsCenter()
    .center()
    .background("light")
    .radius("m")
    .padding("s")
    .gap("s")
    .shadow(1),
  icon: make().font("xl", "m", "main").margin("n"),
  disabled: make().background("disable"),
});
