import { Svg, Path } from "react-native-svg";

export default function SyIconMessage(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 118.24 116.58"
      {...props}
    >
      <Path
        fillRule="evenodd"
        d="M59.118 10.749c-7.558 0-14.514.3-20.721.758-9.29.685-12.577 1.014-16.496 3.165-3.468 1.904-7.565 6.314-9.209 9.913-1.84 4.029-1.943 8.05-1.943 19.153v9.014c0 6.159.023 8.495.371 10.342a21.497 21.497 0 0 0 17.15 17.15c1.847.348 4.183.37 10.342.37h.171c.622 0 1.394-.001 2.148.103a10.751 10.751 0 0 1 7.723 5.093c.393.651.696 1.361.94 1.933l.067.158 2.467 5.756c1.943 4.532 3.257 7.585 4.4 9.7.927 1.717 1.423 2.175 1.483 2.236.703.318 1.51.318 2.215 0 .06-.06.555-.52 1.482-2.236 1.143-2.115 2.457-5.168 4.4-9.7l2.467-5.756.067-.158c.244-.572.547-1.282.94-1.933a10.749 10.749 0 0 1 7.723-5.093c.754-.104 1.526-.103 2.148-.102h.171c6.159 0 8.495-.023 10.342-.371a21.497 21.497 0 0 0 17.15-17.15c.348-1.847.37-4.183.37-10.342v-9.014c0-11.103-.103-15.124-1.943-19.153-1.643-3.598-5.74-8.01-9.209-9.913-3.919-2.151-7.205-2.48-16.495-3.165a282.239 282.239 0 0 0-20.721-.758zM37.607.789C44.057.312 51.275 0 59.117 0 66.96 0 74.18.313 80.63.788l.712.053c8.395.616 14.008 1.029 20.166 4.41 5.496 3.016 11.208 9.166 13.813 14.869 2.92 6.394 2.919 12.865 2.915 22.628V53.387c.001 5.306.002 8.731-.556 11.695a32.247 32.247 0 0 1-25.725 25.725c-2.964.558-6.389.558-11.696.557h-.634c-.43 0-.653 0-.816.005h-.02l-.009.018c-.068.148-.156.353-.325.748l-2.573 6.002c-1.81 4.224-3.313 7.733-4.716 10.33-1.354 2.505-3.175 5.296-6.146 6.748a13.436 13.436 0 0 1-11.802 0c-2.972-1.452-4.793-4.243-6.146-6.749-1.403-2.596-2.907-6.105-4.717-10.329l-2.572-6.002c-.17-.395-.258-.6-.326-.748l-.008-.018h-.02a35.4 35.4 0 0 0-.816-.005h-.635c-5.306 0-8.731.001-11.695-.557A32.246 32.246 0 0 1 .557 65.082C-.001 62.118 0 58.693 0 53.387V42.748c-.003-9.763-.005-16.234 2.915-22.628C5.52 14.417 11.233 8.268 16.73 5.25 22.887 1.87 28.5 1.457 36.895.84l.712-.052z"
        clipRule="evenodd"
        fill={props.color}
      />
    </Svg>
  );
}
