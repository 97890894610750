import { useEffect } from "react";
import { usePushNotifications } from "../lib/notification";
import {
  newNotificationSubscription,
  useNotifications,
} from "../queries/operations/notification";
import { useSavePushToken } from "../queries/profile";
import { notificationsVar } from "../queries/cache";

export default function SyNotification() {
  const { data, subscribeToMore } = useNotifications();
  const { expoPushToken } = usePushNotifications();
  const [savePushToken] = useSavePushToken();

  const notifications = data?.profile?.notifications;

  const processNotification = (notification) => {
    if (!notification) {
      return;
    }

    const notifications = notificationsVar();

    switch (notification.type) {
      case "UserMessage":
        notificationsVar({ ...notifications, Chats: true, UserMessage: true });
        break;
      case "SystemMessage":
        notificationsVar({
          ...notifications,
          Chats: true,
          SystemMessage: true,
        });
        break;
      case "ReceiverRequest":
        notificationsVar({
          ...notifications,
          Requests: true,
          ReceiverRequest: true,
        });
        break;
      case "SenderRequest":
        notificationsVar({
          ...notifications,
          Requests: true,
          SenderRequest: true,
        });
        break;
    }
  };

  useEffect(() => {
    subscribeToMore({
      document: newNotificationSubscription,
      updateQuery: (prev, { subscriptionData }) => {
        const newNotification = subscriptionData.data.newNotification;
        processNotification(newNotification);
      },
    });
  }, []);

  useEffect(() => {
    if (!expoPushToken) {
      return;
    }

    savePushToken({ variables: { token: expoPushToken } });
  }, [expoPushToken]);

  useEffect(() => {
    notifications?.forEach(processNotification);
  }, [notifications]);

  return null;
}
