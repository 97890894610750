import { View } from "react-native";
import { make, stylize } from "../lib/style";
import { makeRow } from "../themes/components";
import { Image } from "react-native";

import SyText from "./SyText";
import SyIcon from "./SyIcon";

export default function SyInformatique({ icon, image, text, margin }) {
  let marginStyle;
  if (margin) {
    marginStyle = make().margin("s").style;
  }

  return (
    <View style={[styles.container,marginStyle]}>
      {icon && <SyIcon name={icon} style={styles.icon}></SyIcon>}
      {image && <Image source={image} style={styles.image}></Image>}
      <SyText content={text} style={styles.text}></SyText>
    </View>
  );
}

const styles = stylize({
  container: makeRow("m")
    .padding("sm")
    .itemsCenter()
    .borderColor("primary")
    .border("u")
    .radius("m")
    .background("light"),
  image: make().ratio(1).set({
    width: 48,
  }),
  text: make().font("sm").flex(1),
  icon: make().font("xxl", "m", "primary"),
});
