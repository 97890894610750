import { useNavigation } from "@react-navigation/native";
import { Modal, ScrollView, View } from "react-native";
import { make, stylize } from "../lib/style";
import { makeError } from "../themes/components";
import SyButton from "./SyButton";
import SySpinner from "./SySpinner";
import SyAlert from "./SyAlert";
import { useReactiveVar } from "@apollo/client";
import { errorVar } from "../queries/cache";

export default function SyModal({ children, footer, loading, showExit }) {
  const navigation = useNavigation();
  const error = useReactiveVar(errorVar);
  const exit = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.replace("Main", { screen: "Search" });
    }
  };

  return (
    <Modal style={styles.page} transparent={true} animationType="fade">
      {error && <SyAlert error={error} />}
      {loading && <SySpinner style={styles.loading}/>}
      {!loading && (
        <ScrollView contentContainerStyle={styles.scroll}>
          <View style={styles.container}>
            {showExit && (
              <View style={styles.actions}>
                <SyButton
                  icon="times"
                  onPress={exit}
                  style={styles.exit}
                  iconStyle={styles.iconStyle}
                ></SyButton>
              </View>
            )}
            {children}
          </View>
          {footer}
        </ScrollView>
      )}
    </Modal>
  );
}

const styles = stylize({
  page: make().flex(1).set({
    minHeight:300
  }),
  scroll: make().background("modalBackground").flex(1).itemsCenter().center(),
  error: makeError(),
  loading: make().selfCenter(),
  container: make()
    .background("light")
    .shadow(1)
    .radius("l")
    .padding("m")
    .margin("m", true, false)
    .width("smallModal")
    .overflowVisible(),
  actions: make(),
  exit: make()
    .set({
      position: "absolute",
      right: -32,
      top: -32,
      width: 48,
      height: 48,
      borderRadius: 24,
    })
    .shadow(1),
  iconStyle: make().font("cancelIcon", "l", "primary"),
});
