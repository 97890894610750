import { useNavigation } from "@react-navigation/native";
import { useState } from "react";
import { Image, View } from "react-native";
import { SyIconCat } from "../icons/SyIconCat";
import { findRequestState } from "../lib/constants";
import { make, stylize } from "../lib/style";
import { useChat } from "../queries/chat";
import {
  useAcceptRequest,
  useCancelRequest,
  useCompleteReceiverRequest,
  useCompleteSenderRequest,
  useConfirmDeliveryRequest,
  useConfirmReceivalRequest,
  useDeclineRequest,
} from "../queries/request";
import { makeBox, makeColumn, makeRow } from "../themes/components";
import SyText from "../widgets/SyText";
import SyButton from "./SyButton";
import SyIcon from "./SyIcon";
import SyModalInput from "./SyModalInput";
import SyRequestListener from "./SyRequestListener";
import SyTitle from "./SyTitle";
import SyUserRating from "./SyUserRating";
import SyFieldInput from "./SyFieldInput";
import SyInputText from "./SyInputText";

function acceptButtonState(state) {
  if (state === "Waiting") {
    return false;
  }

  return true;
}

function declineButtonState(state) {
  if (state === "Waiting" || state === "Accepted" || state === "Delivered") {
    return false;
  }

  return true;
}

function deliveredButtonState(state) {
  if (state === "Accepted") {
    return false;
  }

  return true;
}

const sendMessageButtonState = (state) => {
  if (state === "Cancelled" || state === "Declined") {
    return true;
  }

  return false;
};

const cancelButtonState = (state) => {
  if (state === "Waiting" || state === "Accepted") {
    return false;
  }

  return true;
};

const reportButtonState = (state) => {
  if (state === "Delivered") {
    return false;
  }

  return true;
};

const receivedButtonState = (state) => {
  if (state === "Delivered") {
    return false;
  }

  return true;
};

const receiverModalVisible = (state, isReceiver, receiverComplete) => {
  return state === "Received" && isReceiver && !receiverComplete;
};

const senderModalVisible = (state, isSender, senderComplete) => {
  return state === "Received" && isSender && !senderComplete;
};

function getStateIcon(state) {
  switch (state) {
    case "Waiting":
      return "clock";
    case "Accepted":
      return "check-circle";
    case "Declined":
      return "minus-circle";
    case "Cancelled":
      return "arrow-u-left-top-bold";
    case "Delivered":
      return "truck-check";
    case "Received":
      return "hand-coin";
    case "Complete":
      return "rocket-launch";
  }
}

export default function SyRequestListItem({
  id,
  state,
  product,
  shipmentType,
  updatedDatetime,
  incoming,
  isSender,
  isReceiver,
  sender,
  receiver,
  senderCompleted,
  receiverCompleted,
}) {
  const navigation = useNavigation();
  const chatRet = useChat(null, product.id, sender.id, receiver.id);

  const [newState, changeNewState] = useState(state);
  const [newUpdatedDate, changeNewUpdatedDate] = useState(updatedDatetime);

  const [acceptRequest] = useAcceptRequest();
  const [declineRequest] = useDeclineRequest();
  const [cancelRequest] = useCancelRequest();
  const [confirmDelivery] = useConfirmDeliveryRequest();
  const [confirmReceival] = useConfirmReceivalRequest();
  const [completeReceiverRequest] = useCompleteReceiverRequest();
  const [completeSenderRequest] = useCompleteSenderRequest();
  const [rating, changeRating] = useState(0);
  const [requestReplyVisible, setRequestReplyVisible] = useState(false);
  const changeRequest = (data) => {
    const state = data?.requestStateChanged?.state;

    if (state) {
      changeNewState(state);
    }
  };

  const accept = () => {
    changeNewState("Accepted");
    acceptRequest({ variables: { id } });
    setRequestReplyVisible(false);
  };

  const decline = async () => {
    if (state === "Accepted") {
      changeNewState("Cancelled");
      cancelRequest({ variables: { id } });
    } else if (state === "Waiting") {
      changeNewState("Declined");
      declineRequest({ variables: { id } });
      setRequestReplyVisible(false);
    } else if (newState !== "Accepted") {
      const ret = await fetchSupportChat({
        variables: {
          productId: product.id,
          senderId: sender.id,
          receiverId: "5f75488b-bab4-4ae2-947b-349b23cef8fe",
        },
      });

      const chat = ret.data?.chat;

      if (chat) {
        navigation.navigate("ChatDetail", { id: chat.id });
      } else {
        navigation.navigate("NewChat", {
          productId: product.id,
          otherId: "5f75488b-bab4-4ae2-947b-349b23cef8fe",
        });
      }
    }
  };

  const cancel = () => {
    changeNewState("Cancelled");
    cancelRequest({ variables: { id } });
  };

  const delivered = () => {
    changeNewState("Delivered");
    confirmDelivery({ variables: { id } });
    const shipment = false;
    if (shipment) {
    }
  };

  const received = () => {
    changeNewState("Received");
    confirmReceival({ variables: { id } });
  };

  const sendMessage = () => {
    const chat = chatRet.data?.chat;
    navigation.navigate("RequestDetail", {
      productId: product.id,
      receiverId: receiver.id,
      requestId: id,
      chatId: chat?.id,
    });
  };

  const completeSender = () => {
    if (!rating) {
      ratingIsRequired();
      return;
    }

    completeSenderRequest({ variables: { id, rating } });
  };

  const completeReceiver = () => {
    if (!rating) {
      ratingIsRequired();
      return;
    }

    completeReceiverRequest({ variables: { id, rating } });
  };

  const receiverRatingVisible = receiverModalVisible(
    newState,
    isReceiver,
    receiverCompleted
  );

  const senderRatingVisible = senderModalVisible(
    newState,
    isSender,
    senderCompleted
  );

  return (
    <View style={styles.container}>
      {newState !== "Complete" &&
        newState !== "Declined" &&
        newState !== "Cancelled" && (
          <SyRequestListener
            id={id}
            changeRequest={changeRequest}
          ></SyRequestListener>
        )}
      <View style={styles.header}>
        <SyText
          content={product?.name.slice(0, 30)}
          style={styles.title}
          lines={1}
        ></SyText>
        <SyIcon name={getStateIcon(newState)} style={styles.stateIcon}></SyIcon>
      </View>
      <View style={styles.advert}>
        <View style={styles.imageContainer}>
          <Image
            source={{ uri: product?.thumbnail }}
            style={styles.image}
          ></Image>
        </View>
        <View style={styles.content}>
          {!incoming && isReceiver && (
            <>
              <View style={styles.actions}>
                {(newState === "Waiting" || newState === "Accepted") && (
                  <SyLeftButton
                    label="Geri Al"
                    disabled={cancelButtonState(newState)}
                    onPress={cancel}
                  ></SyLeftButton>
                )}
                {/*<SyButton
                  style={styles.buttonRight}
                  labelStyle={styles.buttonLabel}
                  label="Sorun Bildir"
                  disabled={reportButtonState(newState)}
                  onPress={report}
          ></SyButton>*/}
                {newState === "Delivered" && (
                  <SyLeftButton
                    label="Teslim Aldım"
                    disabled={receivedButtonState(newState)}
                    onPress={received}
                  ></SyLeftButton>
                )}
                <SyButton
                  style={styles.buttonGrey}
                  labelStyle={styles.buttonLabelGrey}
                  label="Detaylar"
                  onPress={sendMessage}
                  disabled={sendMessageButtonState(newState)}
                ></SyButton>
              </View>
              <SyModalInput visible={receiverRatingVisible}>
                <View style={styles.modalContent}>
                  <SyIconCat
                    width={128}
                    height={128}
                    fill={colors.blank}
                  ></SyIconCat>
                  <SyTitle
                    style={styles.successMessage}
                    content="Güle güle kullan!"
                  ></SyTitle>
                  <View style={styles.successGroup}>
                    <SyText content={product.name}></SyText>
                    {!!product.sayklPoints && (
                      <SyText content="1 saykl puan karşılığında artık senin."></SyText>
                    )}
                  </View>
                  <View style={styles.successRating}>
                    <SyTitle content="Göndereni değerlendir"></SyTitle>
                    <SyUserRating
                      size="x"
                      editable={true}
                      rating={rating}
                      changeRating={changeRating}
                    ></SyUserRating>
                  </View>
                </View>
                <SyButton
                  style={styles.successButton}
                  labelStyle={styles.successButtonLabel}
                  onPress={completeReceiver}
                  label="Dönüştürmeye Devam"
                ></SyButton>
              </SyModalInput>
            </>
          )}
          {incoming && isSender && (
            <>
              <View style={styles.actions}>
                {newState === "Waiting" && (
                  <SyLeftButton
                    onPress={() => setRequestReplyVisible(true)}
                    label="Cevapla"
                    disabled={acceptButtonState(newState)}
                  ></SyLeftButton>
                )}
                {/*newState === "Waiting" && (
                  <SyLeftButton
                    onPress={accept}
                    label="Cevapla"
                    disabled={acceptButtonState(newState)}
                  ></SyLeftButton>
                )*/}
                {/*newState === "Waiting" && (
                  <SyRightButton
                    onPress={decline}
                    style={styles.buttonRight}
                    labelStyle={styles.buttonLabel}
                    label={
                      newState === "Waiting"
                        ? "Reddet"
                        : newState === "Accepted"
                        ? "İptal"
                        : "Sorun Bildir"
                    }
                    disabled={declineButtonState(newState)}
                  ></SyRightButton>
                  )*/}
                {newState === "Accepted" && (
                  <SyLeftButton
                    label="Teslim Ettim"
                    disabled={deliveredButtonState(newState)}
                    onPress={delivered}
                  ></SyLeftButton>
                )}
                <SyButton
                  style={styles.buttonGrey}
                  labelStyle={styles.buttonLabelGrey}
                  label="Detaylar"
                  onPress={sendMessage}
                  disabled={sendMessageButtonState(newState)}
                ></SyButton>
              </View>
              <SyModalInput visible={senderRatingVisible}>
                <View style={styles.modalContent}>
                  <SyIconCat
                    width={128}
                    height={128}
                    fill={colors.blank}
                  ></SyIconCat>
                  <SyTitle
                    style={styles.successMessage}
                    content="Tebrikler!"
                  ></SyTitle>
                  <View style={styles.successGroup}>
                    <SyText content={product.name}></SyText>
                    <SyText content="başarıyla teslim edildi."></SyText>
                    {!!product.sayklPoints && (
                      <SyText content="1 saykl puan kazandın!"></SyText>
                    )}
                  </View>
                  <View style={styles.successRating}>
                    <SyTitle content="Alıcıyı değerlendir"></SyTitle>
                    <SyUserRating
                      size="x"
                      editable={true}
                      rating={rating}
                      changeRating={changeRating}
                    ></SyUserRating>
                  </View>
                </View>
                <SyButton
                  style={styles.successButton}
                  labelStyle={styles.successButtonLabel}
                  onPress={completeSender}
                  label="Dönüştürmeye Devam"
                ></SyButton>
              </SyModalInput>
              <SyModalInput
                visible={requestReplyVisible}
                changeVisible={setRequestReplyVisible}
              >
                <View style={styles.modalContent}>
                  <SyTitle
                    style={styles.successMessage}
                    content="Talebi onaylıyor musun?"
                    noCaps={true}
                  ></SyTitle>
                </View>
                <SyButton
                  style={styles.buttonVertical}
                  labelStyle={styles.buttonLabel}
                  label="Onayla"
                  onPress={accept}
                ></SyButton>
                <SyButton
                  style={styles.buttonRightVertical}
                  labelStyle={styles.buttonRightLabel}
                  label="Reddet"
                  onPress={decline}
                ></SyButton>
              </SyModalInput>
            </>
          )}
        </View>
      </View>
      <View style={styles.footer}>
        <SyText
          content={findRequestState(newState).name}
          style={styles.state}
        ></SyText>
        <SyText content={newUpdatedDate} style={styles.date}></SyText>
      </View>
    </View>
  );
}

const colors = make().theme.colors;

const styles = stylize({
  container: makeBox()
    .background("light")
    .column()
    .start()
    .overflowHidden()
    .padding("m")
    .margin("s")
    .gap("s"),
  advert: makeRow("s").itemsStretch().height("requestThumbnail"),
  imageContainer: make().ratio(1).radius("s"),
  image: make().flex(1).ratio(1),
  content: makeColumn("m").spaceBetween().flex(1),
  actions: makeRow("m").flex(1).padding("m"),
  button: make().flex(1).background("primary").contentCenter(),
  buttonRight: make().flex(1).background("black").contentCenter(),
  buttonVertical: make().background("primary").contentCenter(),
  buttonRightVertical: make().background("black").contentCenter(),
  buttonRightLabel: make().font("sm", "l", "white"),
  buttonGrey: make().flex(1).background("lightGrey").contentCenter(),
  buttonLabel: make().font("sm", "l", "light"),
  buttonLabelGrey: make().font("sm", "l", "black"),
  payButton: make().background("primary").selfCenter().margin("s", true, false),
  payButtonLabel: make().font("sm", "l", "light"),
  title: make().font("l", "l"),
  mText: make().font("m"),
  state: make().font("m"),
  footer: makeRow().spaceBetween(),
  date: make().font("m"),
  modalContent: makeColumn().padding("m").itemsCenter().gap("s"),
  successImage: make().fullWidth().ratio(1),
  successMessage: make().textCenter(),
  successButton: make().background("primary"),
  successButtonLabel: make().font("m", "l", "light"),
  successRating: make().margin("l", true, false).itemsCenter().gap("s"),
  successGroup: make().itemsCenter().gap("n"),
  header: makeRow().spaceBetween().itemsCenter(),
  stateIcon: make().font("x", "m", "lightGrey4"),
  shippingModal: makeColumn("m").textCenter(),
  row: makeRow("m").itemsCenter().contentCenter(),
  shippingInput: make().flex(0).padding("s").width("shippingInput"),
  separator: make()
    .height("u")
    .background("primary")
    .set({
      width: "100%",
    })
    .selfCenter(),
  inactiveText: make().font("m", "m", "lightGrey2"),
  inactiveTitle: make().font("l", "l", "lightGrey2"),
  shippingPrice: make().font("m", "l"),
  activeShippingSection: make()
    .border("u")
    .borderColor("primary")
    .radius("m")
    .padding("s"),
});

function SyLeftButton({ label, onPress, disabled }) {
  return (
    <SyButton
      style={styles.button}
      labelStyle={styles.buttonLabel}
      label={label}
      onPress={onPress}
      disabled={disabled}
    ></SyButton>
  );
}

function SyRightButton({ label, onPress, disabled }) {
  return (
    <SyButton
      style={styles.buttonRight}
      labelStyle={styles.buttonRightLabel}
      label={label}
      onPress={onPress}
      disabled={disabled}
    ></SyButton>
  );
}
/*
{shipmentType === "Shipping" && isReceiver && (
        <View style={styles.shippingModal}>
          <View style={true ? styles.activeShippingSection : null}>
            <SyText
              content="İlan sahibi talebini onayladığında"
              style={false ? styles.inactiveText : null}
            ></SyText>
            <SyText
              content="kargo ödemeni gerçekleştirebilirsin."
              style={false ? styles.inactiveText : null}
            ></SyText>
          </View>
          <View style={false ? styles.activeShippingSection : null}>
            <SyText
              content="Adres bilgisi gir"
              style={true ? styles.inactiveText : null}
            ></SyText>

            <SyButton
              label="Adres bilgisi gir"
              style={styles.payButton}
              labelStyle={styles.payButtonLabel}
              disabled={true}
            ></SyButton>
          </View>
          <View style={false ? styles.activeShippingSection : null}>
            <SyText
              content="İlan sahibi talebini onayladı."
              style={true ? styles.inactiveText : null}
            ></SyText>

            <SyText
              content="Ödenecek ücret: 80TL"
              style={[styles.shippingPrice, true ? styles.inactiveText : null]}
            ></SyText>
            <SyButton
              label="Kredi kartı ile öde"
              style={styles.payButton}
              labelStyle={styles.payButtonLabel}
              disabled={true}
            ></SyButton>
          </View>
          <View style={false ? styles.activeShippingSection : null}>
            <SyText
              content="İlan sahibi ürünü kargoya teslim etti."
              style={styles.inactiveText}
            ></SyText>
            <View style={styles.row}>
              <SyFieldInput
                title="Kargo Firması"
                titleStyle={styles.inactiveTitle}
              >
                <SyInputText
                  style={styles.shippingInput}
                  disabled={true}
                ></SyInputText>
              </SyFieldInput>
              <SyFieldInput title="Takip No" titleStyle={styles.inactiveTitle}>
                <SyInputText
                  style={styles.shippingInput}
                  disabled={true}
                ></SyInputText>
              </SyFieldInput>
            </View>
          </View>
        </View>
      )}
      {shipmentType === "Shipping" && isSender && (
        <View style={styles.shippingModal}>
          <View style={true ? styles.activeShippingSection : null}>
            <SyText
              content="İlan sahibi talebini onayladığında"
              style={false ? styles.inactiveText : null}
            ></SyText>
            <SyText
              content="kargo ödemeni gerçekleştirebilirsin."
              style={false ? styles.inactiveText : null}
            ></SyText>
          </View>
          <View style={false ? styles.activeShippingSection : null}>
            <SyText
              content="İlan sahibi talebini onayladı."
              style={true ? styles.inactiveText : null}
            ></SyText>

            <SyText
              content="Ödenecek ücret: 80TL"
              style={[styles.shippingPrice, true ? styles.inactiveText : null]}
            ></SyText>
            <SyButton
              label="Kredi kartı ile öde"
              style={styles.payButton}
              labelStyle={styles.payButtonLabel}
              disabled={true}
            ></SyButton>
          </View>
          <View style={false ? styles.activeShippingSection : null}>
            <SyText
              content="İlan sahibi ürünü kargoya teslim etti."
              style={styles.inactiveText}
            ></SyText>
            <View style={styles.row}>
              <SyFieldInput
                title="Kargo Firması"
                titleStyle={styles.inactiveTitle}
              >
                <SyInputText
                  style={styles.shippingInput}
                  disabled={true}
                ></SyInputText>
              </SyFieldInput>
              <SyFieldInput title="Takip No" titleStyle={styles.inactiveTitle}>
                <SyInputText
                  style={styles.shippingInput}
                  disabled={true}
                ></SyInputText>
              </SyFieldInput>
            </View>
          </View>
        </View>
      )}
*/
