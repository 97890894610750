import SyModalInput from "./SyModalInput";
import SySelection from "./SySelection";

export default function SyModalSelection({
  items,
  id = 'id',
  changeItem,
  visible,
  changeVisible,
  loading,
  error,
}) {

  const change = (item) => {
    changeItem(item);
    changeVisible(false);
  }

  return (
    <SyModalInput
      visible={visible}
      changeVisible={changeVisible}
      loading={loading}
      error={error}
    >
      <SySelection items={items} id={id} changeItem={change}></SySelection>
    </SyModalInput>
  );
}
