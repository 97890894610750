import { Platform, View } from "react-native";
import { clearCache } from "../lib/graphql";
import { make, stylize } from "../lib/style";
import { categoryVar, conditionVar, distanceVar, loggedInVar, searchVar, shipmentVar } from "../queries/cache";
import { removeToken } from "../storage/token";
import { makeColumn } from "../themes/components";
import SyButton from "./SyButton";

export default function ({ loggedIn }) {
  const logout = async () => {
    await removeToken();
    loggedInVar(false);
    searchVar('');
    categoryVar('');
    shipmentVar('');
    conditionVar('');
    paginationVar(0);
    distanceVar(0);
    clearCache();
  };

  return (
    <View style={styles.buttons}>
      {loggedIn && (
        <SyButton
          icon="pencil"
          label="Profili Düzenle"
          style={styles.regularButton}
          labelStyle={styles.regularButtonLabel}
          navigate="UpdateProfile"
        ></SyButton>
      )}
      {loggedIn && (
        <SyButton
          icon="credit-card"
          label="saykl Puan İşlemleri"
          navigate="Conversion"
          style={styles.successButton}
          iconStyle={styles.successButtonIcon}
          labelStyle={styles.successButtonLabel}
        ></SyButton>
      )}
      {/*loggedIn && Platform.OS === 'web' && (
        <SyButton
          icon="credit-card"
          label="saykl Puan Al"
          navigate="Purchase"
          style={styles.successButton}
          iconStyle={styles.successButtonIcon}
          labelStyle={styles.successButtonLabel}
        ></SyButton>
      )*/}
      {/*loggedIn && (
        <SyButton
          icon="account-plus"
          label="Arkadaşını Davet Et"
          style={styles.regularButton}
          labelStyle={styles.regularButtonLabel}
          navigate="Invitation"
        ></SyButton>
      )*/}
      {loggedIn && (
        <SyButton
          icon="cog"
          label="Ayarlar"
          style={styles.regularButton}
          labelStyle={styles.regularButtonLabel}
          navigate="Settings"
        ></SyButton>
      )}
      <SyButton
        icon="chat-question"
        label="SSS"
        style={styles.regularButton}
        labelStyle={styles.regularButtonLabel}
        navigate="Faq"
      ></SyButton>
      <SyButton
        icon="tow-truck"
        label="Yardım & Destek"
        style={styles.regularButton}
        labelStyle={styles.regularButtonLabel}
        navigate="Help"
      ></SyButton>
      <SyButton
        icon="information"
        label="Hakkımızda"
        style={styles.regularButton}
        labelStyle={styles.regularButtonLabel}
        navigate="About"
      ></SyButton>
      {loggedIn && (
        <SyButton
          icon="logout"
          label="Çıkış Yap"
          onPress={() => logout()}
          style={styles.regularButton}
          labelStyle={styles.regularButtonLabel}
        ></SyButton>
      )}
    </View>
  );
}

const styles = stylize({
  buttons: makeColumn("s").padding("m"),
  regularButton: make().background("light"),
  regularButtonLabel: make().font("l", "l", "main"),
  successButton: make().background("primary"),
  successButtonLabel: make().font("l", "l", "light"),
  successButtonIcon: make().color("light"),
});
