import { setItem, getItem, removeItem } from '../lib/storage';

export async function setToken(token) {
    await setItem('token', token.access);
}

export async function getToken() {
    return await getItem('token');
}

export async function removeToken() {
    await removeItem('token');
}