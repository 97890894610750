import { View } from "react-native";
import SyIconGhost from "../icons/SyIconGhost";
import { make, stylize } from "../lib/style";
import SyPage from "./SyPage";
import SyText from "./SyText";

export default function SyNA({ loading, actions }) {
  return (
    <SyPage loading={loading} actions={actions}>
      <View style={styles.imageContainer}>
        <View style={styles.modal}>
          <SyIconGhost width={96} height={96} fill={colors.blank}></SyIconGhost>
          <SyText style={styles.text} content="yok ki"></SyText>
        </View>
      </View>
    </SyPage>
  );
}

const colors = make().theme.colors;

const styles = stylize({
  imageContainer: make().flex(1).itemsCenter().center(),
  modal: make().width("modal").itemsCenter(),
  text: make().font("l", "l", "blank").margin("m", true),
});
