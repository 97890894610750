import { makeVar } from "@apollo/client";

export const profileVar = makeVar({});
export const pageVar = makeVar({
  name: "",
  parent: "",
  params: null,
  key: "",
  link: "",
});
export const loggedInVar = makeVar("");
export const errorVar = makeVar(null);
export const loadingVar = makeVar(false);
export const notificationsVar = makeVar({});
export const themeVar = makeVar("auto");
export const searchVar = makeVar("");
export const categoryVar = makeVar("");
export const distanceVar = makeVar("");
export const shipmentVar = makeVar("");
export const conditionVar = makeVar("");
export const paginationVar = makeVar(0);
export const cameraImageVar = makeVar(null);

export function formatDate(date) {
  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
}

export function formatTime(date) {
  return `${date.getHours()}:${("0" + date.getMinutes()).slice(-2)}`;
}

export function formatDateTime(date) {
  return formatDate(date) + " " + formatTime(date);
}

export const cache = {
  typePolicies: {
    Query: {
      fields: {
        page: {
          read() {
            return pageVar();
          },
        },
        loggedIn: {
          read() {
            return loggedInVar();
          },
        },
        error: {
          read() {
            return errorVar();
          },
        },
        loading: {
          read() {
            return loadingVar();
          },
        },
        products: {
          //keyArgs: ["id","search","category","shipment","distance","condition"],
          keyArgs: false,
          merge(existing = [], incoming) {
            return [...existing, ...incoming];
          },
        },
      },
    },
    Product: {
      fields: {
        isOwner: {
          read(_, { readField, cache }) {
            const profile = profileVar();
            if (!profile) {
              return false;
            }
            const ownerId = readField("id", readField("owner"));

            return ownerId === profile?.id;
          },
        },
        isFavorite: {
          read(_, { readField, cache }) {
            const profile = profileVar();
            if (
              !profile ||
              !profile?.favorites ||
              profile?.favorites.length === 0
            ) {
              return false;
            }
            const id = readField("id");

            return !!profile.favorites.find((f) => f.id === id);
          },
        },
        date: {
          read(_, { readField, cache }) {
            const createdAt = readField("createdAt");
            const date = new Date(createdAt);
            return formatDate(date);
          },
        },
        time: {
          read(_, { readField, cache }) {
            const createdAt = readField("createdAt");
            const date = new Date(createdAt);
            return formatTime(date);
          },
        },
        datetime: {
          read(_, { readField, cache }) {
            const createdAt = readField("createdAt");
            const date = new Date(createdAt);
            return formatDateTime(date);
          },
        },
      },
    },
    Request: {
      fields: {
        isSender: {
          read(_, { readField, cache }) {
            const profile = profileVar();

            if (!profile) {
              return false;
            }
            const senderId = readField("id", readField("sender"));
            return senderId === profile.id;
          },
        },
        isReceiver: {
          read(_, { readField, cache }) {
            const profile = profileVar();

            if (!profile) {
              return false;
            }
            const receiverId = readField("id", readField("receiver"));
            return receiverId === profile.id;
          },
        },
        date: {
          read(_, { readField, cache }) {
            const createdAt = readField("createdAt");
            const date = new Date(createdAt);
            return formatDate(date);
          },
        },
        time: {
          read(_, { readField, cache }) {
            const createdAt = readField("createdAt");
            const date = new Date(createdAt);
            return formatTime(date);
          },
        },
        datetime: {
          read(_, { readField, cache }) {
            const createdAt = readField("createdAt");
            const date = new Date(createdAt);
            return formatDateTime(date);
          },
        },
        updatedDate: {
          read(_, { readField, cache }) {
            const createdAt = readField("updatedAt");
            const date = new Date(createdAt);
            return formatDate(date);
          },
        },
        updatedTime: {
          read(_, { readField, cache }) {
            const createdAt = readField("updatedAt");
            const date = new Date(createdAt);
            return formatTime(date);
          },
        },
        updatedDatetime: {
          read(_, { readField, cache }) {
            const createdAt = readField("updatedAt");
            const date = new Date(createdAt);
            return formatDateTime(date);
          },
        },
      },
    },
    User: {
      fields: {
        readableLocation: {
          read(_, { readField, cache }) {
            const location = readField("location");
            return "asdad";
          },
        },
      },
    },
    Chat: {
      fields: {
        createdDate: {
          read(_, { readField, cache }) {
            const createdAt = readField("createdAt");
            const date = new Date(createdAt);
            return formatDate(date);
          },
        },
        createdTime: {
          read(_, { readField, cache }) {
            const createdAt = readField("createdAt");
            const date = new Date(createdAt);
            return formatTime(date);
          },
        },
        createdDatetime: {
          read(_, { readField, cache }) {
            const createdAt = readField("createdAt");
            const date = new Date(createdAt);
            return formatDateTime(date);
          },
        },
        updatedDate: {
          read(_, { readField, cache }) {
            const createdAt = readField("updatedAt");
            const date = new Date(createdAt);
            return formatDate(date);
          },
        },
        updatedTime: {
          read(_, { readField, cache }) {
            const createdAt = readField("updatedAt");
            const date = new Date(createdAt);
            return formatTime(date);
          },
        },
        updatedDatetime: {
          read(_, { readField, cache }) {
            const createdAt = readField("updatedAt");
            const date = new Date(createdAt);
            return formatDateTime(date);
          },
        },
      },
    },
    Message: {
      fields: {
        createdTime: {
          read(_, { readField, cache }) {
            const createdAt = readField("createdAt");
            const date = new Date(createdAt);
            return formatTime(date);
          },
        },
      },
    },
  },
};
