import { Image, View } from "react-native";
import SyButton from "../widgets/SyButton";
import SyModal from "../widgets/SyModal";
import SyText from "../widgets/SyText";
import * as Sharing from "expo-sharing";
import { make, stylize } from "../lib/style";
import { errorVar, profileVar } from "../queries/cache";
import { makeColumn } from "../themes/components";
import * as Clipboard from "expo-clipboard";
import { useReactiveVar } from "@apollo/client";
import { linkCopySucess } from "../lib/error";

const SAYKL_LOGO_LIGHT = require("../assets/icon-w-slogan.png");
const SAYKL_LOGO_DARK = require("../assets/icon-w-slogan-dark.png");
const SAYKL_LOGO = make().name === "light" ? SAYKL_LOGO_LIGHT : SAYKL_LOGO_DARK;

export default function InvitationPage() {
  const profile = useReactiveVar(profileVar);
  const url = "https://saykl.com/davetler/" + profile.id;
  const share = async () => {
    await Sharing.shareAsync("Saykl davet linki: " + url, {
      UTI: "public.text",
    });
  };

  const copyLink = async () => {
    await Clipboard.setStringAsync(url);
    linkCopySucess();
  };

  return (
    <SyModal showExit={true}>
      <View style={styles.container}>
        <Image source={SAYKL_LOGO} style={styles.logo}></Image>
        <View>
          <SyText
            style={styles.notification}
            content="Arkadaşın saykl üyesi olup, kendisine iletilen doğrulama e-posta'sını onayladığında +1 saykl puan kazanırsın."
          ></SyText>
          <SyText
            style={styles.notification}
            content="(Sadece 1 arkadaş için geçerlidir ve Google / Apple girişlerinde çalışmaz)"
          ></SyText>
        </View>
        <View style={styles.link}>
          <SyText content={url} style={styles.linkText} lines={1}></SyText>
        </View>
        <View style={styles.buttons}>
          <SyButton
            label="Davet linkini paylaş"
            onPress={share}
            style={styles.loginButton}
            labelStyle={styles.loginButtonLabel}
          ></SyButton>
          <SyButton
            label="Davet linkini kopyala"
            onPress={copyLink}
            style={styles.loginButton}
            labelStyle={styles.loginButtonLabel}
          ></SyButton>
        </View>
        <View style={styles.reminder}>
          <SyText
            content="Paylaş butonunun çalışmaması halinde"
            style={styles.reminderText}
          ></SyText>
          <SyText
            content="linki kopyalayarak paylaşabilirsiniz."
            style={styles.reminderText}
          ></SyText>
        </View>
      </View>
    </SyModal>
  );
}

const styles = stylize({
  container: make().column().gap("x"),
  logo: make()
    .selfCenter()
    .ratio(1.2)
    .width("authIcon")
    .set({
      resizeMode: "contain",
    })
    .margin("l", true, false),
  link: make().center().itemsCenter(),
  loginButton: make().background("primary"),
  loginButtonLabel: make().font("m", "l", "light"),
  linkText: make().font("m", "l", "lightGrey4"),
  notification: make().font("m", "m", "lightGrey4").textCenter(),
  buttons: makeColumn("m"),
  reminder: make()
    .itemsCenter()
    .set((theme) => ({ marginTop: theme.size.m })),
  reminderText: make().font("smallText", "m", "lightGrey3"),
});
