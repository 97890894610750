import { useNavigation } from "@react-navigation/native";
import { Image, Pressable, View } from "react-native";

import { make, stylize } from "../lib/style";
import { makeBox, makeListItem } from "../themes/components";
import SyIcon from "./SyIcon";

import SyText from "./SyText";

export default function SyProductListItem({
  id,
  name,
  thumbnail,
  isOwner,
  date,
  favoritedByUsers,
  approvalState,
  style,
}) {
  const navigation = useNavigation();

  const gotoProduct = () => navigation.push("ProductDetail", { id });

  return (
    <Pressable
      style={[styles.container, style]}
      onPress={() => gotoProduct(id)}
    >
      <View style={styles.imageContainer}>
        <Image source={{ uri: thumbnail }} style={styles.image}></Image>
      </View>
      <View style={styles.content}>
        <View style={styles.header}>
          <SyText
            content={name}
            style={styles.title}
            lines={1}
            ellipsis="head"
          ></SyText>
        </View>
        <View style={styles.footer}>
          <View style={styles.favorites}>
            <SyIcon name="heart" style={styles.star} solid></SyIcon>
            <SyText
              content={favoritedByUsers}
              style={styles.starText}
              lines={1}
              ellipsis="head"
            ></SyText>
          </View>
          <View style={styles.date}>
            {approvalState === "Waiting" && <SyText content="Onay Bekleniyor"></SyText>}
            {approvalState === "Declined" && <SyText content="Onaylanmadı"></SyText>}
            {(approvalState === "Approved" || !approvalState) && <SyText content={date}></SyText>}
          </View>
        </View>
      </View>
    </Pressable>
  );
}

const listItem = makeListItem();

listItem.container.margin("s");
listItem.header.itemsCenter().flex(1);
listItem.title.font("m", "m");

const styles = stylize({
  ...listItem,
  star: make().color("primary").font("l", "m", "primary"),
  starText: make().color("primary").font("l"),
  favorites: make().row().itemsCenter(),
  footer: make().row().itemsCenter().spaceBetween(),
});
