import { Image, Pressable, View } from "react-native";
import { useNavigation } from "@react-navigation/native";

import { makeBox, makeRow } from "../themes/components";
import { make, stylize } from "../lib/style";

import SyText from "./SyText";
import SyButton from "./SyButton";
import { useToggleFavorite } from "../queries/product";
import SyIcon from "./SyIcon";
import { formatLocation } from "../lib/location";
import { isProfileOwner } from "../queries/profile";
import { useScreenState } from "../lib/screen";
import { convertToGrid } from "../lib/image";
import SyIconTriangle from "../icons/SyIconTriangle";

const BADGE = require("../assets/badge.png");

export default function SyProductGridItem({
  id,
  owner,
  name,
  thumbnail,
  isFavorite,
  favoritedByUsers,
  location,
  grid,
  sayklPoints,
}) {
  const { contentWidth } = useScreenState();
  const navigation = useNavigation();
  const gotoProduct = () => navigation.push("ProductDetail", { id });
  const [toggleFavorite] = useToggleFavorite();
  const setFavorite = () => {
    toggleFavorite({ variables: { productId: id } });
  };
  const isOwner = isProfileOwner(owner.id);

  const style = {
    width: contentWidth / grid,
  };

  return (
    <View style={[styles.item, style]}>
      <View style={styles.itemContent}>
        <Pressable style={styles.thumbnail} onPress={() => gotoProduct(id)}>
          <Image
            source={{ uri: convertToGrid(thumbnail) }}
            style={styles.image}
          ></Image>
          {!sayklPoints && (
            <View style={styles.sayklPointsContent}>
                <Image source={BADGE} style={styles.badgeImage}></Image>
            </View>
          )}
          {!isOwner && (
            <View style={styles.favoriteContainer}>
              <SyButton
                icon="heart"
                solid={isFavorite ? true : false}
                style={styles.favoriteButton}
                iconStyle={styles.favorite}
                onPress={setFavorite}
                authRequired={true}
                redirect="Search"
                redirectParent="Main"
              ></SyButton>
            </View>
          )}
        </Pressable>
        <View style={styles.content}>
          <View style={styles.header}>
            <SyText content={name} style={styles.name} lines={1}></SyText>
          </View>
          <View style={styles.footer}>
            <SyText
              style={styles.lightText}
              content={formatLocation(location, "Konum Bilinmiyor")}
              lines={1}
            ></SyText>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = stylize({
  item: make().gap("m").padding("m"),
  image: make().ratio(1).set({
    height: "100%",
  }),
  itemContent: makeBox().padding("n"),
  content: make().padding("s"),
  thumbnail: make().ratio(1).background("light"),
  header: make().row().spaceBetween(),
  name: make().flex(1),
  footer: make().row().itemsCenter().spaceBetween(),
  shipment: make().row().itemsCenter(),
  lightText: make().color("lightGrey3"),
  favorite: make().font("l").color("primary").set({ fontSize: 16 }),
  favoriteButton: make().padding("n"),
  favoriteContainer: make()
    .set({
      position: "absolute",
      right: 0,
      top: 0,
    })
    .margin("s"),
  favoriteGroup: makeRow("s"),
  sayklPointsContent: make().set({
    position: "absolute",
    left: 0,
    bottom: 0,
    right: 0,
  }),
  sayklPointsName: make().color("black"),
  sayklPoints: make().background("primary").padding('s'),
  badgeImage: make().set({
    width: 64,
    height:64
  }),
});
