import { ScrollView, View } from "react-native";
import SyPage from "../widgets/SyPage";
import SyText from "../widgets/SyText";
import { make, stylize } from "../lib/style";
import { makeColumn, makeRow } from "../themes/components";
import SyIcon from "../widgets/SyIcon";
import SyTitle from "../widgets/SyTitle";
import SySpace from "../widgets/SySpace";

const SECTIONS = require("../assets/text/about.json");
const ACTIONS = [
  {
    label: "Gizlilik Politikası",
    authRequired: false,
    navigate: "PrivacyStatement",
  },
  {
    label: "Kullanıcı Sözleşmesi",
    authRequired: false,
    navigate: "UsersAgreement",
  },
];
export default function AboutPage() {
  return (
    <SyPage actions={ACTIONS} authRequired={false}>
      <ScrollView contentContainerStyle={styles.container}>
        <View>
          <SyTitle content="Hakkımızda" style={styles.title}></SyTitle>
          <View style={styles.sections}>
            {SECTIONS.map((s, index) => {
              let textStyle;
              if (s.bold) {
                textStyle = styles.text;
              }
              return (
                <View style={styles.paragraph} key={index}>
                  <SyText content={s.content} style={textStyle}></SyText>
                </View>
              );
            })}
          </View>
        </View>
        <View style={styles.informatics}>
          <SyTitle content="İletişim" style={styles.title}></SyTitle>
          <SyFieldInfo
            icon="home-city"
            content="Döngüsel Mobil İnternet Servisleri ve Tic. Ltd. Şti."
          ></SyFieldInfo>
          <SyFieldInfo
            icon="map-marker"
            content="Barbaros Mh. Lale Sk. 2/13 Ataşehir 34758 İstanbul"
          ></SyFieldInfo>
          <SyFieldInfo icon="phone" content="543 953 5999"></SyFieldInfo>
          <SyFieldInfo icon="email" content="info@saykl.com"></SyFieldInfo>
          <SyFieldInfo
            icon="information-variant"
            content="Kozyatağı V.D. Vergi No: 311 078 2076"
          ></SyFieldInfo>
          <SyFieldInfo
            icon="information-variant"
            content="Mersis: 0311-0782-0760-0001"
          ></SyFieldInfo>
        </View>
        <SySpace mode="page"></SySpace>
      </ScrollView>
    </SyPage>
  );
}

function SyFieldInfo({ icon, content }) {
  return (
    <View style={styles.info}>
      <SyIcon name={icon} style={styles.icon}></SyIcon>
      <SyText content={content} style={styles.infoText}></SyText>
    </View>
  );
}

const styles = stylize({
  container: makeColumn("m").padding("m"),
  sections: makeColumn("m"),
  paragraph: makeRow("m"),
  text: make().font("m", "l", "main"),
  info: makeRow("m").itemsCenter(),
  infoText: make().font("sm", "m", "main"),
  icon: make().font("l", "m", "main"),
  informatics: makeColumn("s"),
  title: make()
    .borderColor("lightGrey4")
    .set((theme) => ({
      marginBottom: theme.size.m,
      paddingBottom: theme.size.s,
      borderBottomWidth: 1,
    })),
});
