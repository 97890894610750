import {
  Image,
  KeyboardAvoidingView,
  RefreshControl,
  SafeAreaView,
  ScrollView,
  View,
} from "react-native";

import { loggedInVar } from "../queries/cache";
import { make, stylize } from "../lib/style";
import { makeColumn, makeError, makeRow } from "../themes/components";

import SyPageActions from "./SyPageActions";
import SyText from "./SyText";
import SySpinner from "./SySpinner";
import { useCallback, useState } from "react";
import { useScreenState } from "../lib/screen";
import SySpace from "./SySpace";

const SAYKL_KOPEKS = [
  require("../assets/images/1-01-out.png"),
  require("../assets/images/2-01-out.png"),
  require("../assets/images/3-01-out.png"),
  require("../assets/images/4-01-out.png"),
  require("../assets/images/5-01-out.png"),
  require("../assets/images/6-01-out.png"),
  require("../assets/images/7-01-out.png"),
  require("../assets/images/8-01-out.png"),
  require("../assets/images/9-01-out.png"),
];

const AUTH_ACTIONS = [
  {
    label: "Giriş Yap",
    authRequired: false,
    navigate: "Login",
  },
  {
    label: "Kaydol",
    authRequired: false,
    navigate: "Signup",
  },
];

function SyAuthSection({
  activeActions,
  showContent,
  usingData,
  style,
  data,
  children,
  showBottomSpace = true
}) {
  const image = SAYKL_KOPEKS[Math.floor(Math.random() * SAYKL_KOPEKS.length)];
  activeActions = AUTH_ACTIONS.map((action) => ({ ...action }));
  return (
    <SafeAreaView style={[styles.page, style]}>
      {!showContent && (
        <View style={styles.content}>
          <View style={styles.auth.container}>
            <SyText
              style={styles.auth.text}
              content="Devam etmek için giriş yap"
            ></SyText>
            <SyText style={styles.auth.text} content="ya da kaydol."></SyText>
            <Image source={image} style={styles.auth.dog}></Image>
          </View>
        </View>
      )}
      {showContent && (
        <View style={styles.content}>
          {usingData ? data && children : children}
        </View>
      )}
      {activeActions && <SyPageActions actions={activeActions} showSpace={showBottomSpace}></SyPageActions>}
    </SafeAreaView>
  );
}

export default function SyPage({
  children,
  style,
  contentStyle,
  actions,
  data,
  showContent = false,
  loading,
  authRequired = true,
  showBottomSpace = true,
  refetch,
}) {
  const loggedIn = loggedInVar();
  const [refreshing, changeRefreshing] = useState(false);

  let activeActions = actions;

  const onRefresh = useCallback(() => {
    (async () => {
      changeRefreshing(true);
      await refetch();
      changeRefreshing(false);
    })();
  }, [data]);

  if (loading) {
    return (
      <SafeAreaView style={[styles.page, style]}>
        <SySpinner />
      </SafeAreaView>
    );
  }

  if (authRequired && !loggedIn) {
    return (
      <SyAuthSection
        style={style}
        showContent={showContent}
        activeActions={activeActions}
        children={children}
        showBottomSpace={showBottomSpace}
      ></SyAuthSection>
    );
  }

  return (
    <View style={[styles.page, style, activeActions && styles.marginStyle]}>
      {refetch && (
        <ScrollView
          contentContainerStyle={styles.scrollView}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onRefresh}
            ></RefreshControl>
          }
        >
          <View style={[styles.content, contentStyle]}>
            {children}
            <SySpace></SySpace>
          </View>
        </ScrollView>
      )}
      {!refetch && (
        <View style={[styles.content, contentStyle]}>
          {children}
          <SySpace></SySpace>
        </View>
      )}
      {activeActions && <SyPageActions actions={activeActions} showBottomSpace={showBottomSpace}></SyPageActions>}
    </View>
  );
}

const styles = stylize({
  scrollView: make().flex(1),
  page: make().background("primaryBackground").flex(1),
  error: makeError(),
  content: make().flex(1),
  auth: {
    container: make().flex(1).itemsCenter().center(),
    dog: make().ratio(1).set({
      width: 212,
    }),
    text: make().font("l"),
  },
  marginStyle: make().set((theme) => ({
    paddingBottom: theme.size.tabbarHeight,
  })),
});
