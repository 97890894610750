import { Image, Pressable, ScrollView, Text, View } from "react-native";
import { findCondition, findShipmentType } from "../lib/constants";
import { make, stylize } from "../lib/style";
import SyCarousel from "./SyCarousel";
import SyField from "./SyField";
import SyHeading from "./SyHeading";
import SyTitle from "./SyTitle";
import SyUserRating from "./SyUserRating";
import { formatLocation } from "../lib/location";
import { profileVar } from "../queries/cache";
import SyUserImage from "./SyUserImage";
import { useNavigation } from "@react-navigation/native";

export default function SyProductDetail({
  id,
  shortId,
  name,
  description,
  images,
  owner,
  location,
  shipmentType,
  condition,
  category,
  date,
  isFavorite,
  isOwner,
  favoritedByUsers,
  sayklPoints
}) {
  const profile = profileVar();
  const navigation = useNavigation();

  const gotoProfile = (id) => {
    if (id === profile.id) {
      navigation.navigate("Profile");
    } else {
      navigation.navigate("UserDetail", { id });
    }
  };

  return (
    <ScrollView>
      <SyCarousel
        images={images}
        id={id}
        isFavorite={isFavorite}
        showFavorite={!isOwner}
        favoritedByUsers={favoritedByUsers}
        showBadge={!sayklPoints}
      />
      <View style={styles.info}>
        <SyHeading title={name}></SyHeading>
        <SyField title="açıklama" content={description} lines={null}></SyField>
        <View style={styles.row}>
          <SyField
            style={styles.flexed}
            title="kategori"
            content={category?.name}
          ></SyField>

          <SyField
            style={styles.flexed}
            title="durumu"
            content={findCondition(condition).name}
          ></SyField>
        </View>
        <View style={styles.row}>
          <SyField
            style={styles.flexed}
            title="Teslimat"
            content={findShipmentType(shipmentType).name}
          ></SyField>

          <SyField
            style={styles.flexed}
            title="konum"
            content={formatLocation(location, "Bilinmiyor")}
          ></SyField>
        </View>
        <View style={styles.row}>
          <SyField
            style={styles.flexed}
            title="İlan tarihi"
            content={date}
          ></SyField>

          <SyField
            style={styles.flexed}
            title="İlan no"
            content={shortId}
          ></SyField>
        </View>
        <View style={[styles.section, styles.profile.container]}>
          <Text style={styles.title}>Üye Profili</Text>
          <Pressable
            style={styles.profile.user}
            onPress={() => gotoProfile(owner.id)}
          >
            <SyUserImage image={owner.image} size="s" />
            <View style={styles.profile.info}>
              <SyTitle content={owner?.name}></SyTitle>
              <SyUserRating rating={owner?.rating}></SyUserRating>
            </View>
          </Pressable>
        </View>
      </View>
    </ScrollView>
  );
}

const styles = stylize({
  row: make().row().width("100%").gap("m"),
  flexed: make().flex(0.5),
  image: make().width("carousel").margin("m").circular().ratio(1),
  info: make().padding("m").gap("m"),
  profile: {
    container: make().column().gap("m"),
    user: make().row().itemsCenter(),
    photoContainer: make()
      .border("u")
      .borderColor("main")
      .itemsCenter()
      .center()
      .overflowHidden()
      .set({
        width: 64,
        height: 64,
        borderRadius: 32,
      }),
    photo: make().set({
      width: 64,
      height: 64,
    }),
    name: make(),
    info: make().margin("s", false, true),
  },

  title: make().font("l", "l"),
  condition: make().row().spaceBetween(),
  sayklPointsName: make().color("black").textCenter(),
  sayklPointsContainer: make().background("primary").padding('s'),
});
