import { useState } from "react";
import { Pressable, View } from "react-native";
import * as WebBrowser from "expo-web-browser";

import { make, stylize } from "../lib/style";
import { useLogin, useResetPassword } from "../queries/profile";
import { makeColumn, makeRow } from "../themes/components";

import SyButton from "../widgets/SyButton";
import SyInputText from "../widgets/SyInputText";
import SyText from "../widgets/SyText";
import { emailSent, loginEmpty } from "../lib/error";
import SyButtonGoogle from "../widgets/SyButtonGoogle";
import SyButtonApple from "../widgets/SyButtonApple";
import SyPageModal from "../widgets/SyPageModal";
import SyBrandSlogan from "../widgets/SyBrandSlogan";
import SyModalInput from "../widgets/SyModalInput";
import SyTitle from "../widgets/SyTitle";

WebBrowser.maybeCompleteAuthSession();

export default function LoginPage({ navigation }) {
  const [email, changeEmail] = useState("");
  const [password, changePassword] = useState("");
  const [login, { loading }] = useLogin();
  const [passwordResetVisible, changePasswordResetVisible] = useState(false);

  const onPress = async () => {
    if (!email || !password) {
      loginEmpty();
      return;
    }

    await login({
      variables: {
        email: email,
        password: password,
      },
    });
  };

  return (
    <SyPageModal authRequired={false} loading={loading}>
      <SyBrandSlogan></SyBrandSlogan>
      <SyInputText
        placeholder="E-posta"
        value={email}
        onChangeText={changeEmail}
        style={styles.input}
      ></SyInputText>
      <SyInputText
        placeholder="Şifre"
        value={password}
        onChangeText={changePassword}
        style={styles.input}
        secureTextEntry={true}
      ></SyInputText>
      <View style={styles.actions}>
        <SyButton
          label="Giriş Yap"
          onPress={onPress}
          style={styles.loginButton}
          labelStyle={styles.loginButtonLabel}
        ></SyButton>
        <SyButton
          label="Kaydol"
          navigate="Signup"
          replace={true}
          style={styles.signupButton}
          labelStyle={styles.signupButtonLabel}
        ></SyButton>
      </View>
      <View style={styles.socialActions}>
        <SyButtonGoogle></SyButtonGoogle>
        <SyButtonApple></SyButtonApple>
      </View>
      <View>
        <View style={styles.terms}>
          <SyButton
            style={styles.linkButton}
            labelStyle={styles.linkButtonLabel}
            label="Gizlilik Politikası"
            lines={1}
            navigate="PrivacyStatement"
          ></SyButton>
          <SyText style={styles.text} content="ve" lines={1}></SyText>

          <SyButton
            style={styles.linkButton}
            labelStyle={styles.linkButtonLabel}
            label="Kullanıcı Sözleşmesi"
            navigate="UsersAgreement"
            lines={1}
          ></SyButton>
        </View>
        <SyText
          style={styles.text}
          content="kaydolduğunda okunup onaylanmış sayılır."
        ></SyText>
      </View>
      <Pressable
        style={styles.footer}
        onPress={() => changePasswordResetVisible(true)}
      >
        <SyText
          style={styles.passwordText}
          content="Şifreni mi unuttun?"
        ></SyText>
      </Pressable>
      <SyPasswordResetModal
        visible={passwordResetVisible}
        changeVisible={changePasswordResetVisible}
      ></SyPasswordResetModal>
    </SyPageModal>
  );
}

function SyPasswordResetModal({ changeVisible, visible, error }) {
  const [email, changeEmail] = useState("");
  const [resetPassword] = useResetPassword();

  const onPress = async () => {
    if (!email) {
      loginEmpty();
      return;
    }

    const res = await resetPassword({
      variables: {
        email: email.trim(),
      },
    });

    if (!res.error) {
      emailSent("Email gönderildi");
      changeVisible(false);
    }
  };

  return (
    <SyModalInput
      changeVisible={changeVisible}
      visible={visible}
      showExit={true}
      style={styles.resetModal}
    >
      <View style={styles.passwordResetModal}>
        <SyTitle
          content="Şifre Sıfırlama"
          style={styles.passwordResetTitle}
        ></SyTitle>
        <SyInputText
          value={email}
          onChangeText={changeEmail}
          placeholder="Kayıtlı e-posta"
        ></SyInputText>
        <SyButton
          onPress={onPress}
          label="Şifremi sıfırla"
          style={styles.passwordResetButton}
          labelStyle={styles.passwordResetButtonLabel}
        ></SyButton>
      </View>
    </SyModalInput>
  );
}

const styles = stylize({
  input: make().shadow(1),
  loginButton: make().flex(1).background("primary"),
  loginButtonLabel: make().font("m", "l", "light"),
  signupButton: make().flex(1).background("light"),
  signupButtonLabel: make().font("m", "l", "main"),
  actions: make().row().spaceBetween().gap("m"),
  socialActions: make().column().gap("m"),
  passwordResetTitle: make(),
  footer: makeRow()
    .itemsCenter()
    .center()
    .set((theme) => ({
      marginTop: theme.size.l,
    })),
  text: make().color("primary"),
  passwordText: make().font("m", "m", "primary"),
  passwordResetModal: makeColumn("m"),
  passwordResetButton: make().background("primary"),
  passwordResetButtonLabel: make().font("m", "l", "light"),
  terms: makeRow("s").itemsCenter().center(),
  text: make().color("lightGrey3").textCenter(),
  linkButtonLabel: make().color("primary"),
  linkButton: make()
    .background("transparent")
    .border("n")
    .shadow(0)
    .padding("n"),
  termsSwitch: makeRow("s").itemsCenter(),
});
