import { Switch } from "react-native";
import { make } from "../lib/style";

const colors = make().theme.colors;
const primary = colors.primary;
const inactive = colors.lightGrey3;


export default function SySwitch({ value, changeValue, disabled = false }) {
  return (
    <Switch
      value={value}
      onValueChange={changeValue}
      disabled={disabled}
      trackColor={{ true: primary, false: inactive }}
    ></Switch>
  );
}
