import { createNativeStackNavigator } from "@react-navigation/native-stack";

import MainLayout from "./MainLayout";
import LoginPage from "../pages/LoginPage";
import SignupPage from "../pages/SignupPage";
import NewProductPage from "../pages/NewProductPage";
import ProductDetailPage from "../pages/ProductDetailPage";
import CameraPage from "../pages/CameraPage";
import UpdateProductPage from "../pages/UpdateProductPage";
import NewRequestPage from "../pages/NewRequestPage";

import SyBrandIcon from "../widgets/SyBrandIcon";

import { useProfile } from "../queries/profile";
import NewChatPage from "../pages/NewChatPage";
import ChatDetailPage from "../pages/ChatDetailPage";
import UpdateProfilePage from "../pages/UpdateProfilePage";
import { errorVar, loggedInVar } from "../queries/cache";
import { useReactiveVar } from "@apollo/client";
import UserDetailPage from "../pages/UserDetailPage";
import FaqPage from "../pages/FaqPage";
import InvitationPage from "../pages/InvitationPage";
import HelpPage from "../pages/HelpPage";
import SettingsPage from "../pages/SettingsPage";
import SocialLoginPage from "../pages/SocialLoginPage";
import InvitationDetailPage from "../pages/InvitationDetailPage";

import SyAlert from "../widgets/SyAlert";
import PurchasePage from "../pages/PurchasePage";
import AboutPage from "../pages/AboutPage";
import PrivacyStatementPage from "../pages/PrivacyStatementPage";
import UsersAgreementPage from "../pages/UsersAgreementPage";
import SyNotification from "../widgets/SyNotification";
import IncomingRequestsArchivePage from "../pages/IncomingRequestsArchivePage";
import OutgoingRequestsArchivePage from "../pages/OutgoingRequestsArchivePage";
import ConfirmationDetailPage from "../pages/ConfirmationDetailPage";
import { make } from "../lib/style";
import { useScreenState } from "../lib/screen";
import SyWebLayout from "../widgets/SyWebLayout";
import DownloadPage from "../pages/DownloadPage";
import RequestDetailPage from "../pages/RequestDetailPage";
import ResetPasswordDetailPage from "../pages/ResetPasswordDetailPage";
import ReportPage from "../pages/ReportPage";
import ReportSuccessPage from "../pages/ReportSuccessPage";
import DeliveryNotificationPage from "../pages/DeliveryNotificationPage";
import { saveUrl } from "../lib/routes";
import ConversionPage from "../pages/ConversionPage";
import NewShippingPage from "../pages/NewShippingPage";

const Stack = createNativeStackNavigator();

function SyNavigator() {
  return (
    <Stack.Navigator
      screenOptions={globalScreenOptions}
      options={globalOptions}
      id="App"
      style={{ overflow: "hidden" }}
    >
      <Stack.Screen name="Main" component={MainLayout}></Stack.Screen>

      <Stack.Screen
        name="Login"
        component={LoginPage}
        options={{ title: "Giriş" }}
      ></Stack.Screen>

      <Stack.Screen
        name="Signup"
        component={SignupPage}
        options={{ title: "Kayıt" }}
      ></Stack.Screen>

      <Stack.Screen
        name="SocialLogin"
        component={SocialLoginPage}
        options={{ title: "Sosyal Giriş" }}
      ></Stack.Screen>

      <Stack.Screen
        name="UpdateProfile"
        component={UpdateProfilePage}
        options={{ ...modalOptions, title: "Profili Güncelle" }}
      ></Stack.Screen>

      <Stack.Screen
        name="Camera"
        component={CameraPage}
        options={{ ...modalOptions, title: "Kamera" }}
      ></Stack.Screen>

      <Stack.Screen
        name="UserDetail"
        component={UserDetailPage}
        options={{ ...modalOptions, title: "Kullanıcı Detayı" }}
      ></Stack.Screen>

      <Stack.Screen
        name="NewProduct"
        component={NewProductPage}
        options={{ ...modalOptions, title: "Yeni İlan" }}
      ></Stack.Screen>

      <Stack.Screen
        name="UpdateProduct"
        component={UpdateProductPage}
        options={{ ...modalOptions, title: "İlanı Güncelle" }}
      ></Stack.Screen>

      <Stack.Screen
        name="ProductDetail"
        component={ProductDetailPage}
        options={{ ...modalOptions, title: "İlan Detayı" }}
      ></Stack.Screen>

      <Stack.Screen
        name="NewRequest"
        component={NewRequestPage}
        options={{ ...modalOptions, title: "Yeni Talep" }}
      ></Stack.Screen>

      <Stack.Screen
        name="RequestDetail"
        component={RequestDetailPage}
        options={{ ...modalOptions, title: "Talep Detayı" }}
      ></Stack.Screen>

      <Stack.Screen
        name="NewChat"
        component={NewChatPage}
        options={{ ...modalOptions, title: "Yeni Mesaj" }}
      ></Stack.Screen>

      <Stack.Screen
        name="ChatDetail"
        component={ChatDetailPage}
        options={{ ...modalOptions, title: "Mesaj Detayı" }}
      ></Stack.Screen>

      <Stack.Screen
        name="Faq"
        component={FaqPage}
        options={{ ...modalOptions, title: "S.S.S." }}
      ></Stack.Screen>

      <Stack.Screen
        name="Invitation"
        component={InvitationPage}
        options={{ ...modalOptions, title: "Yeni Davet" }}
      ></Stack.Screen>

      <Stack.Screen
        name="InvitationDetail"
        component={InvitationDetailPage}
        options={{ ...modalOptions, title: "Davet Detayı" }}
      ></Stack.Screen>

      <Stack.Screen
        name="ConfirmationDetail"
        component={ConfirmationDetailPage}
        options={{ ...modalOptions, title: "Doğrulama Detayı" }}
      ></Stack.Screen>

      <Stack.Screen
        name="ResetPasswordDetail"
        component={ResetPasswordDetailPage}
        options={{ ...modalOptions, title: "Şifre Sıfırlama" }}
      ></Stack.Screen>

      <Stack.Screen
        name="Help"
        component={HelpPage}
        options={{ ...modalOptions, title: "Yardım" }}
      ></Stack.Screen>

      <Stack.Screen
        name="About"
        component={AboutPage}
        options={{ ...modalOptions, title: "Hakkımızda" }}
      ></Stack.Screen>

      <Stack.Screen
        name="Settings"
        component={SettingsPage}
        options={{ ...modalOptions, title: "Ayarlar" }}
      ></Stack.Screen>

      <Stack.Screen
        name="PrivacyStatement"
        component={PrivacyStatementPage}
        options={{ ...modalOptions, title: "Gizlilik Politikası" }}
      ></Stack.Screen>

      <Stack.Screen
        name="UsersAgreement"
        component={UsersAgreementPage}
        options={{ ...modalOptions, title: "Hüküm ve Koşullar" }}
      ></Stack.Screen>

      <Stack.Screen
        name="Purchase"
        component={PurchasePage}
        options={{ ...modalOptions, title: "saykl Puan" }}
      ></Stack.Screen>

      <Stack.Screen
        name="Conversion"
        component={ConversionPage}
        options={{ ...modalOptions, title: "Dönüştür" }}
      ></Stack.Screen>

      <Stack.Screen
        name="NewShipping"
        component={NewShippingPage}
        options={{ ...modalOptions, title: "Anlaşmalı Kargo" }}
      ></Stack.Screen>

      <Stack.Screen
        name="IncomingRequestsArchive"
        component={IncomingRequestsArchivePage}
        options={{ ...modalOptions, title: "Arşiv - Gelen Talepler" }}
      ></Stack.Screen>

      <Stack.Screen
        name="OutgoingRequestsArchive"
        component={OutgoingRequestsArchivePage}
        options={{ ...modalOptions, title: "Arşiv - Giden Talepler" }}
      ></Stack.Screen>

      <Stack.Screen
        name="Download"
        component={DownloadPage}
        options={{ ...modalOptions, title: "İndir" }}
      ></Stack.Screen>

      <Stack.Screen
        name="Report"
        component={ReportPage}
        options={{ ...modalOptions, title: "Sorun Bildir" }}
      ></Stack.Screen>

      <Stack.Screen
        name="ReportSuccess"
        component={ReportSuccessPage}
        options={{ ...modalOptions, title: "Başarılı" }}
      ></Stack.Screen>

      <Stack.Screen
        name="DeliveryNotification"
        component={DeliveryNotificationPage}
        options={{ ...modalOptions, title: "Kargo" }}
      ></Stack.Screen>
    </Stack.Navigator>
  );
}

export default function AppLayout() {
  saveUrl();

  const loggedIn = useReactiveVar(loggedInVar);
  const error = useReactiveVar(errorVar);
  const { state } = useScreenState();

  const { refetch } = useProfile();

  if (loggedIn) {
    refetch();
  }

  return (
    <>
      {loggedIn && <SyNotification></SyNotification>}
      {(state === "small" || state === "tablet") && <SyNavigator></SyNavigator>}
      {state !== "small" && state !== "tablet" && (
        <SyWebLayout>
          <SyNavigator></SyNavigator>
        </SyWebLayout>
      )}
      {error && <SyAlert error={error} />}
    </>
  );
}

const colors = make().theme.colors;
const headerIconColor = colors.headerIconColor;

const globalScreenOptions = ({ route }) => {
  return {
    headerShown: false,
    headerTitle: SyBrandIcon,
    headerTitleAlign: "center",
    headerStyle: {
      backgroundColor: colors.headerColor,
      borderBottomColor: colors.headerBorderColor,
    },
    headerTintColor: headerIconColor,
  };
};

const globalOptions = ({ route }) => ({
  headerShown: false,
});

const modalOptions = {
  //presentation: "transparentModal",
  //animation: "fade",
  headerShown: true,
  headerTitle: SyBrandIcon,
  headerTitleAlign: "center",
  headerBackTitleVisible: false,
};
