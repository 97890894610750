import { Image, Pressable, View } from "react-native";
import { make, stylize } from "../lib/style";
import { Link, useNavigation } from "@react-navigation/native";

const SAYKL_LOGO_LIGHT = require("../assets/icon-w-slogan.png");
const SAYKL_LOGO_DARK = require("../assets/icon-w-slogan-dark.png");

export default function SyBrandSlogan() {
  const navigation = useNavigation();
  const SAYKL_LOGO =
    make().name === "light" ? SAYKL_LOGO_LIGHT : SAYKL_LOGO_DARK;
  const gotoMain = () => {
    navigation.replace("Main", { screen: "Search" });
  };

  return (
    <View style={styles.container}>
      <Pressable onPress={gotoMain}>
        <Image source={SAYKL_LOGO} style={styles.logo}></Image>
      </Pressable>
    </View>
  );
}

const styles = stylize({
  logo: make()
    .ratio(229 / 105)
    .height("sloganImage")
    .set({
      resizeMode: "contain",
    }),
  container: make().itemsCenter().center().margin("l", true, false),
});
